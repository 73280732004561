import { useState } from "react";
import TitleLoader from "components/Loaders/TitleLoader";
import NavigationLinks from "components/Navbar/NavigationLinks";
import { LightBulbActive, Wand } from "assets/icons";
import NavbarContainer from "components/Navbar/NavbarContainer";
import NavbarDefaultLeft from "components/Navbar/NavbarDefaultLeft";
import { Button, SquareButton } from "components/Forms/Button";
import AdInspirationModal from "components/Modals/AdInspirationModal";
import {
  DropdownBase,
  Dropable,
  UserMenuDropdown,
  DropdownMenu,
} from "components/Dropdown";
import CollaborationControlMenu from "components/Misc/CollaborationControlMenu";
import { useSelector } from "react-redux";
import {
  createLibraryCollaboration,
  fetchLibraryCollaborations,
  removeLibraryCollaboration,
} from "api/libraries";
import { LibraryCollaboration } from "interfaces/Collaboration";
import AutoSourceAdsModal from "components/Modals/AutoSourceAdsModal";

export type LibraryNavbarProps = {
  isLoading: boolean;
  readOnly: boolean;
  handleCopyLibraryLink: () => void;
  selectedLibrary?: any;
};

const Navbar = ({
  isLoading,
  readOnly,
  handleCopyLibraryLink,
  selectedLibrary,
}: LibraryNavbarProps) => {
  const [showAutoSourceAdsModal, setShowAutoSourceAdsModal] = useState(false);
  const [showAdInspirationModal, setShowAdInspirationModal] = useState(false);

  const libraries = useSelector((state: any) => state.libraries.libraries);

  const user = useSelector((state: any) => state.user.localUser);
  const library = selectedLibrary || libraries[0];
  const isOwner = user?.uid === library?.user_id;

  return (
    <NavbarContainer classes={{ navbarWrapper: "border-0" }}>
      <NavbarDefaultLeft />
      <div className="right-side">
        {isLoading && <TitleLoader />}
        {!isLoading && (
          <>
            {/* <SquareButton
              classes="btn-white border-1 border-color-20"
              onClick={() => setShowAutoSourceAdsModal(true)}
            >
              <img src={Wand} alt="Source Ads" />
            </SquareButton> */}
            <SquareButton
              classes="btn-white border-1 border-color-20"
              onClick={() => setShowAdInspirationModal(true)}
            >
              <img src={LightBulbActive} alt="Inspiration" />
            </SquareButton>

            {!readOnly && (
              <DropdownBase
                classes={{ dropableContainer: "h-full" }}
                closeOnClick={false}
              >
                <Dropable className="h-full">
                  <Button
                    className="btn-white border-1 border-color-20 h-full"
                    style={{ width: "87px" }}
                  >
                    Share
                  </Button>
                </Dropable>
                <DropdownMenu>
                  <CollaborationControlMenu<LibraryCollaboration>
                    item={{
                      id: selectedLibrary?.id || libraries[0]?.id,
                      title: selectedLibrary?.name || libraries[0]?.name,
                    }}
                    readOnly={!isOwner}
                    handleCopyLink={handleCopyLibraryLink}
                    fetchQuery={(item) => fetchLibraryCollaborations(item.id)}
                    addQuery={(item, email) =>
                      createLibraryCollaboration(item.id, email)
                    }
                    removeQuery={(item, c) =>
                      removeLibraryCollaboration(item.id, c.id)
                    }
                  />
                </DropdownMenu>
              </DropdownBase>
            )}
          </>
        )}
        <NavigationLinks />
        <UserMenuDropdown />
      </div>

      <AdInspirationModal
        show={showAdInspirationModal}
        handleClose={() => setShowAdInspirationModal(false)}
      />

      {/* <AutoSourceAdsModal
        show={showAutoSourceAdsModal}
        handleClose={() => setShowAutoSourceAdsModal(false)}
      /> */}
    </NavbarContainer>
  );
};

export default Navbar;
