import "./styles.css";
import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { auth } from "lib/firebase";
import { fetchUserProjects } from "store/slices/project";
import VideoUploadForm from "./VideoUploadForm";
import ProcessedVideoResults from "./ProcessedVideoResults";

function Video() {
  const location = useLocation();
  const videoFile = location.state?.file;

  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.localUser);
  const [results, setResults] = useState(null);

  const handleVideoProcessed = (data) => {
    setResults(data);
  };

  const handleClearResults = () => {
    setResults(null);
  };

  useEffect(() => {
    if (auth.currentUser) dispatch(fetchUserProjects());
  }, [user, dispatch]);

  if (results) {
    return (
      <ProcessedVideoResults
        results={results}
        handleClearResults={handleClearResults}
      />
    );
  } else {
    return (
      <VideoUploadForm
        defaultFile={videoFile}
        handleVideoProcessed={handleVideoProcessed}
      />
    );
  }
}

export default Video;
