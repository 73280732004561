import "./Modal.css";
import { createPortal } from "react-dom";
import { useEffect } from "react";
import Overlay from "components/Utils/Overlay";

export type ModalBaseProps = {
  show: boolean;
  handleClose: () => void;
  classes?: Helpers.ComponentClasses<"modal" | "modalWrapper">;
  children?: React.ReactNode;
};

const ModalBase = ({
  show,
  children,
  classes,
  handleClose,
}: ModalBaseProps) => {
  const modalRoot = document.querySelector(".modal-root");

  useEffect(() => {
    // Close modal on escape
    const handleEscape = (e: any) => {
      if (e.key === "Escape") {
        handleClose && handleClose();
      }
    };

    document.addEventListener("keydown", handleEscape);

    return () => {
      document.removeEventListener("keydown", handleEscape);
    };
  }, []);

  const modal = (
    <div className={`modal-wrapper z-400 ${classes?.modalWrapper}`}>
      <div className={`modal z-400 ${classes?.modal}`}>{children}</div>

      {/* Modal Overlay */}
      <Overlay
        show={show}
        className="modal-overlay z-300"
        onClick={handleClose}
      />
    </div>
  );

  if (show && modalRoot) return createPortal(modal, modalRoot);
  else return <></>;
};

export default ModalBase;
