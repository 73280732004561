import "./styles.css";
import "styles/masonry.css";
import { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Masonry from "react-masonry-css";
import Navbar from "./Navbar";
import AdCard from "components/Cards/AdCard";
import ChromeExtensionBanner from "components/Banners/ChromeExtensionBanner";
import ExtensionInstalledBanner from "components/Banners/ExtensionInstalledBanner";
import AdsFilterBar from "./AdsFiltersBar";
import AdDetailsModal from "components/Modals/AdDetailsModal";
import { eventEmitter } from "events/index";
import { toast } from "react-toastify";
import AdInspirationSetupModal from "components/Modals/AdsInspirationSetupModal";
import AdInspirationModal from "components/Modals/AdInspirationModal";
import {
  fetchUserAds,
  currentAdsObject,
  requestMoreAds,
} from "store/slices/ad/ad";
import useUniversalLoader from "hooks/useUniversalLoader";
import InfiniteScroll from "react-infinite-scroller";
import RandomHeightCardLoader from "components/Loaders/RandomHeightsCardsLoader";
import { appConfigs } from "configs";
import GustNavbar from "./GuestNavbar";
import { auth } from "lib/firebase";

const breakPoints = {
  default: 4,
  1200: 3,
  768: 2,
  576: 1,
};

function Library() {
  const { id: link_id } = useParams();
  const isReadOnly = link_id ? true : false;

  const dispatch = useDispatch();
  const universalLoader = useUniversalLoader();

  const selectedLibrary = useRef(null);
  const user = useSelector((state) => state.user.localUser);
  const libraries = useSelector((state) => state.libraries.libraries);
  const adsObject = useSelector(currentAdsObject);
  const isFetching = useSelector((state) => state.ad.isFetching);
  const isLoadingMoreAds = useSelector((state) => state.ad.isLoadingMore);

  const [showAdDetailsModal, setShowAdDetailsModal] = useState(null);
  const [showAdInspirationModal, setShowAdInspirationModal] = useState(false);

  // Initial fetch of data
  useEffect(() => {
    if (!link_id && !auth.currentUser) return;

    const defaultOptions = {
      filter: {},
      limit: appConfigs.defaultAdsLimitFetchLimit,
    };

    dispatch(fetchUserAds({ options: defaultOptions, link_id }));
  }, [user, dispatch]);

  useEffect(() => {
    // Force scroll to top on every ads load
    window.scrollTo(0, 0);
  }, [isFetching]);

  const handleLoadMoreAds = () => {
    try {
      universalLoader.startLoading();

      dispatch(requestMoreAds({ link_id }))
        .unwrap()
        .then(() => {
          universalLoader.stopLoading();
        })
        .catch((e) => {
          universalLoader.stopLoading();
        });
    } catch (e) {
      universalLoader.stopLoading();
    }
  };

  const handleSetSelectedLibrary = (lib) => {
    selectedLibrary.current = lib;
  };

  const handleCopyLibraryLink = () => {
    let url = window.location.href;

    if (!link_id) {
      const library = selectedLibrary.current || libraries[0];

      url = `${window.location.origin}/library/${library.link_id}`;

      eventEmitter.emit("library:link_copied", {
        libraryId: library.id,
        link: url,
      });
    }

    navigator.clipboard.writeText(url);

    toast("Copied to clipboard", {
      className: "toast-success",
    });
  };

  const handleShowAdDetailsModal = (ad) => {
    setShowAdDetailsModal(ad.id);
  };

  const handleShowNextAd = (ad) => {
    const ads = adsObject.ads;

    const index = ads.findIndex((a) => a.id === ad.id);

    if (index === ads.length - 1) return;

    setShowAdDetailsModal(ads[index + 1].id);
  };

  const handleShowPreviousAd = (ad) => {
    const ads = adsObject.ads;

    const index = ads.findIndex((a) => a.id === ad.id);

    if (index === 0) return;

    setShowAdDetailsModal(ads[index - 1].id);
  };

  const handleCloseAdDetailsModal = () => {
    setShowAdDetailsModal(null);
  };

  return (
    <div className="library-page">
      <div className="library-top-bar">
        {!isReadOnly && (
          <Navbar
            readOnly={isReadOnly}
            selectedLibrary={selectedLibrary.current}
            handleCopyLibraryLink={handleCopyLibraryLink}
          />
        )}

        {isReadOnly && (
          <GustNavbar handleCopyLibraryLink={handleCopyLibraryLink} />
        )}

        {!isReadOnly && (
          <AdsFilterBar handleSetSelectedLibrary={handleSetSelectedLibrary} />
        )}
      </div>

      <div className="container">
        <div
          className="ads-container"
          style={{ paddingTop: isReadOnly ? "118px" : "" }}
        >
          {/* Chrome Extension Banners */}
          {!isReadOnly && (
            <>
              <ChromeExtensionBanner style={{ marginBottom: "48px" }} />
              <ExtensionInstalledBanner
                style={{ marginBottom: "48px" }}
                handleShowInspirationModal={() =>
                  setShowAdInspirationModal(true)
                }
              />
            </>
          )}

          {/* Ads */}
          <InfiniteScroll
            initialLoad={true}
            pageStart={0}
            loadMore={
              isLoadingMoreAds || isFetching ? () => {} : handleLoadMoreAds // never fetch again if already fetching
            }
            hasMore={adsObject.hasMore}
            threshold={1200}
          >
            <Masonry
              breakpointCols={breakPoints}
              className="masonry-grid"
              columnClassName="masonry-grid-column"
            >
              {/* Ads Cards */}
              {!isFetching &&
                adsObject.ads.map((ad) => (
                  <AdCard
                    ad={ad}
                    key={ad.id}
                    showAdDetailsModal={handleShowAdDetailsModal}
                    readOnly={isReadOnly}
                  />
                ))}

              {/* Ads Loader */}
              {(isFetching || isLoadingMoreAds) &&
                Array.from({ length: isFetching ? 8 : 4 }).map((_, i) => (
                  <RandomHeightCardLoader key={i} width={289} />
                ))}
            </Masonry>
          </InfiniteScroll>

          {/* Ads Modals */}
          {adsObject.ads.map((ad) => (
            <AdDetailsModal
              key={ad.id}
              show={showAdDetailsModal === ad.id}
              ad={ad}
              handleShowNextAd={handleShowNextAd}
              handleShowPreviousAd={handleShowPreviousAd}
              handleClose={handleCloseAdDetailsModal}
              readOnly={isReadOnly}
            />
          ))}
        </div>
      </div>

      <AdInspirationSetupModal />

      <AdInspirationModal
        show={showAdInspirationModal}
        handleClose={() => setShowAdInspirationModal(false)}
      />
    </div>
  );
}

export default Library;
