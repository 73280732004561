import { IAdsFetchOptions } from "./types";
import { appConfigs } from "configs";

export function stringifyAdFetchOptions(options: IAdsFetchOptions) {
  const searchParams = new URLSearchParams({
    filter: JSON.stringify(options.filter || {}),
    sort: JSON.stringify(options.sort || {}),
    limit:
      options.limit?.toString() ||
      appConfigs.defaultAdsLimitFetchLimit.toString(),
  });

  return searchParams.toString();
}
