import BaseSegmentLoader from "./BaseSegmentLoader";

export type MasonaryCardsLoaderProps = {
  width: number;
};

const RandomHeightCardLoader: any = ({ width }: MasonaryCardsLoaderProps) => {
  const heights = [300, 325, 350, 375, 400, 425, 450];

  return (
    <BaseSegmentLoader
      width={width}
      height={heights[Math.floor(Math.random() * heights.length)]}
    />
  );
};

export default RandomHeightCardLoader;
