import instance from "lib/axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  IAdsFetchOptions,
  IInitialState,
  sourceInspirationAdsRequest,
} from "./types";
import { fetchLibraryAds, fetchUserAdsRequest } from "./requests";
import { toast } from "react-toastify";
import { stringifyAdFetchOptions } from "./utils";
import { arrayUnique, arrayUniqueByField } from "utils/arrays";

export const fetchUserAds = createAsyncThunk(
  "ad/fetchUserAds",
  async (
    payload: { options: IAdsFetchOptions; link_id?: string },
    { getState, dispatch, rejectWithValue }
  ) => {
    try {
      const state = getState() as any;
      const adState = state.ad;

      const parsedOptions = stringifyAdFetchOptions(payload.options);

      const adsRef = adState.adsMap[parsedOptions];

      if (adsRef) {
        dispatch(setAdsRef(parsedOptions));

        return {
          ads: [],
          cursor: adsRef.lastCursor,
          hasMore: adsRef.hasMore,
          stringifiedOptions: parsedOptions,
        };
      }

      let res: any;

      if (payload.link_id)
        res = await fetchLibraryAds(payload.options, payload.link_id);
      else res = await fetchUserAdsRequest(payload.options);

      return res;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const requestMoreAds = createAsyncThunk(
  "ad/requestMoreAds",
  async (
    payload: { link_id?: string },
    { getState, dispatch, rejectWithValue }
  ) => {
    try {
      const state = getState() as any;
      const adState = state.ad;

      if (adState.isLoadingMore) {
        throw new Error("Already loading more ads");
      }

      dispatch(setLoadingMoreState(true));

      const ref = adState.currentAdsRef;
      const cursor = adState.adsMap[ref].lastCursor;
      const hasMore = adState.adsMap[ref].hasMore;

      if (!hasMore) {
        throw new Error("No more ads to load");
      }

      let res;

      if (payload.link_id)
        res = await fetchLibraryAds(ref, payload.link_id, cursor);
      else res = await fetchUserAdsRequest(ref, cursor);

      return res;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const getAdByLinkId = createAsyncThunk(
  "ad/getAdByLinkId",
  async ({ linkId }: { linkId: string }, { rejectWithValue }) => {
    try {
      const res = await instance.get(`/ads/${linkId}`);

      return res.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const getUserAdsCount = createAsyncThunk(
  "libraries/getUserAdsCount",
  async (_, { rejectWithValue }) => {
    try {
      const res = await instance.get("/users/ads/count");

      return res.data.count;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const sourceInspirationAds = createAsyncThunk(
  "ad/sourceInspirationAds",
  async (
    { company, industry, link }: sourceInspirationAdsRequest,
    { rejectWithValue }
  ) => {
    try {
      const res = await instance.post(`/ads/inspire_me`, {
        company,
        industry,
        link,
      });

      return res.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const fetchAdsFilterOptions = createAsyncThunk(
  "ad/fetchAdsFilterOptions",
  async (_, { rejectWithValue }) => {
    try {
      const res = await instance.get("/users/libraries/getFilterOptions");

      return res.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const addAdTag = createAsyncThunk(
  "ad/addAdTag",
  async (payload: { ad_id: number; tag: string }, { rejectWithValue }) => {
    try {
      const { ad_id, tag } = payload;

      const res = await instance.post("/ad_tags", {
        ad_id,
        tag,
      });

      return res.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const removeAdTag = createAsyncThunk(
  "ad/removeAdTag",
  async (payload: any, { rejectWithValue }) => {
    try {
      await instance.delete("/ad_tags/" + payload.id);

      return payload;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const editAd = createAsyncThunk(
  "ad/editAd",
  async (payload: any, { rejectWithValue }) => {
    try {
      const { id, data, message } = payload;

      const { library_id, tags, notes } = data;

      const res = await instance.patch("/ads/" + id, {
        library_id: parseInt(library_id) || undefined,
        tags: arrayUnique(tags),
        notes,
      });

      return { data: res.data, successMessage: message };
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const deleteAd = createAsyncThunk(
  "ad/deleteAd",
  async (payload: { id: number }, { rejectWithValue }) => {
    try {
      const { id } = payload;

      await instance.delete("/ads/" + id);

      return { id };
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

const initialState: IInitialState = {
  adsMap: {
    defaultPlaceholder: { ads: [], lastCursor: 0, hasMore: false },
  },
  currentAdsRef: "defaultPlaceholder",
  isFetching: true,
  isLoadingMore: false,
  filterOptions: {
    categories: [],
    providers: [],
    tags: [],
  },
};

const ad = createSlice({
  name: "ad",
  initialState,
  reducers: {
    setFetchingState(state, action) {
      state.isFetching = action.payload;
    },
    setLoadingMoreState(state, action) {
      state.isLoadingMore = action.payload;
    },
    setAdsRef(state, action) {
      state.currentAdsRef = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchUserAds.fulfilled, (state, action) => {
      state.isFetching = false;

      const { ads, cursor, hasMore, stringifiedOptions } = action.payload;

      const adsRef = state.adsMap[stringifiedOptions];

      if (!adsRef) {
        state.adsMap[stringifiedOptions] = {
          ads: ads,
          lastCursor: cursor,
          hasMore: hasMore,
        };
      } else {
        state.adsMap[stringifiedOptions].ads =
          state.adsMap[stringifiedOptions].ads.concat(ads);
        state.adsMap[stringifiedOptions].lastCursor = cursor;
        state.adsMap[stringifiedOptions].hasMore = hasMore;
      }

      state.currentAdsRef = stringifiedOptions;
    });

    builder.addCase(fetchUserAds.pending, (state, action) => {
      state.isFetching = true;
    });

    builder.addCase(fetchUserAds.rejected, (state, action) => {
      state.isFetching = false;
    });

    builder.addCase(requestMoreAds.fulfilled, (state, action) => {
      state.isLoadingMore = false;

      const { ads, cursor, hasMore, stringifiedOptions } = action.payload;

      if (ads.length === 0) {
        state.adsMap[stringifiedOptions].hasMore = false;

        return;
      }

      state.adsMap[stringifiedOptions].ads =
        state.adsMap[stringifiedOptions].ads.concat(ads);
      state.adsMap[stringifiedOptions].ads = arrayUniqueByField(
        state.adsMap[stringifiedOptions].ads,
        "id"
      );
      state.adsMap[stringifiedOptions].hasMore = hasMore;

      state.adsMap[stringifiedOptions].lastCursor = cursor;

      state.currentAdsRef = stringifiedOptions;
    });

    builder.addCase(requestMoreAds.rejected, (state, action: any) => {
      state.isLoadingMore = false;
    });

    builder.addCase(fetchAdsFilterOptions.fulfilled, (state, action) => {
      state.filterOptions = action.payload;
    });

    builder.addCase(sourceInspirationAds.rejected, (state, action: any) => {
      const { message } = action.payload.response.data;

      toast(message, {
        className: "toast-danger",
      });
    });

    builder.addCase(addAdTag.fulfilled, (state, action) => {
      Object.keys(state.adsMap).forEach((key) => {
        state.adsMap[key].ads = state.adsMap[key].ads.map((ad: any) => {
          if (ad.id === action.payload.ad_id) {
            ad.ad_tags.push(action.payload);
          }

          return ad;
        });
      });
    });

    builder.addCase(addAdTag.rejected, (state, action) => {
      // @ts-ignore
      const message = action.payload?.response?.data?.message;

      toast(message || "Error adding tag", {
        className: "toast-danger",
      });
    });

    builder.addCase(removeAdTag.fulfilled, (state, action) => {
      Object.keys(state.adsMap).forEach((key) => {
        state.adsMap[key].ads = state.adsMap[key].ads.map((ad: any) => {
          if (ad.id === action.payload.ad_id) {
            ad.ad_tags = ad.ad_tags.filter(
              (t: any) => t.id !== action.payload.id
            );
          }

          return ad;
        });
      });
    });

    builder.addCase(editAd.fulfilled, (state, action) => {
      const { data, successMessage } = action.payload;

      Object.keys(state.adsMap).forEach((key) => {
        state.adsMap[key].ads = state.adsMap[key].ads.map((ad: any) => {
          if (ad.id === data.id) {
            ad = { ...ad, ...data };
          }

          return ad;
        });
      });

      if (successMessage) {
        toast(successMessage, {
          className: "toast-success",
        });
      }
    });

    builder.addCase(editAd.rejected, (state, action) => {
      toast("Error Occurred", {
        className: "toast-danger",
      });
    });

    builder.addCase(deleteAd.fulfilled, (state, action) => {
      const { id } = action.payload;

      Object.keys(state.adsMap).forEach((key) => {
        state.adsMap[key].ads = state.adsMap[key].ads.filter(
          (ad: any) => ad.id !== id
        );
      });
    });

    builder.addCase(deleteAd.rejected, (state, action) => {
      toast("Error Occurred", {
        className: "toast-danger",
      });
    });
  },
});

export const { setFetchingState, setLoadingMoreState, setAdsRef } = ad.actions;

export const currentAdsObject = (state: any) =>
  state.ad.adsMap[state.ad.currentAdsRef];

export default ad.reducer;
