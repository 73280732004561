import "./RectangleExternalLink.css";
import { ExternalLinkActive } from "assets/icons";

export type RectangleExternalLinkProps = {
  children: React.ReactNode;
  href: string;
  icon?: string;
};

const RectangleExternalLink = ({
  children,
  href,
  icon,
}: RectangleExternalLinkProps) => {
  return (
    <a
      href={href}
      className="rectangle flex items-center justify-between external-link cursor-pointer rounded-8 p-8 decoration-none"
      target="_blank"
      rel="noopener noreferrer"
    >
      <div className="flex items-center gap-14">
        {icon && (
          <div className="link-icon">
            <img src={icon} alt="" />
          </div>
        )}
        <p className="link-content text-primary text-md font-bold">
          {children}
        </p>
      </div>
      <div style={{ marginRight: "5px" }}>
        <img src={ExternalLinkActive} alt="Go" />
      </div>
    </a>
  );
};

export default RectangleExternalLink;
