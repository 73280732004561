import Modal from "./Base/ModalBase";
import { DefaultModalHeader, ModalBody } from "./Base/Utils";
import { useDispatch } from "react-redux";
import {
  deleteProjectOnly,
  deleteProjectWithBoards,
} from "store/slices/project";

const DeleteProjectModal = ({ show, handleClose, project }) => {
  const dispatch = useDispatch();

  const handleDeleteProjectOnly = () => {
    dispatch(deleteProjectOnly({ id: project.id }));

    handleClose();
  };

  const handleDeleteProjectWithBoards = () => {
    dispatch(deleteProjectWithBoards({ id: project.id }));

    handleClose();
  };

  return (
    <Modal show={show} handleClose={handleClose}>
      <DefaultModalHeader handleClose={handleClose}>
        Delete Project
      </DefaultModalHeader>
      <ModalBody className="mt-24 pb-32">
        <div className="flex flex-col gap-12">
          <button
            className="btn-default action-btn btn-danger-outlined h-40"
            onClick={handleDeleteProjectWithBoards}
          >
            Delete Project & Boards
          </button>
          <button
            className="btn-default action-btn btn-danger h-40"
            onClick={handleDeleteProjectOnly}
          >
            Delete Project Only
          </button>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default DeleteProjectModal;
