import "./StatusDropdown.css";
import {
  DropdownBase,
  Dropable,
  DropdownMenu,
  DropdownMenuItem,
} from "./index";
import { useState } from "react";
import { ArrowDown } from "assets/icons";

export type BoardStatus = {
  name: string;
  value: string;
  class: string;
};

const status: BoardStatus[] = [
  {
    name: "IN PROGRESS",
    value: "in_progress",
    class: "status-progress",
  },
  {
    name: "APPROVED",
    value: "approved",
    class: "status-approved",
  },
  {
    name: "NEEDS REVIEW",
    value: "needs_review",
    class: "status-needs-review",
  },
  {
    name: "ARCHIVED",
    value: "archived",
    class: "status-archived",
  },
];

export type StatusDropdownProps = {
  defaultStatus: string;
  onChange: (value: string) => void;
};

const StatusDropdown: React.FC<StatusDropdownProps> = ({
  defaultStatus,
  onChange,
}: StatusDropdownProps) => {
  const [selected, setSelected] = useState(
    status.find((s) => s.value === defaultStatus) || status[0]
  );

  const handleStatusChange = (s: BoardStatus) => {
    setSelected(s);

    onChange && onChange(s.value);
  };

  return (
    <DropdownBase classes={{ menuContainer: "z-300 status-dropdown-menu" }}>
      <Dropable>
        <div
          className={`status-dropdown flex justify-between items-center gap-12 text-primary font-extrabold border-1 h-32 rounded-8 ${selected.class}`}
          style={{ padding: "4px 12px" }}
        >
          <div className="flex gap-8 items-center">
            <span className="circle-sm"></span>
            {selected.name}
          </div>
          <img src={ArrowDown} alt="dropdown" />
        </div>
      </Dropable>
      <DropdownMenu className="p-16 gap-12">
        {status
          .filter((s) => s !== selected)
          .map((s) => (
            <DropdownMenuItem
              className="status-dropdown-item"
              key={s.name}
              onClick={() => handleStatusChange(s)}
            >
              <div
                className={`status-selection w-full flex gap-8 items-center border-1 h-32 rounded-8 ${s.class}`}
                style={{ padding: "4px 12px" }}
              >
                <span className="circle-sm"></span>
                {s.name}
              </div>
            </DropdownMenuItem>
          ))}
      </DropdownMenu>
    </DropdownBase>
  );
};

export default StatusDropdown;
