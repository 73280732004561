import "./Carousel.css";
import { useState } from "react";
import { ArrowLeft } from "assets/icons";
import { useEffect } from "react";

const Carousel = ({ media, onLoadingChange }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [current, setCurrent] = useState(0);

  const next = (e) => {
    e.stopPropagation();
    setCurrent((prev) => prev + 1);
  };

  const prev = (e) => {
    e.stopPropagation();
    setCurrent((prev) => prev - 1);
  };

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
      onLoadingChange && onLoadingChange(false);
    }, 300);
  }, []);

  useEffect(() => {
    if (onLoadingChange) onLoadingChange(true);
    setIsLoading(true);
  }, [current]);

  const handleLoaded = () => {
    if (onLoadingChange) onLoadingChange(false);
    setIsLoading(false);
  };

  return (
    <div className="carousel-container">
      <div className="carousel">
        {current < media.length - 1 && (
          <span className="control-btn next" onClick={next}>
            <img src={ArrowLeft} className="rotate-180" alt="next" />
          </span>
        )}
        {media[current].type === "image" && (
          <div className={`item item-image ${isLoading ? "hide" : ""}`}>
            <img
              src={media[current].src}
              alt=""
              onLoad={() => handleLoaded(false)}
              onError={() => handleLoaded(false)}
            />
          </div>
        )}
        {media[current].type === "video" && (
          <div className={`item item-video ${isLoading ? "hide" : ""}`}>
            <video
              controls
              src={media[current].src}
              onLoad={() => handleLoaded(false)}
              onError={() => handleLoaded(false)}
              onLoadedMetadata={() => handleLoaded(false)}
            />
          </div>
        )}
        {current > 0 && (
          <span className="control-btn prev" onClick={prev}>
            <img src={ArrowLeft} alt="prev" />
          </span>
        )}
      </div>
    </div>
  );
};

export default Carousel;
