import "./Tag.css";
import { XIcon } from "assets/icons";

const Tag = ({ children, removeable, handleRemove }) => {
  const handleRemoveClicked = () => {
    handleRemove();
  };

  return (
    <div className="tag">
      <p className="tag-content text-primary">{children}</p>
      {handleRemove && removeable && (
        <img
          className="tag-close-icon"
          src={XIcon}
          alt="remove"
          onClick={handleRemoveClicked}
        />
      )}
    </div>
  );
};

export default Tag;
