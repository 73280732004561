import "./TitleLoader.css";
import LoadingPlaceholder from "react-placeholder-loading";

const TitleLoader = () => {
  return (
    <div className="title-loader flex items-center">
      <LoadingPlaceholder
        width="200px"
        height={30}
        shape="rect"
        colorStart="#e8e1fa"
        colorEnd="#e1d8f7"
      />
    </div>
  );
};

export default TitleLoader;
