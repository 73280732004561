import { CSS } from "@dnd-kit/utilities";
import { useSortable } from "@dnd-kit/sortable";

export function SortableItemHOC(Component, id, props) {
  const sortable = useSortable({ id });

  const styles = {
    transform: CSS.Transform.toString(sortable.transform),
    transition: sortable.transition,
    translate: CSS.Translate.toString(),
  };

  return <Component {...props} draggingOptions={{ ...sortable, styles }} />;
}

export default SortableItemHOC;
