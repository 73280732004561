import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { RootState } from 'store/store';
import { LocalUser } from 'types/DataObjects';

const RequireNotAuth: React.FunctionComponent<React.PropsWithChildren> = ({
  children,
}) => {
  const user = useSelector<RootState, LocalUser | null>(
    (state) => state.user.localUser
  );

  if (user) {
    return <Navigate to={'/'} replace={true} />;
  }

  return <>{children}</>;
};

export default RequireNotAuth;
