import { auth } from "lib/firebase";
import { useEffect, useState } from "react";

const useIsNewUser = () => {
  const user = auth.currentUser;
  const [isNewUser, setIsNewUser] = useState(false);

  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      if (user)
        setIsNewUser(
          user.metadata.creationTime === user.metadata.lastSignInTime
        );
    });
  }, [user]);

  return isNewUser;
};

export default useIsNewUser;
