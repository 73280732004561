export const allBoardFrameFields = [
  {
    name: "Asset",
  },
  {
    name: "Label",
  },
  {
    name: "Sound",
  },
  {
    name: "Action",
  },
  {
    name: "Caption",
  },
  {
    name: "Link",
  },
];
