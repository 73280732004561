import { initializeApp } from "firebase/app";
import { getAuth, connectAuthEmulator } from "firebase/auth";
import {
  getFirestore,
  doc,
  getDoc,
  setDoc,
  onSnapshot,
  deleteDoc,
  connectFirestoreEmulator,
  collection,
  addDoc,
} from "firebase/firestore";
import { getFunctions, connectFunctionsEmulator } from "firebase/functions";
import { appConfigs, firebaseConfigs } from "configs";
import { getUserLocalData } from "utils/user";

export const app = initializeApp({
  apiKey: firebaseConfigs.apiKey,
  projectId: firebaseConfigs.projectId,
  authDomain: firebaseConfigs.authDomain,
  storageBucket: firebaseConfigs.storageBucket,
});

export const auth = getAuth(app);

export const db = getFirestore(app);

export const functions = getFunctions(app);

if (appConfigs.env === "development") {
  connectAuthEmulator(auth, "http://localhost:9099");
  connectFirestoreEmulator(db, "localhost", 8080);
  connectFunctionsEmulator(functions, "localhost", 5001);
}

// Watch user subscriptions subcollection
// And update user token if subscription changed
auth.onAuthStateChanged(async (user) => {
  if (user) {
    const userSubscriptionsCollectionRef = collection(
      db,
      "users",
      user.uid,
      "subscriptions"
    );

    onSnapshot(userSubscriptionsCollectionRef, async (snapshot) => {
      // Update user token
      await user.getIdTokenResult(true);
    });
  }
});

export async function checkIfUserExistsInFirestore(uid) {
  const docRef = doc(db, "users", uid);
  const userRef = await getDoc(docRef);

  const exists = userRef.exists();

  return exists ? userRef.data() : false;
}

export async function createUserInFirestore() {
  const user = auth.currentUser;

  const docRef = doc(db, "users", user.uid);

  await setDoc(
    docRef,
    {
      uid: user.uid,
      email: user.email,
      phoneNumber: user.phoneNumber,
    },
    {
      merge: true,
    }
  );
}

/**
 * Trigger create stripe account for already existing user
 * by creating a dummy checkout session
 */
export async function triggerCreateStripeCustomer() {
  const user = auth.currentUser;

  const docRef = doc(db, "users", user.uid, "checkout_sessions", "latest");

  await setDoc(docRef, {});

  await new Promise((resolve, _) => {
    const userDocRef = doc(db, "users", user.uid);

    // Watch for stripe customer id
    onSnapshot(userDocRef, (doc) => {
      if (doc.data().stripeId) {
        resolve();
      }
    });
  });

  // Clean up dummy checkout session collection
  deleteDoc(docRef);
}

export async function checkoutToProPlan() {
  const user = auth.currentUser;

  const proPlanId = appConfigs.stripe.proPlanId;

  const collectionRef = collection(db, "users", user.uid, "checkout_sessions");

  const docRef = await addDoc(collectionRef, {
    mode: "subscription",
    price: proPlanId,
    success_url: window.location.origin,
    cancel_url: window.location.origin,
    billing_address_collection: "auto",
    allow_promotion_codes: true,
  });

  onSnapshot(docRef, (snap) => {
    const { url } = snap.data();

    if (url) window.location.assign(url);
  });
}

export async function updateUserAdditionalData(docName, data) {
  const user = auth.currentUser;

  const docRef = doc(db, "users", user.uid, "additional", docName);

  await setDoc(docRef, data, { merge: true });
}

export async function getUserAdditionalData(docName) {
  if (!auth.currentUser) return true;

  const user = auth.currentUser;

  const docRef = doc(db, "users", user.uid, "additional", docName);

  const docSnap = await getDoc(docRef);

  return docSnap.data();
}

export async function checkIfUserInstalledChromeExtensionBefore() {
  if (!auth.currentUser) return true;

  if (window.magicbriefExtensionInstalled) return true;

  const extensionId = localStorage.getItem("extensionId");

  if (extensionId) {
    await updateUserAdditionalData("setup", {
      extensionInstalled: true,
    });

    return true;
  }

  const additionalData = await getUserAdditionalData("setup");

  if (additionalData && additionalData.extensionInstalled) return true;

  return false;
}

export async function checkIfUserSkippedExtensionTutorial() {
  const didSkip = getUserLocalData().didSkipExtensionTutorial;

  if (didSkip) return true;

  const additionalData = await getUserAdditionalData("setup");

  if (additionalData && additionalData.skippedExtensionTutorial) return true;

  return false;
}
