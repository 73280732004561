import { ArrowDown, CancelActive } from "assets/icons";

export const SelectboxHead = ({ children }) => {
  return <div className="selectbox-head-item">{children}</div>;
};

export const SelectboxAction = ({ children }) => {
  return <div className="selectbox-action-item">{children}</div>;
};

export const SelectboxBody = ({ children, handleToggle, classes }) => {
  return (
    <div className={`selectbox-body ${classes?.body}`} onClick={handleToggle}>
      {children}
    </div>
  );
};

export const DefaultSelectboxBody = ({
  label,
  isPlaceholder,
  handleToggle,
  classes,
}) => {
  return (
    <SelectboxBody classes={classes} handleToggle={handleToggle}>
      <p
        className={`placeholder ${classes?.placeholder} ${
          !isPlaceholder ? classes?.label : ""
        }`}
      >
        {label}
      </p>
      <img src={ArrowDown} alt="" />
    </SelectboxBody>
  );
};

export const ResetableSelectboxBody = ({
  label,
  handleToggle,
  classes,
  isResetable,
  handleReset,
}) => {
  const handleResetClicked = (e) => {
    e.stopPropagation();
    handleReset();
  };

  classes.body = `${classes.body} resetable-body`;

  return (
    <SelectboxBody classes={classes} handleToggle={handleToggle}>
      <p className={`placeholder ${classes?.placeholder}`}>{label}</p>
      {!isResetable && <img src={ArrowDown} alt="" />}
      {isResetable && (
        <button className="btn-clear reset-icon" onClick={handleResetClicked}>
          <img src={CancelActive} alt="Reset" />
        </button>
      )}
    </SelectboxBody>
  );
};
