import "./FrameCardsLoader.css";
import LoadingPlaceholder from "react-placeholder-loading";

const FrameCardsLoader = () => {
  return (
    <div className="frames-loader">
      {Array(8)
        .fill(0)
        .map((_, index) => (
          <LoadingPlaceholder
            width={289}
            height={525}
            shape="rect"
            key={index}
            colorStart="#e8e1fa"
            colorEnd="#e1d8f7"
          />
        ))}
    </div>
  );
};

export default FrameCardsLoader;
