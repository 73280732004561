import "styles/form.css";
import "./styles.css";
import { Input } from "components/Forms/Input";
import { Button } from "components/Forms/Button";
import { ContinueWithGoogleButton } from "components/Forms/Button";
import { RemoveRedEye, Oval } from "assets/icons";
import { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { login, clearvalidationErrors } from "store/slices/user";
import useAutoFocus from "hooks/useAutoFocus";

function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const emailInputRef = useAutoFocus();

  const dispatch = useDispatch();
  const validationErrors = useSelector((state) => state.user.validationErrors);

  const passwordInputRef = useRef();

  const handleFormSubmit = (e) => {
    e.preventDefault();

    dispatch(login({ email, password }));
  };

  const handleToggleShowPassword = () => {
    const currentType = passwordInputRef.current.type;

    if (currentType === "text") passwordInputRef.current.type = "password";
    else if (currentType === "password") passwordInputRef.current.type = "text";
  };

  useEffect(() => {
    dispatch(clearvalidationErrors());
  }, [email, password, dispatch]);

  return (
    <div className="login-page">
      <div className="logo-container">
        <Link to="/">
          <img
            src="/images/MagicBriefLogo.png"
            alt="Magic Brief"
            width="159px"
          />
        </Link>
      </div>
      <form className="form-container" onSubmit={handleFormSubmit}>
        <div className="form-body">
          <h1 className="form-title text-primary text-lg">Login</h1>
          <div className="input-group">
            <div className="input-container">
              <Input
                type="email"
                placeholder="Your Email"
                inputRef={emailInputRef}
                autoComplete="true"
                onChange={(e) => setEmail(e.target.value)}
              />
              <span className="validation-error">
                {validationErrors.login?.email?.[0]}
              </span>
            </div>
            <div className="input-container">
              <Input
                type="password"
                placeholder="Password"
                inputRef={passwordInputRef}
                autoComplete="true"
                onChange={(e) => setPassword(e.target.value)}
                icon={
                  <img
                    src={RemoveRedEye}
                    alt="Show Password"
                    onClick={handleToggleShowPassword}
                    className="cursor-pointer"
                  />
                }
              />
              <span className="validation-error">
                {validationErrors.login?.password?.[0]}
              </span>
            </div>
          </div>
          <div className="action-buttons" style={{ marginTop: "-10px" }}>
            <Button className="w-full h-40" type="submit">
              Login
            </Button>
            <p className="or-text">OR</p>
            <ContinueWithGoogleButton />
          </div>
        </div>
        <div className="form-footer">
          <Link to="/reset-password" className="text-primary font-semibold">
            Can’t login?
          </Link>
          <img src={Oval} alt="oval" />
          <Link to="/register">Sign up for an account</Link>
        </div>
      </form>
      <div className="privacy-terms">
        <a
          href="https://www.magicbrief.com/privacy"
          target="_blank"
          rel="noreferrer"
        >
          Privacy policy
        </a>
        <img src={Oval} alt="oval" />
        <a
          href="https://www.magicbrief.com/terms"
          target="_blank"
          rel="noreferrer"
        >
          Terms of use
        </a>
      </div>
    </div>
  );
}

export default Login;
