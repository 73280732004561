import instance from "lib/axios";
import { LibraryCollaboration } from "interfaces/Collaboration";

export async function fetchLibraryCollaborations(
  library_id: number
): Promise<LibraryCollaboration[]> {
  const { data } = await instance.get(
    `/libraries/${library_id}/collaborations`
  );

  return data;
}

export async function createLibraryCollaboration(
  libraryId: number,
  email: string
) {
  const { data } = await instance.post(
    `/libraries/${libraryId}/collaborations`,
    {
      email,
    }
  );

  return data;
}

export async function removeLibraryCollaboration(
  libraryId: number,
  collaborationId: number
) {
  const { data } = await instance.delete(
    `/libraries/${libraryId}/collaborations/${collaborationId}`
  );

  return data;
}
