import Modal from "./Base/ModalBase";
import { DefaultModalHeader, ModalBody } from "./Base/Utils";
import { useState } from "react";
import { useDispatch } from "react-redux";
import useAutoFocus from "hooks/useAutoFocus";
import { Input } from "components/Forms/Input";
import { Button } from "components/Forms/Button";
import { addBoardCollaborator } from "store/slices/project";

const AddTeamMemberModal = ({ show, handleClose, board }) => {
  const [email, setEmail] = useState("");
  const dispatch = useDispatch();

  const inputRef = useAutoFocus();

  const handleAddCollaborators = (e) => {
    e?.preventDefault();

    dispatch(addBoardCollaborator({ board, email }));

    handleClose();
  };

  return (
    <Modal show={show} handleClose={handleClose}>
      <DefaultModalHeader handleClose={handleClose}>
        Add Editor
      </DefaultModalHeader>
      <ModalBody className="mt-24 pb-32">
        <form onSubmit={handleAddCollaborators}>
          <Input
            type="text"
            className="input-default text-primary"
            placeholder="Collaborator email address"
            classes={{
              container: "h-40",
            }}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            inputRef={inputRef}
          />
        </form>
        <Button className="w-full h-40 mt-12" onClick={handleAddCollaborators}>
          Add to Board
        </Button>
      </ModalBody>
    </Modal>
  );
};

export default AddTeamMemberModal;
