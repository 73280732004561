import Modal from "./Base/ModalBase";
import { DefaultModalHeader, ModalBody } from "./Base/Utils";
import { Button } from "components/Forms/Button";
import { useDispatch } from "react-redux";
import { deleteLibrary } from "../../store/slices/library";

const DeleteLibraryModal = ({ show, handleClose, handleDeleted, library }) => {
  const dispatch = useDispatch();

  const handleDeleteFolder = () => {
    dispatch(deleteLibrary({ id: library.id })).then((res) => {
      if (res.type === "libraries/deleteLibrary/fulfilled") {
        handleDeleted();
      }
    });

    handleClose();
  };

  return (
    <Modal show={show} handleClose={handleClose}>
      <DefaultModalHeader handleClose={handleClose}>
        Delete Folder
      </DefaultModalHeader>
      <ModalBody className="mt-24 pb-32">
        <Button
          className="btn-danger w-full h-40 mt-12"
          onClick={handleDeleteFolder}
        >
          Delete Folder
        </Button>
      </ModalBody>
    </Modal>
  );
};

export default DeleteLibraryModal;
