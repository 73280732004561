import "./SwitchItem.css";

type Props = {
  children: JSX.Element | JSX.Element[];
  active: boolean;
  value: any;
  className?: string;
};

const SwitchItem: React.FC<Props> = ({
  children,
  active,
  value,
  className,
}: Props) => {
  return (
    <div
      className={`switch-item h-full flex-centered rounded-8 cursor-pointer ${
        active ? "switch-item-active" : ""
      } ${className}`}
    >
      {children}
    </div>
  );
};

export default SwitchItem;
