import "./DropdownBase.css";
import { useState, useEffect } from "react";
import { Dropable, DropdownMenu } from "./index";
import Overlay, { OverlayProps } from "components/Utils/Overlay";

export type DropdownProps = {
  children: React.ReactNode;
  isOpen?: boolean;
  closeOnClick?: boolean;
  onClose?: () => any;
  classes?: Helpers.ComponentClasses<
    "container" | "dropableContainer" | "menuContainer"
  >;
  overlayProps?: OverlayProps;
};

const Dropdown: React.FC<DropdownProps> = ({
  children,
  isOpen: defaultIsOpen = false,
  closeOnClick = true,
  onClose,
  classes,
  overlayProps,
}: DropdownProps) => {
  const [isOpen, setIsOpen] = useState(defaultIsOpen);

  const asArray = Array.isArray(children) ? children.flat() : [children].flat();
  const dropables = asArray.filter((child) => child?.type === Dropable);
  const dropdownMenus = asArray.filter((child) => child?.type === DropdownMenu);

  // Force change isOpen state depnding on defaultIsOpen prop
  useEffect(() => {
    setIsOpen(defaultIsOpen);
  }, [defaultIsOpen]);

  useEffect(() => {
    // Close modal on escape
    const handleEscape = (e: KeyboardEvent) => {
      if (e.key === "Escape") {
        handleCloseDropdown();
      }
    };

    document.addEventListener("keydown", handleEscape);

    return () => {
      document.removeEventListener("keydown", handleEscape);
    };
  }, []);

  // Fire onClose callback when isOpen is false
  useEffect(() => {
    if (!isOpen) onClose && onClose();
  }, [isOpen]);

  const handleCloseDropdown = (e?: any) => {
    e?.stopPropagation();

    setIsOpen(false);
  };

  const handleToggleDropdown = (e?: any) => {
    e?.stopPropagation();

    setIsOpen((prev) => !prev);
  };

  const handleClickOnMenu = () => {
    closeOnClick && handleCloseDropdown();
  };

  return (
    <div className={`dropdown-container ${classes?.container}`}>
      {/* Dropables */}
      <div
        className={`dropable-container ${classes?.dropableContainer}`}
        onClick={handleToggleDropdown}
      >
        {dropables}
      </div>

      {/* Dropdown Menus */}
      <div
        onClick={handleClickOnMenu}
        className={`dropdown-menu-container ${classes?.menuContainer} ${
          !isOpen ? "" : "dropdown-open"
        }`}
      >
        {dropdownMenus}
      </div>

      {/* Overlay */}
      <Overlay
        portalTarget="#overlay-root"
        className="dropdown-overlay z-200"
        onClick={handleCloseDropdown}
        {...overlayProps}
        show={isOpen}
      />
    </div>
  );
};

export default Dropdown;
