import ModalBase from "./Base/ModalBase";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { createProject } from "../../store/slices/project";
import { DefaultModalHeader, ModalBody } from "./Base/Utils";
import { Input } from "components/Forms/Input";
import { Button } from "components/Forms/Button";
import useAutoFocus from "hooks/useAutoFocus";

const CreateProjectModal = ({ show, handleClose }) => {
  const [projectName, setProjectName] = useState("");

  const inputRef = useAutoFocus();
  const dispatch = useDispatch();

  const handleAddProject = (e) => {
    e?.preventDefault();

    if (projectName.length === "") return;

    dispatch(createProject({ name: projectName })).then(() => {
      setProjectName("");

      handleClose();
    });
  };

  return (
    <ModalBase show={show} handleClose={handleClose}>
      <DefaultModalHeader handleClose={handleClose}>
        New Project
      </DefaultModalHeader>
      <ModalBody className="mt-24 pb-32">
        <form onSubmit={handleAddProject}>
          <Input
            placeholder="Project Name"
            classes={{
              container: "h-40",
            }}
            value={projectName}
            onChange={(e) => setProjectName(e.target.value)}
            inputRef={inputRef}
          />
          <Button className="w-full h-40 mt-12" onClick={handleAddProject}>
            Create Project
          </Button>
        </form>
      </ModalBody>
    </ModalBase>
  );
};

export default CreateProjectModal;
