import { User } from "firebase/auth";
import { injectScriptWithContent } from "utils/DOM";
import { eventEmitter } from "./index";

export function getNeededUserData(user: User) {
  return {
    uid: user.uid,
    name: user.displayName,
    email: user.email,
    emailVerified: user.emailVerified,
    phoneNumber: user.phoneNumber,
    photoURL: user.photoURL,
    providerId: user.providerId,
    ...user.metadata,
  };
}

export default function registerUserEvents() {
  // User identification
  eventEmitter.once("user:identified", (user: User) => {
    const data = getNeededUserData(user);

    // Load Etag scripts
    const content = `
      var userId = '${user.uid}' || null;
      window.hj('identify', userId, {
        'signed_up': '${user.metadata.creationTime}',
        'last_logged_in': '${user.metadata.lastSignInTime}',
      });
    `;
    injectScriptWithContent(content);

    // Boot Intercom
    window.Intercom("boot", data);

    // Identify user for segment
    window.analytics.identify(user.uid, data);
  });

  // User created event
  eventEmitter.on("user:created", (user: User) => {
    const data = getNeededUserData(user);

    window.analytics.track("signup_complete", data);

    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: "signup_complete",
      userId: user.uid,
      email: user.email,
    });
  });
}
