import { configureStore } from "@reduxjs/toolkit";
import appReducer from "./app/app";
import userReducer from "./slices/user";
import projectReducer from "./slices/project";
import boardReducer from "./slices/board";
import libraryReducer from "./slices/library";
import adReducer from "./slices/ad/ad";

export const store = configureStore({
  reducer: {
    app: appReducer,
    user: userReducer,
    project: projectReducer,
    board: boardReducer,
    libraries: libraryReducer,
    ad: adReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }),
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
