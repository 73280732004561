import "./Avatar.css";
import { useState } from "react";
import { DefaultAvatar } from "assets/icons";

export type AvatarProps = {
  src?: string;
  classes?: Helpers.ComponentClasses<"container" | "img">;
  showDefaultOnFail?: boolean;
};

const Avatar: React.FC<AvatarProps> = ({
  src,
  classes,
  showDefaultOnFail = true,
}: AvatarProps) => {
  const [imgSrc, setImgSrc] = useState<String | undefined>(src);
  const img = imgSrc ? imgSrc : showDefaultOnFail ? DefaultAvatar : undefined;

  return (
    <div className={`avatar ${classes?.container}`}>
      <img
        className={classes?.img}
        src={img}
        onError={() => setImgSrc(undefined)}
        alt="Avatar"
      />
    </div>
  );
};

export default Avatar;
