import { useRef } from "react";
import { createRoot } from "react-dom/client";
import Spinner from "components/Loaders/Spinner";

const useUniversalLoader = () => {
  const isLoading = useRef(false);

  const Loader = () => {
    return <Spinner className="spinner-primary" />;
  };

  const startLoading = () => {
    isLoading.current = true;

    const injectionArea = document.querySelector(".navbar .right-side");

    const loaderContainer = document.createElement("div");
    loaderContainer.classList = "universal-loader d-flex items-center";

    injectionArea.prepend(loaderContainer);

    const root = createRoot(loaderContainer);

    root.render(<Loader />);
  };

  const stopLoading = () => {
    isLoading.current = true;

    const loaderContainer = document.querySelector(".universal-loader");

    if (loaderContainer) {
      loaderContainer.remove();
    }
  };

  return { isLoading: isLoading.current, startLoading, stopLoading };
};

export default useUniversalLoader;
