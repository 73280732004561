import { Link, useLocation } from "react-router-dom";
import { SquareButton } from "components/Forms/Button";
import {
  ViewModule,
  ViewModuleWhite,
  Splice,
  SpliceWhite,
  VideosSlices,
  VideosSlicesActive,
} from "assets/icons";

const NavigationLinks = () => {
  const location = useLocation();
  const pathName = location.pathname;

  const navigationLinks = [
    {
      name: "Dashboard",
      to: "/",
      icon: ViewModule,
      activeIcon: ViewModuleWhite,
      isActive: pathName === "/",
    },
    {
      name: "Library",
      to: "/library",
      icon: VideosSlicesActive,
      activeIcon: VideosSlices,
      isActive: pathName.startsWith("/library"),
    },
    // {
    //   name: "Video Tool",
    //   to: "/video",
    //   icon: Splice,
    //   activeIcon: SpliceWhite,
    //   isActive: pathName.startsWith("/video"),
    // },
  ];

  return (
    <div className="navigation-links">
      {navigationLinks.map((link) => (
        <Link to={link.to} key={link.name} className="navigation-link">
          {link.isActive && <span className="active-bar"></span>}
          <SquareButton
            classes={`navigation-btn ${link.isActive ? "active" : ""}`}
          >
            {link.isActive && <img src={link.activeIcon} alt={link.name} />}
            {!link.isActive && <img src={link.icon} alt={link.name} />}
          </SquareButton>
        </Link>
      ))}
    </div>
  );
};

export default NavigationLinks;
