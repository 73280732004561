import { EventEmitter } from "events";
import registerUserEvents from "./user";
import registerBoardEvents from "./board";
import registerAdEvents from "./ad";
import registerChromExtensionEvents from "./extension";
import registerPaymentEvents from "./payments";
import registerSpliceEvents from "./splice";

export const eventEmitter = new EventEmitter();

export function registerApplicationsEvents() {
  registerUserEvents();
  registerBoardEvents();
  registerAdEvents();
  registerChromExtensionEvents();
  registerPaymentEvents();
  registerSpliceEvents();
}

export default eventEmitter;
