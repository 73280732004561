import "./styles.css";
import Navbar from "./Navbar";
import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getAdByLinkId } from "store/slices/ad/ad";
import AdDetailsCard from "components/Cards/AdDetailsCard";
import { toast } from "react-toastify";
import Spinner from "components/Loaders/Spinner";

function Ad() {
  const { id } = useParams();
  const navigate = useNavigate();

  const [ad, setAd] = useState(null);
  const [isBlocked, setIsBlocked] = useState(false);

  const dispatch = useDispatch();

  // Fetching the ad
  useEffect(() => {
    dispatch(getAdByLinkId({ linkId: id })).then((res) => {
      if (res.type === "ad/getAdByLinkId/fulfilled") {
        setAd(res.payload);
      } else if (res.type === "ad/getAdByLinkId/rejected") {
        // If the request is intercepted/rejected by the ad blocker
        // show a toast with the error message
        if (res?.payload?.code === "ERR_NETWORK") {
          setIsBlocked(true);

          return toast("Please stop the Ad Blocker for the Ad to show up", {
            className: "toast-danger",
            autoClose: 15000,
          });
        } else {
          navigate("/");
        }
      }
    });
  }, [id, dispatch, navigate]);

  return (
    <div className="ad-details-page">
      <Navbar />
      <div className="container" style={{ maxWidth: "760px" }}>
        <div className="ad-details flex items-center justify-center">
          {/* Loader */}
          {!ad && !isBlocked && (
            <div className="flex text-primary font-extrabold text-lg items-center justify-center gap-10">
              <Spinner className="spinner-primary" />
              Loading...
            </div>
          )}

          {/* Ad Blocked */}
          {!ad && isBlocked && (
            <div className="flex text-primary font-extrabold text-lg items-center justify-center gap-10">
              Ads are blocked
            </div>
          )}

          {/* Ad Details Card */}
          {ad && <AdDetailsCard ad={ad} />}
        </div>
      </div>
    </div>
  );
}

export default Ad;
