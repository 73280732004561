import Modal from "./Base/ModalBase";
import { DefaultModalHeader, ModalBody } from "./Base/Utils";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { createLibrary } from "store/slices/library";
import { Button } from "components/Forms/Button";
import { Input } from "components/Forms/Input";
import useAutoFocus from "hooks/useAutoFocus";

const CreateLibraryModal = ({ show, handleClose }) => {
  const [libraryName, setLibraryName] = useState("");
  const inputRef = useAutoFocus();

  const dispatch = useDispatch();

  const handleAddLibrary = (e) => {
    e.preventDefault();

    if (libraryName.length === "") return;

    dispatch(createLibrary({ name: libraryName }));

    setLibraryName("");

    handleClose();
  };

  return (
    <Modal show={show} handleClose={handleClose}>
      <DefaultModalHeader handleClose={handleClose}>
        New Folder
      </DefaultModalHeader>
      <ModalBody className="mt-24 pb-32">
        <form onSubmit={handleAddLibrary}>
          <Input
            placeholder="Folder Name"
            classes={{
              container: "h-40",
            }}
            value={libraryName}
            onChange={(e) => setLibraryName(e.target.value)}
            inputRef={inputRef}
          />
          <Button className="w-full h-40 mt-12" onClick={handleAddLibrary}>
            Create Folder
          </Button>
        </form>
      </ModalBody>
    </Modal>
  );
};

export default CreateLibraryModal;
