import Modal from "./Base/ModalBase";
import { DefaultModalHeader, ModalBody } from "./Base/Utils";
import { Button } from "components/Forms/Button";
import { useDispatch } from "react-redux";
import { deleteAd } from "store/slices/ad/ad";

const DeleteAdModal = ({ show, handleClose, ad }) => {
  const dispatch = useDispatch();

  const handleDeleteAd = () => {
    dispatch(deleteAd({ id: ad.id }));
  };

  return (
    <Modal show={show} handleClose={handleClose}>
      <DefaultModalHeader handleClose={handleClose}>
        Delete Ad
      </DefaultModalHeader>
      <ModalBody className="mt-24 pb-32">
        <Button
          className="btn-danger w-full h-40 mt-12"
          onClick={handleDeleteAd}
        >
          Delete Ad
        </Button>
      </ModalBody>
    </Modal>
  );
};

export default DeleteAdModal;
