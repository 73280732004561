import "./BaseSegmentLoader.css";
import LoadingPlaceholder from "react-placeholder-loading";

export type BaseSegmentLoaderProps = {
  width: number;
  height: number;
};

const BaseSegmentLoader: React.FC<BaseSegmentLoaderProps> = ({
  width,
  height,
}: BaseSegmentLoaderProps) => {
  return (
    <div className="segment-loader" style={{ width }}>
      <LoadingPlaceholder
        width={width}
        height={height}
        shape="rect"
        colorStart="#e8e1fa"
        colorEnd="#e1d8f7"
      />
    </div>
  );
};

export default BaseSegmentLoader;
