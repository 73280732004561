import { Checkbox } from "components/Forms/Checkbox";

const SelectboxCheckOption = ({
  children,
  value,
  checked,
  className,
  ...props
}) => {
  return (
    <div className={`selectbox-option option-checkbox ${className}`} {...props}>
      {children}
      <Checkbox checked={checked} />
    </div>
  );
};

export default SelectboxCheckOption;
