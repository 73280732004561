import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import instance from "lib/axios";
import { createFileAndDownload } from "utils/DOM";
import { appConfigs } from "configs";

export const getBoard = createAsyncThunk(
  "board/getBoard",
  async (boardId, { rejectWithValue }) => {
    try {
      const res = await instance.get(`/boards/${boardId}`);

      return res.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const exportBoard = createAsyncThunk(
  "boards/exportBoard",
  async (payload, { rejectWithValue, dispatch }) => {
    dispatch(setIsExporting(true));

    try {
      const { board, options } = payload;
      const joinedOptions = options.join(",");

      const res = await instance.post(
        `/boards/${board.id}/export?options=${joinedOptions}`,
        {},
        {
          responseType: "arraybuffer",
        }
      );

      const file = new Blob([res.data], { type: "application/zip" });
      createFileAndDownload(file, `${board.name}.zip`);

      return res.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const exportFramesToNewBoard = createAsyncThunk(
  "boards/exportFramesToNewBoard",
  async (payload, { rejectWithValue }) => {
    try {
      const { name, project, framesTypes, selectedFrames, providedFrames } =
        payload;

      const res = await instance.post(
        "/export_to_new_board",
        {
          name,
          project_id: project.id,
          frames_types: framesTypes,
          selected_frames: selectedFrames,
          provided_frames: providedFrames,
        },
        {
          baseURL: appConfigs.video_host,
        }
      );

      return res.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const exportToExistingBoard = createAsyncThunk(
  "boards/exportFramesToExistingBoard",
  async (payload, { rejectWithValue }) => {
    try {
      const { board, project, framesTypes, selectedFrames, providedFrames } =
        payload;

      const res = await instance.post(
        "/export_to_board",
        {
          sharable_link_id: board.sharable_link_id,
          project_id: project.id,
          frames_types: framesTypes,
          selected_frames: selectedFrames,
          provided_frames: providedFrames,
        },
        {
          baseURL: appConfigs.video_host,
        }
      );

      return res.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const updateBoardFrames = createAsyncThunk(
  "boards/updateBoardFrames",
  async (payload, { rejectWithValue }) => {
    try {
      const { board, frames } = payload;

      const res = await instance.put(`/boards/${board.id}/frames`, {
        frames,
      });

      return res.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

const initialState = {
  isExporting: false,
};

const board = createSlice({
  name: "board",
  initialState,
  reducers: {
    setIsExporting: (state, action) => {
      state.isExporting = action.payload;
    },
  },
  extraReducers: {
    [getBoard.rejected]: (state, action) => {
      window.location = "/";
    },
    [exportBoard.fulfilled]: (state, action) => {
      toast("Board exported successfully", {
        className: "toast-success",
      });

      state.isExporting = false;
    },
    [exportBoard.rejected]: (state, action) => {
      toast("Error while exporting board", {
        className: "toast-danger",
      });

      state.isExporting = false;
    },
    [exportFramesToNewBoard.fulfilled]: (state, action) => {
      toast("Frames exported successfully", {
        className: "toast-success",
      });
    },
    [exportFramesToNewBoard.rejected]: (state, action) => {
      toast("Error while exporting frames", {
        className: "toast-danger",
      });
    },
    [exportToExistingBoard.fulfilled]: (state, action) => {
      toast("Frames exported successfully", {
        className: "toast-success",
      });
    },
    [exportToExistingBoard.rejected]: (state, action) => {
      toast("Error while exporting frames", {
        className: "toast-danger",
      });
    },
  },
});

export const { setIsExporting } = board.actions;

export default board.reducer;
