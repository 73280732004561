import "./index.css";
import Navbar from "./Navbar";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { auth } from "lib/firebase";
import { fetchUserProjects } from "store/slices/project";
import BoardCard from "components/Cards/BoardCard";
import NewBoardCard from "components/Cards/NewBoardCard";
import CreateProjectModal from "components/Modals/CreateProjectModal";
import CreateBoardModal from "components/Modals/CreateBoardModal";
import BoardCardsLoader from "components/Loaders/BoardCardsLoader";
import ProjectCard from "components/Cards/ProjectCard";
import GetStartedModal from "components/Modals/GetStartedModal";
import { ProjectService } from "services";

function Dashboard() {
  const dispatch = useDispatch();

  const user = useSelector((state) => state.user.localUser);
  const projects = useSelector((state) => state.project.projects);
  const isLoading = useSelector((state) => state.project.isLoading.projects);

  const canCreateProjectBoard = (p) =>
    ProjectService.checkIfProjectOwner(p, user) ||
    ProjectService.checkIfProjectCollaborator(p, user);

  const [showCreateBoardModal, setShowCreateBoardModal] = useState(null);
  const [showCreateProjectModal, setShowCreateProjectModal] = useState(null);

  const primaryProject = projects.find(
    (p) => p.primary && p.user_id === user.uid
  );

  const secondaryProjects = projects
    .filter((p) => p !== primaryProject)
    .sort((a, b) => a.id - b.id);

  useEffect(() => {
    if (auth.currentUser) dispatch(fetchUserProjects());
  }, [user, dispatch]);

  const handleShowCreateProjectModal = () => {
    setShowCreateProjectModal(true);
  };

  useEffect(() => {
    // Force scroll page to top on projects load
    window.scrollTo(0, 0);
  }, [projects]);

  return (
    <div className="dashboard">
      {/* Navbar */}
      <Navbar handleShowCreateProjectModal={handleShowCreateProjectModal} />

      {/* Content */}
      <div className="container dashboard-content">
        {/* Skelton Loader */}
        {isLoading && <BoardCardsLoader />}

        {/* Projects */}
        {!isLoading && (
          <div className="projects-list">
            {[primaryProject, ...secondaryProjects]
              .filter((p) => p)
              .map((p) => (
                <div
                  className="project-container flex flex-col gap-12 w-full"
                  key={p.id}
                >
                  {/* Project Card */}
                  <ProjectCard project={p} />

                  {/* Boards List */}
                  {p.boards.map((board) => (
                    <BoardCard board={board} key={board.id} />
                  ))}

                  {/* New Board Card */}
                  {canCreateProjectBoard(p) && (
                    <NewBoardCard
                      handleClick={() => setShowCreateBoardModal(p)}
                    />
                  )}
                </div>
              ))}
          </div>
        )}
      </div>

      <GetStartedModal />

      <CreateProjectModal
        show={showCreateProjectModal}
        handleClose={() => setShowCreateProjectModal(false)}
      />

      <CreateBoardModal
        show={showCreateBoardModal}
        defaultSelectedProject={showCreateBoardModal}
        handleClose={() => setShowCreateBoardModal(false)}
      />
    </div>
  );
}

export default Dashboard;
