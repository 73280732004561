import { Link } from "react-router-dom";

const NavbarDefaultLeft = () => {
  return (
    <div className="left-side">
      <Link to="/" className="brand">
        <img
          src="/images/MagicBriefLogo.png"
          className="logo hide-sm"
          alt="Magic Brief"
        />

        <img
          src="/images/MagicBriefLogoMini.png"
          className="logo-mini hide-md"
          alt="Magic Brief"
        />
      </Link>
    </div>
  );
};

export default NavbarDefaultLeft;
