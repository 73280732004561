import Modal from "./Base/ModalBase";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import TabsContainer from "../Tabs/TabsContainer";
import Tab from "../Tabs/Tab";
import { SelectOption, Selectbox } from "components/Forms/Selectbox";
import { Input } from "components/Forms/Input";
import { Button } from "components/Forms/Button";
import { LabeledCheckbox } from "components/Forms/Checkbox";
import CheckList from "../Forms/CheckList/CheckList";
import Spinner from "../Loaders/Spinner";
import {
  exportFramesToNewBoard,
  exportToExistingBoard,
} from "../../store/slices/board";
import { DefaultModalHeader, ModalBody } from "./Base/Utils";
import useAutoFocus from "hooks/useAutoFocus";

const ExportFramesModal = ({
  show,
  handleClose,
  selectedFrames,
  providedFrames,
}) => {
  const navigate = useNavigate();
  const [newBoardName, setNewBoardName] = useState("");
  const projects = useSelector((state) => state.project.projects);
  const [selectedProject, setSelectedProject] = useState(projects[0] || null);
  const [selectedBoard, setSelectedBoard] = useState(
    selectedProject?.boards[0] || null
  );
  const [boards, setBoards] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [isExportImages, setIsExportImages] = useState(false);
  const [isExportVideos, setIsExportVideos] = useState(false);

  const inputRef = useAutoFocus();
  const dispatch = useDispatch();

  const handleExportToNewBoard = (e) => {
    e.preventDefault();

    if (
      newBoardName &&
      selectedProject &&
      (isExportImages || isExportVideos) &&
      !isLoading
    ) {
      setIsLoading(true);

      const framesTypes = [];

      if (isExportImages) framesTypes.push("images");
      if (isExportVideos) framesTypes.push("videos");

      const response = dispatch(
        exportFramesToNewBoard({
          name: newBoardName,
          project: selectedProject,
          framesTypes,
          selectedFrames,
          providedFrames,
        })
      );

      response.then((data) => {
        const board = data.payload;

        setIsLoading(false);

        if (data.type === "boards/exportFramesToNewBoard/fulfilled") {
          navigate(`/${board.sharable_link_id}`, { replace: true });
        }
      });
    }
  };

  const handleExportToExistingBoard = (e) => {
    e.preventDefault();

    if (
      selectedBoard &&
      selectedProject &&
      (isExportImages || isExportVideos) &&
      !isLoading
    ) {
      setIsLoading(true);

      const framesTypes = [];

      if (isExportImages) framesTypes.push("images");
      if (isExportVideos) framesTypes.push("videos");

      const response = dispatch(
        exportToExistingBoard({
          board: selectedBoard,
          project: selectedProject,
          framesTypes,
          selectedFrames,
          providedFrames,
        })
      );

      response.then((data) => {
        const board = selectedBoard;

        setIsLoading(false);

        if (data.type === "boards/exportFramesToExistingBoard/fulfilled") {
          navigate(`/${board.sharable_link_id}`, { replace: true });
        }
      });
    }
  };

  const handleProjectOptionChange = (value) => {
    if (value) {
      const project = projects.find((p) => p.id === value.id);

      setSelectedProject(project);
      setBoards(project.boards);
    }
  };

  const handleSelectedBoardChange = (value) => {
    if (value) {
      const board = selectedProject.boards.find((b) => b.id === value.id);

      setSelectedBoard(board);
    }
  };

  return (
    <Modal show={show} handleClose={handleClose}>
      <DefaultModalHeader handleClose={handleClose}>
        Add frames to board
      </DefaultModalHeader>
      <ModalBody className="mt-24 pb-32">
        <TabsContainer>
          <Tab label="Create Board">
            <form onSubmit={(e) => handleExportToNewBoard(e)}>
              <Input
                type="text"
                placeholder="Board Name"
                classes={{
                  container: "h-40",
                }}
                value={newBoardName}
                onChange={(e) => setNewBoardName(e.target.value)}
                inputRef={inputRef}
              />

              <div style={{ marginTop: "12px" }}>
                <Selectbox
                  placeholder="Select Project"
                  onChange={handleProjectOptionChange}
                  classes={{
                    label: "text-primary",
                    options: "text-primary",
                  }}
                >
                  {projects.map((p) => (
                    <SelectOption key={p.id} value={p}>
                      {p.name}
                    </SelectOption>
                  ))}
                </Selectbox>
              </div>

              <div className="mt-12">
                <CheckList>
                  <LabeledCheckbox
                    classes={{
                      label: "text-primary",
                      labelActive: "font-semibold",
                    }}
                    checked={isExportImages}
                    onChange={(checked) => setIsExportImages(checked)}
                  >
                    Images Frames
                  </LabeledCheckbox>
                  <LabeledCheckbox
                    classes={{
                      label: "text-primary",
                      labelActive: "font-semibold",
                    }}
                    checked={isExportVideos}
                    onChange={(checked) => setIsExportVideos(checked)}
                  >
                    Videos Frames
                  </LabeledCheckbox>
                </CheckList>
              </div>

              <Button
                style={{ width: "100%", height: "40px", marginTop: "24px" }}
              >
                {isLoading ? <Spinner /> : "Add Frames"}
              </Button>
            </form>
          </Tab>
          <Tab label="Select Board">
            <form onSubmit={(e) => handleExportToExistingBoard(e)}>
              <div className="mt-12">
                <Selectbox
                  placeholder="Select Project"
                  onChange={handleProjectOptionChange}
                  classes={{
                    label: "text-primary",
                    options: "text-primary",
                  }}
                >
                  {projects.map((p) => (
                    <SelectOption key={p.id} value={p}>
                      {p.name}
                    </SelectOption>
                  ))}
                </Selectbox>
              </div>
              <div className="mt-12">
                <Selectbox
                  placeholder="Select Board"
                  onChange={handleSelectedBoardChange}
                  classes={{
                    label: "text-primary",
                    options: "text-primary",
                  }}
                >
                  {boards.map((b) => (
                    <SelectOption key={b.id} value={b}>
                      {b.name}
                    </SelectOption>
                  ))}
                </Selectbox>
              </div>

              <div className="mt-12">
                <CheckList>
                  <LabeledCheckbox
                    classes={{
                      label: "text-primary",
                      labelActive: "font-semibold",
                    }}
                    checked={isExportImages}
                    onChange={(checked) => setIsExportImages(checked)}
                  >
                    Images Frames
                  </LabeledCheckbox>
                  <LabeledCheckbox
                    classes={{
                      label: "text-primary",
                      labelActive: "font-semibold",
                    }}
                    checked={isExportVideos}
                    onChange={(checked) => setIsExportVideos(checked)}
                  >
                    Videos Frames
                  </LabeledCheckbox>
                </CheckList>
              </div>

              <Button className="w-full h-40 mt-24">
                {isLoading ? <Spinner /> : "Add Frames"}
              </Button>
            </form>
          </Tab>
        </TabsContainer>
      </ModalBody>
    </Modal>
  );
};

export default ExportFramesModal;
