import "./Navbar.css";

const NavbarContainer = ({ children, classes }) => {
  return (
    <nav className={`navbar-wrapper ${classes?.navbarWrapper}`}>
      <div className={`navbar container ${classes?.navbar}`}>{children}</div>
    </nav>
  );
};

export default NavbarContainer;
