export function updateUserLocalData(data: object, merge: boolean = true) {
  const oldData = localStorage.getItem("local");
  const parsedOldData = JSON.parse(oldData || "{}") || {};

  let updated;

  if (merge) updated = { ...parsedOldData, ...data };
  else updated = data;

  localStorage.setItem("local", JSON.stringify(updated));

  return updated;
}

export function getUserLocalData() {
  return JSON.parse(localStorage.getItem("local") || "{}");
}
