import "./AdDetailsCard.css";
import { useState } from "react";
import { formatDistance } from "date-fns";
import * as DOMPurify from "dompurify";
import Carousel from "components/Misc/Carousel";
import Tag from "components/Misc/Tag";
import { ResizeableTextarea } from "components/Forms/Textarea";
import { Button } from "components/Forms/Button";
import Spinner from "components/Loaders/Spinner";
import { DownloadActive, InsertLinkFilled, PhonePCActive } from "assets/icons";
import { createFileAndDownload } from "utils/DOM";
import JSZip from "jszip";
import instance from "lib/axios";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { eventEmitter } from "events/index";

const AdDetailsCard = ({ ad }) => {
  const user = useSelector((state) => state.user.localUser);

  const logoSrc =
    ad.source === "tiktok" ? "/images/tiktok.jpeg" : ad.provider_logo;
  const saveDate = formatDistance(new Date(), new Date(ad.created_at), {
    addSuffix: false,
  });
  const media = ad.ad_media;
  const sanitizedContent = DOMPurify.sanitize(ad.content);
  const formatedLikes = Intl.NumberFormat("en-US", {}).format(ad.likes);

  const [isDownloadingVideo, setIsDownloadingVideo] = useState(false);
  const [isDownloadingMedia, setIsDownloadingMedia] = useState(false);

  const implode = (arr) => arr.join(", ");

  const handleDownloadAsPNG = async () => {
    const name = media[0].src.split("/").pop();
    const res = await instance.get(media[0].src, {
      responseType: "blob",
    });

    const url = window.URL.createObjectURL(new Blob([res.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `${name}.png`);
    document.body.appendChild(link);
    link.click();
  };

  const handleDownloadAsMP4 = async () => {
    setIsDownloadingVideo(true);

    let name = media[0].src.split("/").pop();

    // Force add .mp4 extension
    if (!name.includes(".mp4")) name = name + ".mp4";

    try {
      const res = await instance.get(media[0].src, {
        responseType: "blob",
      });

      createFileAndDownload(res.data, name);
    } catch (e) {
      setIsDownloadingVideo(false);
    }

    setIsDownloadingVideo(false);
  };

  const handleDownloadMedia = async () => {
    setIsDownloadingMedia(true);

    // grap all media and zip them
    const zip = new JSZip();

    for (let i = 0; i < media.length; i++) {
      const medium = media[i];

      try {
        const fileRes = await instance.get(medium.src, {
          responseType: "blob",
        });

        let fileName = medium.src.split("/").pop();

        // Add extensions if not present
        if (medium.type === "image" && !fileName.includes(".png"))
          fileName = fileName + ".png";
        else if (medium.type === "video" && !fileName.includes(".mp4"))
          fileName = fileName + ".mp4";

        zip.file(fileName, fileRes.data);
      } catch (e) {}
    }

    const content = await zip.generateAsync({ type: "blob" });

    createFileAndDownload(content, "media.zip");

    setIsDownloadingMedia(false);
  };

  const handleCopyAdLink = () => {
    const link = `${window.location.origin}/ad/${ad.link_id}`;

    navigator.clipboard.writeText(link);

    toast("Link copied to clipboard", {
      className: "toast-success",
    });

    eventEmitter.emit("ad:link_copied", {
      adId: ad.id,
      link,
    });
  };

  return (
    <div className="ad-details-card">
      <div className="body">
        <div className="left-side pb-24">
          <div className="left-side-header">
            <div className="ad-card-header">
              <div className="ad-info">
                <div className="logo">
                  <img className="w-full" src={logoSrc} alt={""} />
                </div>
                <div className="ad-provider">
                  <h3>{ad.provider_name}</h3>
                  <p>Saved {saveDate} ago</p>
                </div>
              </div>
              <div className="ad-menu"></div>
            </div>
          </div>
          <div className="content">
            <div className="description">
              <p dangerouslySetInnerHTML={{ __html: sanitizedContent }}></p>
            </div>
            <div className="asset">
              {ad.display_format.toLowerCase() === "image" && (
                <div className="asset-img">
                  <img src={media[0].src} alt="" />
                </div>
              )}

              {ad.display_format.toLowerCase() === "video" && (
                <div className="asset-video">
                  <video className="w-full" src={media[0].src} controls />
                </div>
              )}

              {(ad.display_format.toLowerCase() === "dco" ||
                ad.display_format.toLowerCase() === "dpa") && (
                <>
                  {media[0].type.toLowerCase() === "image" && (
                    <div className="asset-img">
                      <img src={media[0].src} alt="" />
                    </div>
                  )}

                  {media[0].type.toLowerCase() === "video" && (
                    <div className="asset-video">
                      <video className="w-full" src={media[0].src} controls />
                    </div>
                  )}
                </>
              )}

              {(ad.display_format.toLowerCase() === "carousel" ||
                ad.display_format.toLowerCase() === "multi_images") && (
                <div className="asset-carousel">
                  <Carousel media={media} />
                </div>
              )}
            </div>
            {ad.link && (
              <a
                href={ad.link}
                className="provider-og"
                target="_blank"
                rel="noreferrer"
              >
                <div className="og-content">
                  <p className="caption">{ad.link_caption}</p>
                  <p className="title">{ad.link_title}</p>
                  <p className="subtitle">{ad.link_subtitle}</p>
                </div>
                <div className="og-action">
                  <button>{ad.link_text}</button>
                </div>
              </a>
            )}
          </div>
        </div>
        <div className="separator"></div>
        <div className="right-side pb-24">
          <h1 className="text-primary font-extrabold text-lg">Ad Details</h1>
          <div className="info mt-24">
            <div className="info-row">
              {ad.cta_type && (
                <div className="info-piece">
                  <p className="label">CTA TYPE</p>
                  <p className="value">{ad.cta_type}</p>
                </div>
              )}
              <div className="info-piece">
                <p className="label">DISPLAY TYPE</p>
                <p className="value">{ad.display_format.toUpperCase()}</p>
              </div>
              <div className="info-piece">
                <p className="label">LIKES</p>
                <p className="value">{formatedLikes}</p>
              </div>
            </div>
            <div className="info-row">
              <div className="info-piece">
                <p className="label">PLATFORMS</p>
                <p className="value">{implode(JSON.parse(ad.platforms))}</p>
              </div>
            </div>
            <div className="info-row">
              <div className="info-piece">
                <p className="label">CATEGORIES</p>
                <p className="value">{implode(JSON.parse(ad.categories))}</p>
              </div>
            </div>
          </div>
          {ad?.ad_tags?.length > 0 && (
            <div className="tags-container">
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <p className="label">TAGS</p>
                <div
                  className="flex"
                  style={{
                    justifyContent: "space-between",
                    alignItems: "flex-start",
                    marginTop: "12px",
                  }}
                >
                  <div className="tags">
                    {ad.ad_tags.map((tag) => (
                      <Tag key={tag.id} removeable={false}>
                        {tag.name}
                      </Tag>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          )}
          {ad.notes && (
            <div className="note-container mt-16">
              <p className="label">Notes</p>
              <ResizeableTextarea
                classes={{ container: "w-full mt-12" }}
                placeholder="Add Notes"
                defaultHeight="121px"
                value={ad.notes || ""}
                onChange={() => {}}
              />
            </div>
          )}
        </div>
      </div>
      <div className="flex card-footer ">
        <div className="footer-actions flex flex-wrap items-center gap-16">
          {media[0].type === "video" && media.length === 1 && (
            <Button
              className="btn-white font-semibold h-40 gap-10"
              style={{ paddingRight: "16px", minWidth: "174px" }}
              onClick={handleDownloadAsMP4}
            >
              {isDownloadingVideo ? (
                <Spinner className="spinner-primary" />
              ) : (
                <>
                  <img src={DownloadActive} alt="Download" />
                  Download MP4
                </>
              )}
            </Button>
          )}
          {media[0].type === "image" && media.length === 1 && (
            <Button
              className="btn-white font-semibold h-40 gap-10"
              style={{ paddingRight: "16px", minWidth: "174px" }}
              onClick={handleDownloadAsPNG}
            >
              <img src={DownloadActive} alt="Download" />
              Download PNG
            </Button>
          )}
          {media.length > 1 && (
            <Button
              className="btn-white font-semibold h-40 gap-10"
              style={{ paddingRight: "16px", minWidth: "174px" }}
              onClick={handleDownloadMedia}
            >
              <img src={DownloadActive} alt="Download" />
              {isDownloadingMedia ? <Spinner /> : "Download Media"}
            </Button>
          )}
          {ad.link && (
            <a
              href={ad.link}
              style={{ textDecoration: "none", minWidth: "164px" }}
              target={"_blank"}
              rel={"noopener noreferrer"}
            >
              <Button
                className="btn-white font-semibold rounded-6 h-40 gap-10 w-full"
                style={{ paddingRight: "16px" }}
              >
                <img src={PhonePCActive} alt="Landing" />
                Landing Page
              </Button>
            </a>
          )}
          <Button
            className="btn-white font-semibold h-40 gap-10"
            style={{ paddingRight: "16px", minWidth: "160px" }}
            onClick={handleCopyAdLink}
          >
            <img src={InsertLinkFilled} alt="Download" />
            Copy ad link
          </Button>
          {!user && (
            <Link to="/register" className="decoration-none">
              <Button
                className="btn-white font-semibold h-40 gap-0"
                style={{ paddingRight: "16px", minWidth: "160px" }}
              >
                <img
                  src="/images/MagicBriefLogoMini.png"
                  width="24px"
                  height="24px"
                  alt="MagicBrief"
                />
                Try MagicBrief
              </Button>
            </Link>
          )}
        </div>
      </div>
    </div>
  );
};

export default AdDetailsCard;
