import { eventEmitter } from "./index";

export default function registerChromExtensionEvents() {
  eventEmitter.on("chrome_extension:authorized", () => {
    window.analytics.track("chrome_extension_authorized", () => {
      // Close window after tracking
      window.close();
    });
  });
}
