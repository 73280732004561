import ReactDOM from "react-dom/client";
import "styles/reset.css";
import "styles/variables.css";
import "styles/index.css";
import { BrowserRouter } from "react-router-dom";
import { store } from "store/store";
import { Provider } from "react-redux";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { appConfigs } from "configs";

import App from "./App";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      staleTime: 1000 * 60 * 60, // 1 hour
      retry: false,
    },
  },
});

const isDevEnv = appConfigs.env === "development";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <QueryClientProvider client={queryClient}>
    <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>

    {isDevEnv && <ReactQueryDevtools initialIsOpen={false} />}
  </QueryClientProvider>
);
