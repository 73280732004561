import Modal from "./Base/ModalBase";
import { DefaultModalHeader, ModalBody } from "./Base/Utils";
import { Button } from "components/Forms/Button";
import { useDispatch } from "react-redux";
import { deleteBoard } from "store/slices/project";

const DeleteBoardModal = ({ show, handleClose, handleDeleted, board }) => {
  const dispatch = useDispatch();

  const handleDeleteBoard = () => {
    dispatch(deleteBoard({ id: board.id }));

    handleDeleted && handleDeleted();
  };

  return (
    <Modal show={show} handleClose={handleClose}>
      <DefaultModalHeader handleClose={handleClose}>
        Delete Board
      </DefaultModalHeader>
      <ModalBody className="mt-24 pb-32">
        <Button
          className="btn-danger w-full h-40 mt-12"
          onClick={handleDeleteBoard}
        >
          Delete Board
        </Button>
      </ModalBody>
    </Modal>
  );
};

export default DeleteBoardModal;
