import { eventEmitter } from "./index";

export default function registerAdEvents() {
  eventEmitter.on("ad:link_copied", (data) => {
    window.analytics.track("ad_link_copied", data);
  });

  eventEmitter.on("library:link_copied", (data) => {
    window.analytics.track("library_link_copied", data);
  });
}
