import TitleLoader from "components/Loaders/TitleLoader";
import { InsertLinkFilled } from "assets/icons";
import NavbarContainer from "components/Navbar/NavbarContainer";
import NavbarDefaultLeft from "components/Navbar/NavbarDefaultLeft";
import { Button, SquareButton } from "components/Forms/Button";
import { Link } from "react-router-dom";

export type GuestNavbarProps = {
  isLoading: boolean;
  handleCopyLibraryLink: () => void;
};

const GustNavbar: React.FC<GuestNavbarProps> = ({
  isLoading,
  handleCopyLibraryLink,
}: GuestNavbarProps) => {
  return (
    <NavbarContainer classes={{ navbarWrapper: "border-0" }}>
      <NavbarDefaultLeft />
      <div className="right-side">
        {isLoading && <TitleLoader />}
        {!isLoading && (
          <>
            <div className="flex items-center gap-16">
              <p className="font-semibold text-primary hide-sm">
                Save ad references in seconds!
              </p>
              <Link className="decoration-none" to="/register">
                <Button
                  className="h-40 btn-gradient"
                  style={{ width: "137px" }}
                >
                  Try MagicBrief
                </Button>
              </Link>
            </div>
            <SquareButton
              classes="btn-white border-1 border-color-20"
              onClick={handleCopyLibraryLink}
            >
              <img src={InsertLinkFilled} alt="Copy Link" />
            </SquareButton>
          </>
        )}
      </div>
    </NavbarContainer>
  );
};

export default GustNavbar;
