import "./styles.css";
import { useState, useEffect, useRef } from "react";
import { Sortable, SortableItemHOC } from "components/Dnd";
import { arrayMoveImmutable } from "array-move";
import { useParams } from "react-router-dom";
import { useDebounce } from "use-debounce";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { sortByField } from "utils/arrays";
import { getBoard } from "store/slices/board";
import instance from "lib/axios";
import Navbar from "./Navbar";
import NewFrameCard from "components/Cards/NewFrameCard";
import BoardFrameCard from "components/Cards/BoardFrameCard";
import FrameCardsLoader from "components/Loaders/FrameCardsLoader";
import Overlay from "components/Utils/Overlay";
import HorizontalBoardFrameCard from "components/Cards/HorizontalBoardFrameCard";
import { eventEmitter } from "events/index";
import { appConfigs } from "configs";

const FRAME_TEMPLATE = {
  order: 0,
  type: null,
  src: null,
  label: "",
  action: "",
  sound: "",
  caption: "",
  link: "",
};

const SortableGridFrameCard = ({ id, ...props }) =>
  SortableItemHOC(BoardFrameCard, id, props);

const SortableHorizontalFrameCard = ({ id, ...props }) =>
  SortableItemHOC(HorizontalBoardFrameCard, id, props);

function Board() {
  const { id } = useParams();
  const dispatch = useDispatch();

  const user = useSelector((state) => state.user.localUser);

  const [isReadOnly, setIsReadOnly] = useState(true);

  const [board, setBoard] = useState(null);
  const [boardFrames, setBoardFrames] = useState([FRAME_TEMPLATE]);
  const [markedFrames, setMarkedFrames] = useState([]);
  const [debouncedBoardFrames] = useDebounce(boardFrames, 900);
  const [lastSavedBoardFrames, setLastSavedBoardFrames] = useState(null);

  const [isBoardFramesJustLoaded, setIsBoardFramesJustLoaded] = useState(true);

  const [isLoading, setIsLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);

  const [showExtras, setShowExtras] = useState(
    JSON.parse(localStorage.getItem("showExtras") || false)
  );
  const [boardView, setBoardView] = useState(
    localStorage.getItem("boardView") || "grid"
  );

  const BoardFrameCardComponent =
    boardView === "grid" ? SortableGridFrameCard : SortableHorizontalFrameCard;

  const canAddMoreFrames = boardFrames.length < appConfigs.max_frames_per_board;

  const currentSaveRequestController = useRef(null);

  // Watch for user edit permission
  useEffect(() => {
    const isAuthorOrCollaborator = () => {
      if (!user) return false;

      const userInCollablorators = board.collaborators.find(
        (c) => c.user_id === user.uid
      );

      if (user.uid === board.user_id || userInCollablorators !== undefined)
        return true;

      return false;
    };

    if (board) {
      const canEdit = isAuthorOrCollaborator();

      setIsReadOnly(!canEdit);
    }
  }, [user, board]);

  // Initial fetch of page content and assets
  useEffect(() => {
    dispatch(getBoard(id)).then((res) => {
      const board = res.payload;

      setBoard(board);

      setLastSavedBoardFrames(board.frames);

      setIsLoading(false);
    });
  }, []);

  // Watch for changes in board frames change frames state
  useEffect(() => {
    if (board) {
      setBoardFrames(board.frames);
      setIsBoardFramesJustLoaded(false);
    }
  }, [board]);

  const didChangeFromLastSave = () => boardFrames !== lastSavedBoardFrames;

  // Watch for every change and check if board frames are different from last saved
  // If they aren', start saving loader
  useEffect(() => {
    if (isBoardFramesJustLoaded) return;

    if (didChangeFromLastSave()) {
      setIsSaving(true);
    } else {
      setIsSaving(false);
    }
  });

  useEffect(() => {
    // Save board frames to server when debounce frames change
    if (didChangeFromLastSave()) handleSaveEdits();
  }, [debouncedBoardFrames]);

  const handleAddFrame = () => {
    const frame = { ...FRAME_TEMPLATE, order: boardFrames.length + 1 };

    console.debug("Adding frame with order", frame.order);

    setBoardFrames((prev) => [...prev, frame]);

    eventEmitter.emit("board:frame_added", {
      boardId: board.id,
      frameOrder: frame.order,
    });
  };

  const handleAddEmptyFrameAfter = (frame) => {
    console.debug("Adding after: ", frame);

    handleUnMarkAllFrames();

    setBoardFrames((prev) => {
      let newFrames = [...prev, FRAME_TEMPLATE];

      newFrames = arrayMoveImmutable(
        newFrames,
        newFrames.length - 1,
        frame.order + 1
      );

      return reOrderFramesByIndex(newFrames);
    });

    eventEmitter.emit("board:frame_added", {
      boardId: board.id,
      frameOrder: frame.order + 1,
    });
  };

  const handleUnMarkAllFrames = () => setMarkedFrames([]);

  const handleCopyPageLinkToClipboard = () => {
    const link = (appConfigs.short_url || window.location.origin) + "/" + id;

    navigator.clipboard.writeText(link);

    toast("Copied to clipboard", {
      className: "toast-success",
    });

    eventEmitter.emit("board:link_copied", { boardId: board.id, link });
  };

  const handleChangeOrder = (firstOrder, secondOrder) => {
    if (isReadOnly) return;

    setBoardFrames((prev) => {
      const newFrames = arrayMoveImmutable(prev, firstOrder, secondOrder);

      const reOrdered = reOrderFramesByIndex(newFrames);

      return reOrdered;
    });
  };

  const handleToggleFrameMark = (frame) => {
    if (isReadOnly) return;

    setMarkedFrames((prev) => {
      if (prev.includes(frame)) {
        return prev.filter((f) => f !== frame);
      }

      return [...prev, frame];
    });
  };

  const handleDeleteMarkedFrames = () => {
    let msg;

    if (markedFrames.length === 1)
      msg = "Are you sure you want to delete this frame?";
    else msg = "Are you sure you want to delete these frames?";

    const confirmed = window.confirm(msg);

    if (!confirmed) return;

    console.debug("Deleting marked frames: ", markedFrames);

    setBoardFrames((prev) => {
      const framesWithoutMark = prev.filter(
        (frame) => !markedFrames.includes(frame)
      );

      return [...framesWithoutMark];
    });

    handleUnMarkAllFrames();
  };

  const handleEditFrame = (frame, updatedFrame) => {
    if (isReadOnly) return;

    console.debug(
      "Editing frame: ",
      boardFrames.filter((f) => f.order === frame.order)[0],
      "To: ",
      updatedFrame
    );

    setBoardFrames((prev) => {
      const otherFrames = prev.filter((f) => f.order !== frame.order);
      const currentUpdatedFrame = { ...frame, ...updatedFrame };
      const newFrames = [...otherFrames, currentUpdatedFrame];

      const newFramesSorted = sortByField(newFrames, "order", "asc");

      return newFramesSorted;
    });

    handleUnMarkAllFrames();
  };

  const handleSaveEdits = () => {
    const toBeSaved = boardFrames;

    if (isReadOnly) return;

    console.debug("Proceeding to save content: ", boardFrames);

    if (currentSaveRequestController.current) {
      currentSaveRequestController.current.abort();
    }

    const controller = new AbortController();
    currentSaveRequestController.current = controller;

    try {
      instance
        .put(
          `/boards/${board.id}/frames`,
          {
            frames: boardFrames,
          },
          {
            signal: controller.signal,
          }
        )
        .then(() => {
          setLastSavedBoardFrames(toBeSaved);
        });
    } catch (e) {}
  };

  // Watch saving status and block user from leaving page
  useEffect(() => {
    const callback = (e) => {
      if (isSaving) {
        e.returnValue =
          "You have unsaved changes. Are you sure you want to leave?";
      }
    };

    window.addEventListener("beforeunload", callback);

    return () => {
      window.removeEventListener("beforeunload", callback);
    };
  });

  const reOrderFramesByIndex = (frames) =>
    frames.map((frame, index) => ({ ...frame, order: index }));

  const handleDragEnd = (event) => {
    const { active, over } = event;

    // No change in order
    if (active.id === over.id) return;

    handleChangeOrder(active.id - 1, over.id - 1);

    setMarkedFrames([]);
  };

  const handleToggleShowExtras = () => {
    localStorage.setItem("showExtras", !showExtras);

    setShowExtras((prev) => !prev);
  };

  const handleChangeBoardView = (view) => {
    localStorage.setItem("boardView", view);

    setBoardView(view);
  };

  return (
    <div className="board-page">
      <Navbar
        board={board}
        isLoading={isLoading}
        handleCopyPageLinkToClipboard={handleCopyPageLinkToClipboard}
        markedCount={markedFrames.length}
        handleDeleteMarkedFrames={handleDeleteMarkedFrames}
        isUpdatingContent={isSaving}
        showExtras={showExtras}
        handleToggleShowExtras={handleToggleShowExtras}
        boardView={boardView}
        handleChangeBoardView={handleChangeBoardView}
      />

      <div className="magic-brief-main flex flex-col overflow-x-clip">
        {/* Frames Skelton Loader */}
        {isLoading && (
          <div className="container">
            <FrameCardsLoader />
          </div>
        )}

        {/* Board Frames */}
        {!isLoading && (
          <div
            className={`board-frames ${
              boardView === "grid" ? "frames-grid-view" : "frames-list-view"
            } w-full container`}
          >
            {/* Sortable Frame Cards */}
            <Sortable
              items={boardFrames.map((f) => f.order + 1)}
              onDragEnd={handleDragEnd}
            >
              {boardFrames.map((frame, i) => (
                <BoardFrameCardComponent
                  frame={frame}
                  id={frame.order + 1}
                  key={frame.order + 1}
                  isMarked={markedFrames.includes(frame)}
                  handleEditFrame={handleEditFrame}
                  handleToggleMark={handleToggleFrameMark}
                  handleUnMarkAllFrames={handleUnMarkAllFrames}
                  handleAddAfter={handleAddEmptyFrameAfter}
                  isLastCard={i === boardFrames.length - 1}
                  readOnly={isReadOnly}
                  showFullContent={showExtras}
                  transition="all 1s ease"
                />
              ))}
            </Sortable>

            {/* Add Frame Card */}
            {canAddMoreFrames && !isReadOnly && (
              <NewFrameCard handleAddFrame={handleAddFrame} />
            )}

            <Overlay
              show={true}
              className="z-0"
              onClick={handleUnMarkAllFrames}
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default Board;
