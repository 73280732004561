import axios from "axios";
import { auth } from "./firebase";
import { appConfigs } from "configs";

const instance = axios.create({
  baseURL: appConfigs.host,
});

instance.interceptors.request.use(async (config) => {
  /**
   * Ask Firebase app instance for token. This
   * should always return the current token or a new
   * token if the cached one is expiring.
   *
   * @see https://firebase.google.com/docs/reference/js/auth.user.md#usergetidtoken
   */
  const firebaseToken = await auth.currentUser?.getIdToken();
  const authToken = `Bearer ${firebaseToken}`;
  config.headers = {
    ...config.headers,
    Authorization: authToken,
  };
  return config;
});

export default instance;
