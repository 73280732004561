import "./Tabs.css";
import { useState } from "react";

const TabsContainer = ({ children, active }) => {
  const [activeTab, setActiveTab] = useState(active || 0);

  const handleSwitchTab = (index) => {
    setActiveTab(index);
  };

  if (!Array.isArray(children)) children = [children];

  return (
    <div className="tabs-container">
      {/* Tabs Head */}
      <ul className="tabs-list">
        <div className="border-prefix"></div>

        {children.map((tab, index) => {
          const { label } = tab.props;
          const isActive = activeTab === index;

          return (
            <li
              key={index}
              className={`tab-item text-md ${isActive ? "tab-active" : ""}`}
              onClick={() => handleSwitchTab(index)}
            >
              {label}
            </li>
          );
        })}
      </ul>

      {/* Tabs Content */}
      <div className="tab-body">
        {children.map((TabComponent, index) => {
          const isActive = activeTab === index;

          return isActive ? TabComponent : null;
        })}
      </div>
    </div>
  );
};

export default TabsContainer;
