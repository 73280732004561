import { Routes, Route } from "react-router-dom";
import RequireAuth from "./components/HOCs/RequireAuth";
import RequireNotAuth from "./components/HOCs/RequireNotAuth";
import Login from "./pages/Login/Index";
import Register from "./pages/Register/Index";
import ResetPassword from "pages/ResetPassword/ResetPassword";
import Dashboard from "./pages/Dashboard/Index";
import { useDispatch } from "react-redux";
import { auth } from "./lib/firebase";
import { setToLocalUser } from "./store/slices/user";
import ToastWrapper from "components/Utils/ToastWrapper";
import Video from "pages/Video/Index";
import Library from "pages/Library/Index";
import Board from "pages/Board/Index";
import AuthExtension from "./pages/AuthExtension";
import RequireActiveSubscription from "components/HOCs/RequreActiveSubscription";
import AdPage from "pages/Ad/Index";
import EmailVerification from "pages/EmailVerification/Index";
import * as Sentry from "@sentry/react";
import { initSentry } from "./lib/sentry";
import { eventEmitter, registerApplicationsEvents } from "events/index";

// Sentry
initSentry();

registerApplicationsEvents();

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

function App() {
  const dispatch = useDispatch();

  auth.onAuthStateChanged(async (user) => {
    dispatch(setToLocalUser({ user }));

    if (user) {
      const token = await user.getIdTokenResult(true);

      if (token?.claims?.stripeRole === undefined) {
        // Refresh token
        await user.getIdTokenResult(true);
      }

      eventEmitter.emit("user:identified", user);
    }
  });

  return (
    <>
      <SentryRoutes>
        <Route
          path="/"
          element={
            <RequireAuth>
              <RequireActiveSubscription>
                <Dashboard />
              </RequireActiveSubscription>
            </RequireAuth>
          }
        />
        <Route
          path="/login"
          element={
            <RequireNotAuth>
              <Login />
            </RequireNotAuth>
          }
        />
        <Route
          path="/register"
          element={
            <RequireNotAuth>
              <Register />
            </RequireNotAuth>
          }
        />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route
          path="/video"
          element={
            <RequireAuth>
              <RequireActiveSubscription>
                <Video />
              </RequireActiveSubscription>
            </RequireAuth>
          }
        />
        <Route
          path="/library"
          element={
            <RequireAuth>
              <RequireActiveSubscription>
                <Library />
              </RequireActiveSubscription>
            </RequireAuth>
          }
        ></Route>
        <Route path="/library/:id" element={<Library />} />
        <Route path="/auth-extension" element={<AuthExtension />} />
        <Route path=":id" element={<Board />} />
        <Route path="ad/:id" element={<AdPage />} />
        <Route path="/email-verification" element={<EmailVerification />} />
      </SentryRoutes>
      <ToastWrapper />
    </>
  );
}

export default App;
