/* global chrome */
import { useSelector } from "react-redux";
import { useLocation, Navigate } from "react-router-dom";
import { auth, updateUserAdditionalData } from "../lib/firebase";
import instance from "../lib/axios";
import Spinner from "components/Loaders/Spinner";
import { eventEmitter } from "events/index";

function AuthExtension() {
  const localUser = useSelector((state) => state.user.localUser);
  const location = useLocation();

  const searchParams = new URLSearchParams(location.search);

  const extensionId = searchParams.get("extension_id");

  auth.onAuthStateChanged(async (user) => {
    if (user) {
      const res = await instance.post("/users/generateCustomToken");
      const token = res.data.token;
      try {
        chrome.runtime.sendMessage(
          extensionId,
          {
            type: "loginWithToken",
            data: {
              token: token,
            },
          },
          async (response) => {
            if (response.success) {
              localStorage.setItem("extensionId", extensionId);

              await updateUserAdditionalData("setup", {
                extensionInstalled: true,
              });

              eventEmitter.emit("chrome_extension:authorized");

              // setTimeout(() => {
              //   window.close();
              // }, 3000);
            }
          }
        );
      } catch (error) {
        console.log(error);
      }
    }
  });

  if (!localUser) {
    return <Navigate to={"/login"} />;
  } else {
    return (
      <div className="fully-centered">
        <Spinner className="spinner-primary" />
      </div>
    );
  }
}

export default AuthExtension;
