export function arrayUniqueByField(array: any[], field: string) {
  return array.filter((obj, pos, arr) => {
    return arr.map((mapObj) => mapObj[field]).indexOf(obj[field]) === pos;
  });
}

export function arrayUnique(array: any[]) {
  if (!array) return array;

  return array.filter((a, b) => array.indexOf(a) === b);
}

export function sortByField(array: any[], field: string, order = "desc") {
  const clone = [...array];

  if (!array) return array;

  return clone.sort((a, b) => {
    if (order === "asc") {
      return a[field] > b[field] ? 1 : -1;
    } else {
      return a[field] < b[field] ? 1 : -1;
    }
  });
}
