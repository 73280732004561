import "./ExtensionInstalledBanner.css";
import { Button } from "components/Forms/Button";
import {
  LightBulb,
  SmoothArrowBottomLeft,
  SmoothArrowRight,
  XIcon,
} from "assets/icons";
import { useEffect, useState } from "react";
import {
  auth,
  updateUserAdditionalData,
  checkIfUserSkippedExtensionTutorial,
  checkIfUserInstalledChromeExtensionBefore,
} from "lib/firebase";
import { updateUserLocalData } from "utils/user";

export type ExtensionInstalledBannerProps = {
  handleShowInspirationModal: () => void;
} & React.HTMLAttributes<HTMLDivElement>;

const ExtensionInstalledBanner: React.FC<ExtensionInstalledBannerProps> = ({
  handleShowInspirationModal,
  ...props
}: ExtensionInstalledBannerProps) => {
  const [isSkippedExtentionTutorial, setIsSkippedExtentionTutorial] =
    useState(true);

  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      if (user) {
        checkIfUserSkippedExtensionTutorial().then((isSkipped) => {
          checkIfUserInstalledChromeExtensionBefore().then((isInstalled) => {
            if (isInstalled) setIsSkippedExtentionTutorial(isSkipped);
          });
        });
      }
    });
  }, []);

  const handleSkipExtensionTutorial = async () => {
    updateUserLocalData({ didSkipExtensionTutorial: true });

    setIsSkippedExtentionTutorial(true);

    await updateUserAdditionalData("setup", { skippedExtensionTutorial: true });
  };

  return isSkippedExtentionTutorial ? (
    <></>
  ) : (
    <div
      className="extension-installed-banner relative rounded-8 w-full flex"
      {...props}
    >
      <div className="left-side flex flex-col justify-between flex-1">
        <h1 className="text-primary text-2xl font-extrabold title">
          Extension Installed!
        </h1>
        <div className="flex flex-col">
          <img
            src={SmoothArrowRight}
            className="step2-arrow"
            alt="Arrow Right"
            width="60px"
          />
          <div className="flex items-center">
            <p className="step-label">Step 2</p>
            <Button
              className="top-ads-button"
              onClick={handleShowInspirationModal}
            >
              <img src={LightBulb} alt="Idea" />
              View top Ad Libraries
            </Button>
          </div>
        </div>
      </div>
      <div className="right-side flex flex-1">
        <img
          src="/images/FacebookVerticalAdCard.png"
          className="fb-ad-card"
          alt="Facebook Ad"
        />
        <img
          src={SmoothArrowBottomLeft}
          className="arrow-bottom-left"
          alt="Arrow Left"
          width="50px"
        />
        <div className="flex flex-col items-start justify-center step-3">
          <p className="step-label">Step 3</p>
          <p className="text-primary font-semibold content text-md">
            Use the <span className="font-extrabold">Save Ad</span> button to{" "}
            <br /> download your favourite <br /> ads from the{" "}
            <span
              className="font-extrabold underline cursor-pointer"
              onClick={handleShowInspirationModal}
            >
              Ad Libraries
            </span>
            .
          </p>
        </div>
      </div>
      <img
        src={XIcon}
        className="absolute cursor-pointer close-icon"
        alt="Close"
        onClick={handleSkipExtensionTutorial}
      />
    </div>
  );
};

export default ExtensionInstalledBanner;
