import "./ProjectCard.css";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IconButton } from "components/Forms/Button";
import {
  DropdownBase,
  Dropable,
  DropdownMenu,
  DropdownMenuItem,
} from "components/Dropdown";
import DeleteProjectModal from "components/Modals/DeleteProjectModal";
import RenameProjectModal from "components/Modals/RenameProjectModal";
import CollaborationControlMenu from "components/Misc/CollaborationControlMenu";
import { AddPerson, DotsWhite, EditFilled, TrashFilledRed } from "assets/icons";
import { Project } from "interfaces/Project";
import { ProjectCollaboration } from "interfaces/Collaboration";
import {
  createProjectCollaboration,
  deleteProjectCollaboration,
  fetchProjectCollaborations,
} from "api/projects";
import { ProjectService } from "services";
import { changeProjectCollaboratorsCount } from "store/slices/project";

export type ProjectCardProps = {
  project: Project;
};

const ProjectCard: React.FC<ProjectCardProps> = ({
  project,
}: ProjectCardProps) => {
  const dispatch = useDispatch();

  const user = useSelector((state: any) => state.user.localUser);
  const isOwner = ProjectService.checkIfProjectOwner(project, user);
  const isCollaborator = ProjectService.checkIfProjectCollaborator(
    project,
    user
  );

  const [showCollaborationMenu, setShowCollaborationMenu] = useState(false);
  const [showDeleteProjectModal, setShowDeleteProjectModal] = useState(false);
  const [showRenameProjectModal, setShowRenameProjectModal] = useState(false);

  const collaboratorsText = (count: number) => {
    if (count === 1) return "COLLABORATOR";
    return "COLLABORATORS";
  };

  const handleSetProjectCollaborationCount = (count?: number) => {
    dispatch(
      changeProjectCollaboratorsCount({
        projectId: project.id,
        count: count || 0,
      })
    );
  };

  return (
    <div className="project-card flex items-center justify-between rounded-8 h-40 text-white">
      {/* Project Title */}
      <p className="project-name font-extrabold text-md uppercase">
        {project.name}
      </p>

      <div className="flex items-center gap-16">
        {/* Collaborators Count */}
        {project._count && (
          <p className="text-sm uppercase font-extrabold">
            {project._count?.project_collaboration}
            &nbsp;
            {/* Collaborators text */}
            {collaboratorsText(project._count?.project_collaboration || 0)}
          </p>
        )}

        {/* Project Options Menu */}
        {(isOwner || isCollaborator) && (
          <DropdownBase
            closeOnClick={false}
            overlayProps={{ show: true, className: "z-100" }}
            onClose={() => setShowCollaborationMenu(false)}
          >
            <Dropable>
              <IconButton style={{ height: "27px", width: "27px" }}>
                <img src={DotsWhite} alt="More" />
              </IconButton>
            </Dropable>

            {/* Collaboration Menu */}
            {showCollaborationMenu && (
              <DropdownMenu className="border-0 rounded-16">
                <CollaborationControlMenu<ProjectCollaboration>
                  item={{ id: project.id, title: project.name }}
                  readOnly={false}
                  showShareToPublic={false}
                  addQuery={(project, email) =>
                    createProjectCollaboration(project.id, email)
                  }
                  fetchQuery={(project) =>
                    fetchProjectCollaborations(project.id)
                  }
                  removeQuery={(project, c) => deleteProjectCollaboration(c)}
                  classes={{
                    inviteSection: "px-22 py-16",
                  }}
                  events={{
                    onCollaborationAdded: () =>
                      handleSetProjectCollaborationCount(
                        (project._count?.project_collaboration || 0) + 1
                      ),
                    onCollaborationDeleted: () =>
                      handleSetProjectCollaborationCount(
                        (project._count?.project_collaboration || 1) - 1
                      ),
                  }}
                />
              </DropdownMenu>
            )}

            {/* Options Menu */}
            {!showCollaborationMenu && (
              <DropdownMenu>
                <DropdownMenuItem
                  onClick={() => setShowCollaborationMenu(true)}
                >
                  <img src={AddPerson} alt="Add Collaborators" />
                  <span style={{ paddingRight: "9px" }}>Share Project</span>
                </DropdownMenuItem>

                <DropdownMenuItem
                  onClick={() => setShowRenameProjectModal(true)}
                >
                  <img src={EditFilled} alt="Rename Project" />
                  <span style={{ paddingRight: "9px" }}>Rename Project</span>
                </DropdownMenuItem>

                {/* Non primary projects only can be deleted by the owner */}
                {!project.primary && isOwner && (
                  <DropdownMenuItem
                    onClick={() => setShowDeleteProjectModal(true)}
                  >
                    <img src={TrashFilledRed} alt="Delete Project" />
                    <span className="text-danger">Delete Project</span>
                  </DropdownMenuItem>
                )}
              </DropdownMenu>
            )}
          </DropdownBase>
        )}
      </div>

      <DeleteProjectModal
        show={showDeleteProjectModal}
        handleClose={() => setShowDeleteProjectModal(false)}
        project={project}
      />

      <RenameProjectModal
        show={showRenameProjectModal}
        handleClose={() => setShowRenameProjectModal(false)}
        project={project}
      />
    </div>
  );
};

export default ProjectCard;
