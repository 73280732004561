import "./AdsFiltersBar.css";
import { useState, useEffect, useCallback } from "react";
import {
  Selectbox,
  SelectOption,
  MultiSelectbox,
  CheckboxOption,
  SelectboxAction,
} from "components/Forms/Selectbox";
import { Button } from "components/Forms/Button";
import { PlusIconActive, XIcon } from "assets/icons";
import { useDispatch, useSelector } from "react-redux";
import { fetchUserLibraries } from "store/slices/library";
import CreateLibraryModal from "components/Modals/CreateLibraryModal";
import DeleteLibraryModal from "components/Modals/DeleteLibraryModal";
import { fetchAdsFilterOptions, fetchUserAds } from "store/slices/ad/ad";
import { arrayUnique } from "utils/arrays";
import { auth } from "lib/firebase";

const platforms = [
  { name: "Tiktok", value: "Tiktok" },
  { name: "Facebook", value: "facebook" },
  { name: "Instagram", value: "instagram" },
  { name: "Messenger", value: "messenger" },
  { name: "Audience Network", value: "audience_network" },
];

const formats = [
  { name: "Video", value: "video" },
  { name: "Static", value: "static" },
  { name: "Carousel", value: "carousel" },
];

const sortOrder = [
  { name: "Newest First", value: "desc" },
  { name: "Oldest First", value: "asc" },
];

const AdsFilterBar = ({ handleSetSelectedLibrary }) => {
  const dispatch = useDispatch();

  const user = useSelector((state) => state.user.localUser);
  const libraries = useSelector((state) => state.libraries.libraries);
  const filterOptions = useSelector((state) => state.ad.filterOptions);

  const [selectedPlatforms, setSelectedPlatforms] = useState([]);
  const [selectedIndustries, setSelectedIndustries] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);
  const [selectedBrands, setSelectedBrands] = useState([]);
  const [selectedFormats, setSelectedFormats] = useState([]);

  const [filter, setFilter] = useState({});
  const [sortOptions, setSortOptions] = useState();
  const [isInitialLoad, setIsInitialLoad] = useState(true);

  const [showCreateLibraryModal, setShowCreateLibraryModal] = useState(false);
  const [showDeleteLibraryModal, setShowDeleteLibraryModal] = useState(false);

  // Fetch filtering options
  useEffect(() => {
    if (!auth.currentUser) return;

    dispatch(fetchUserLibraries());
    dispatch(fetchAdsFilterOptions());
  }, [user, dispatch]);

  // Handle updating filter object on filter change
  useEffect(() => {
    if (isInitialLoad) return;

    dispatch(fetchUserAds({ options: { filter, sort: sortOptions } }));
  }, [filter, sortOptions]);

  useEffect(() => {
    setIsInitialLoad(false);
  }, []);

  // Toggle a value in an array of state
  const handleToggleInArrayState = (setState, value) => {
    setState((prev) => {
      const alreadySelected = prev.find((el) => el === value);

      if (alreadySelected) {
        return prev.filter((el) => el !== value);
      } else {
        return [...prev, value];
      }
    });
  };

  const handleLibraryChange = (value) => {
    if (value) {
      handleSetSelectedLibrary(value);

      setFilter({
        library_id: value.id,
      });
    } else {
      setFilter({});
    }
  };

  const handlePlatfromsChange = (value) => {
    handleToggleInArrayState(setSelectedPlatforms, value);
  };

  const handleApplyFilter = (filterName, values) => {
    closeSelectMenu();

    setFilter((prev) => {
      return {
        ...prev,
        [filterName]: values,
      };
    });
  };

  const handleResetFilter = (setState, filterName) => {
    closeSelectMenu();

    setState([]);

    setFilter((prev) => {
      const newFilter = { ...prev };

      delete newFilter[filterName];

      return newFilter;
    });
  };

  const handleResetPlatforms = () => {
    handleResetFilter(setSelectedPlatforms, "platforms");
  };

  const handleTagsChange = (value) => {
    handleToggleInArrayState(setSelectedTags, value.name);
  };

  const handleResetTags = () => {
    handleResetFilter(setSelectedTags, "tags");
  };

  const handleIndustryChange = (value) => {
    handleToggleInArrayState(setSelectedIndustries, value);
  };

  const handleResetIndustries = () => {
    handleResetFilter(setSelectedIndustries, "industries");
  };

  const handleBrandsChange = (value) => {
    handleToggleInArrayState(setSelectedBrands, value);
  };

  const handleResetBrands = () => {
    handleResetFilter(setSelectedBrands, "brands");
  };

  const handleFormatChange = (value) => {
    handleToggleInArrayState(setSelectedFormats, value);
  };

  const handleResetFormats = () => {
    handleResetFilter(setSelectedFormats, "formats");
  };

  const handleSortOrderChange = (value) => {
    setSortOptions({
      id: value,
    });
  };

  const stringSearch = useCallback((searchKey, value) => {
    return value.toLowerCase().includes(searchKey.toLowerCase());
  }, []);

  const handleShowCreateLibraryModal = () => {
    setShowCreateLibraryModal(true);
  };

  const handleShowDeleteLibraryModal = (e, library) => {
    e.stopPropagation();

    setShowDeleteLibraryModal(library);
  };

  const closeSelectMenu = () => {
    const overlays = document.querySelectorAll(".selectbox-overlay");

    overlays.forEach((el) => el.click());
  };

  return (
    <div className="ads-filter-bar z-200">
      <div className="filters-list container">
        {/* Libraries Filter */}
        <Selectbox
          placeholder="All Ads"
          classes={{
            container: "border-color-25",
            placeholder: "text-primary font-extrabold",
          }}
          onChange={handleLibraryChange}
        >
          {/* All ads selection */}
          <SelectOption value={null} className="text-primary font-extrabold">
            All Ads
          </SelectOption>

          {libraries.map((library) => (
            <SelectOption
              key={library.id}
              value={library}
              label={library.name}
              className="text-primary font-extrabold"
            >
              <div className="library-option">
                <p className="text-primary font-extrabold">{library.name}</p>
                <button
                  className="btn-clear"
                  onClick={(e) => handleShowDeleteLibraryModal(e, library)}
                >
                  <img src={XIcon} alt="Remove" />
                </button>
              </div>
            </SelectOption>
          ))}
          <SelectboxAction>
            <div
              className="new-library-option d-flex items-center w-full"
              style={{ borderBottom: "1px solid #3d1caf4d" }}
              onClick={handleShowCreateLibraryModal}
            >
              <img
                src={PlusIconActive}
                alt="New Library"
                className="bg-secondary rounded-4 p-5"
              />
              <p className="text-primary font-extrabold">New Folder</p>
            </div>
          </SelectboxAction>
        </Selectbox>

        {/* Platfroms Filter */}
        <MultiSelectbox
          placeholder="Platforms"
          onChange={handlePlatfromsChange}
          searchOptions={{
            placeholder: "Search Platforms",
            filter: stringSearch,
          }}
          classes={{
            container: "border-color-25",
            placeholder: "text-primary font-extrabold",
          }}
          resetable={filter.platforms?.length > 0}
          handleReset={handleResetPlatforms}
        >
          {platforms.map((platform) => (
            <CheckboxOption
              className="text-primary font-extrabold"
              key={platform.value}
              value={platform.value}
              checked={selectedPlatforms.includes(platform.value)}
              onClick={() => handlePlatfromsChange(platform.value)}
            >
              {platform.name}
            </CheckboxOption>
          ))}

          <SelectboxAction>
            <div className="apply-reset-actions flex flex-col w-full p-12">
              <Button
                className="btn-default h-40"
                onClick={() =>
                  handleApplyFilter("platforms", selectedPlatforms)
                }
              >
                Apply Platforms
              </Button>
              <Button
                className="btn-white border-0 shadow-0 p-0 mt-8"
                onClick={handleResetPlatforms}
              >
                Reset
              </Button>
            </div>
          </SelectboxAction>
        </MultiSelectbox>

        {/* Industry Filter */}
        <MultiSelectbox
          placeholder="Industry"
          onChange={handleIndustryChange}
          searchOptions={{
            placeholder: "Search Industry",
            filter: stringSearch,
          }}
          classes={{
            container: "border-color-25",
            placeholder: "text-primary font-extrabold",
          }}
          resetable={filter.industries?.length > 0}
          handleReset={handleResetIndustries}
        >
          {filterOptions.categories.map((industry) => (
            <CheckboxOption
              className="text-primary font-extrabold"
              value={industry}
              key={industry}
              checked={selectedIndustries.includes(industry)}
              onClick={() => handleIndustryChange(industry)}
            >
              {industry}
            </CheckboxOption>
          ))}
          <SelectboxAction>
            <div className="apply-reset-actions flex flex-col w-full p-12">
              <Button
                className="btn-default h-40"
                onClick={() =>
                  handleApplyFilter("industries", selectedIndustries)
                }
              >
                Apply Industries
              </Button>
              <Button
                className="btn-white border-0 shadow-0 p-0 mt-8"
                onClick={handleResetIndustries}
              >
                Reset
              </Button>
            </div>
          </SelectboxAction>
        </MultiSelectbox>

        {/* Tags Filter */}
        <MultiSelectbox
          placeholder="Tags"
          onChange={handleTagsChange}
          searchOptions={{
            placeholder: "Search Tags",
            filter: stringSearch,
          }}
          classes={{
            container: "border-color-25",
            placeholder: "text-primary font-extrabold",
          }}
          resetable={filter.tags?.length > 0}
          handleReset={handleResetTags}
        >
          {filterOptions.tags.map((tag) => (
            <CheckboxOption
              className="text-primary font-extrabold"
              key={tag.id}
              value={tag}
              checked={selectedTags.includes(tag.name)}
              onClick={() => handleTagsChange(tag)}
            >
              {tag.name}
            </CheckboxOption>
          ))}
          <SelectboxAction>
            <div className="apply-reset-actions flex flex-col w-full p-12">
              <Button
                className="btn-default h-40"
                onClick={() => handleApplyFilter("tags", selectedTags)}
              >
                Apply Tags
              </Button>
              <Button
                className="btn-white border-0 shadow-0 p-0 mt-8"
                onClick={handleResetTags}
              >
                Reset
              </Button>
            </div>
          </SelectboxAction>
        </MultiSelectbox>

        {/* Brands Filter */}
        <MultiSelectbox
          placeholder="Brands"
          searchOptions={{
            placeholder: "Search Brands",
            filter: stringSearch,
          }}
          classes={{
            container: "border-color-25",
            placeholder: "text-primary font-extrabold",
          }}
          resetable={filter.brands?.length > 0}
          handleReset={handleResetBrands}
        >
          {arrayUnique(filterOptions.providers).map((brand) => (
            <CheckboxOption
              className="text-primary font-extrabold"
              value={brand}
              key={brand}
              checked={selectedBrands.includes(brand)}
              onClick={() => handleBrandsChange(brand)}
            >
              {brand}
            </CheckboxOption>
          ))}
          <SelectboxAction>
            <div className="apply-reset-actions flex flex-col w-full p-12">
              <Button
                className="btn-default h-40"
                onClick={() => handleApplyFilter("brands", selectedBrands)}
              >
                Apply Brands
              </Button>
              <Button
                className="btn-white border-0 shadow-0 p-0 mt-8"
                onClick={handleResetBrands}
              >
                Reset
              </Button>
            </div>
          </SelectboxAction>
        </MultiSelectbox>

        {/* Format Filter */}
        <MultiSelectbox
          placeholder="Format"
          searchOptions={{
            placeholder: "Search Formats",
            filter: stringSearch,
          }}
          classes={{
            container: "border-color-25",
            placeholder: "text-primary font-extrabold",
          }}
          resetable={filter.formats?.length > 0}
          handleReset={handleResetFormats}
        >
          {formats.map((format) => (
            <CheckboxOption
              className="text-primary font-extrabold"
              value={format.value}
              key={format.value}
              checked={selectedFormats.includes(format.value)}
              onClick={() => handleFormatChange(format.value)}
            >
              {format.name}
            </CheckboxOption>
          ))}
          <SelectboxAction>
            <div className="apply-reset-actions flex flex-col w-full p-12">
              <Button
                className="btn-default h-40"
                onClick={() => handleApplyFilter("formats", selectedFormats)}
              >
                Apply Format
              </Button>
              <Button
                className="btn-white border-0 shadow-0 p-0 mt-8"
                onClick={handleResetFormats}
              >
                Reset
              </Button>
            </div>
          </SelectboxAction>
        </MultiSelectbox>

        {/* Sort Order */}
        <Selectbox
          placeholder="Sort Order"
          classes={{
            container: "border-color-25",
            placeholder: "text-primary font-extrabold",
          }}
          onChange={handleSortOrderChange}
        >
          {sortOrder.map((sortOrder) => (
            <SelectOption
              className="text-primary font-extrabold"
              value={sortOrder.value}
              key={sortOrder.value}
              label={sortOrder.name.split(" ")[0]}
            >
              {sortOrder.name}
            </SelectOption>
          ))}
        </Selectbox>
      </div>

      <CreateLibraryModal
        show={showCreateLibraryModal}
        handleClose={() => setShowCreateLibraryModal(false)}
      />

      <DeleteLibraryModal
        show={showDeleteLibraryModal !== false}
        library={showDeleteLibraryModal}
        handleClose={() => setShowDeleteLibraryModal(false)}
        handleDeleted={() => handleLibraryChange(libraries[0])}
      />
    </div>
  );
};

export default AdsFilterBar;
