import "./BoardCard.css";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { EditableText } from "components/Forms/Input";
import { IconButton } from "components/Forms/Button";
import {
  DropdownBase,
  Dropable,
  DropdownMenu,
  DropdownMenuItem,
} from "components/Dropdown";
import {
  duplicatedBoard,
  editBoard,
  removeCollaborator,
} from "store/slices/project";
import { Link } from "react-router-dom";
import Avatar from "components/Misc/Avatar";
import DeleteBoardModal from "components/Modals/DeleteBoardModal";
import AddTeamMemberModal from "components/Modals/AddBoardCollaboratorModal";
import { toast } from "react-toastify";
import {
  EditFilled,
  InsertLinkFilled,
  MoreFilled,
  TrashFilledRed,
  AddPerson,
  FileCopy,
  CloseIconRed,
} from "assets/icons";
import { appConfigs } from "configs";
import StatusDropdown from "components/Dropdown/StatusDropdown";

const BoardCard = ({ board }) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.localUser);

  const [boardName, setBoardName] = useState(board.name);
  const [editMode, setEditMode] = useState(false);

  const [showDeleteBoardModal, setShowDeleteBoardModal] = useState(false);
  const [showAddTeamModal, setShowAddTeamModal] = useState(false);

  const formatedDate = new Date(board.updated_at)
    .toLocaleDateString("en-GB", {
      day: "numeric",
      month: "long",
      year: "2-digit",
    })
    .toUpperCase();

  const handleRenameBoard = () => {
    dispatch(
      editBoard({
        id: board.id,
        name: boardName,
        message: `Board Renamed to "${boardName}"`,
      })
    );

    setEditMode(false);
  };

  const handleCopyBoardLink = () => {
    const link = appConfigs.short_url + "/" + board.sharable_link_id;

    navigator.clipboard.writeText(link);

    toast("Link copied to clipboard", { className: "toast-success" });
  };

  const handleDeleteBoardTeamMember = (id) => {
    const collaborator = board.collaborators.find((c) => c.user_id === id);

    dispatch(removeCollaborator({ collaborator }));
  };

  const handleDuplicatedBoard = () => {
    dispatch(duplicatedBoard({ id: board.id }));
  };

  const handleEditableTextChange = (value, editable) => {
    setBoardName(value);
    setEditMode(editable);
  };

  const handleStatusChange = (status) => {
    dispatch(editBoard({ id: board.id, status }));
  };

  return (
    <div className="board-card">
      <div className="board-title text-primary">
        {/* Board Name Input */}
        {editMode && (
          <div className="board-title text-primary">
            <EditableText
              currentValue={board.name}
              onSubmit={handleRenameBoard}
              placeholder="Board Name"
              isEditing={editMode}
              onChange={handleEditableTextChange}
              classes={{ container: "w-full" }}
            />
          </div>
        )}

        {/* Board Data */}
        {!editMode && (
          <Link to={`/${board.sharable_link_id}`} className="board-description">
            <p>{boardName}</p>
            <p className="board-update-info">
              {board._count?.frames || 1}{" "}
              {board._count?.frames > 1 ? "FRAMES" : "FRAME"} -{" "}
              {board.updated_at === board.created_at ? "CREATED" : "UPDATED"}{" "}
              {formatedDate} BY{"  "}
              <span className="uppercase">
                {board.updated_by?.split(" ")[0] || user?.name.split(" ")[0]}
              </span>
            </p>
          </Link>
        )}
      </div>

      <div className="board-actions" onClick={(e) => e.preventDefault()}>
        {/* Avatars of collaborators */}
        {board.collaborators?.map((c) => (
          <DropdownBase key={c.id} overlayProps={{ className: "z-100" }}>
            <Dropable>
              <Avatar classes={{ container: "avatar-sm" }} src={c.avatar} />
            </Dropable>
            <DropdownMenu>
              <div
                className="flex items-center justify-between text-primary font-semibold gap-8"
                style={{ padding: "8px 12px" }}
              >
                {c.email}
                {board.user_id === user.uid && (
                  <img
                    className="cursor-pointer"
                    src={CloseIconRed}
                    alt="Remove"
                    onClick={() => handleDeleteBoardTeamMember(c.user_id)}
                  />
                )}
              </div>
            </DropdownMenu>
          </DropdownBase>
        ))}

        {/* Board Status */}
        <div className="board-status">
          <StatusDropdown
            defaultStatus={board.status}
            onChange={handleStatusChange}
          />
        </div>

        {/* Card Options */}
        <DropdownBase classes={{ menuContainer: "z-300" }}>
          <Dropable>
            <IconButton>
              <img src={MoreFilled} alt="More" />
            </IconButton>
          </Dropable>
          <DropdownMenu>
            <DropdownMenuItem onClick={() => setShowAddTeamModal(true)}>
              <img src={AddPerson} alt="Add editor" />
              <span>Add Editor</span>
            </DropdownMenuItem>
            <DropdownMenuItem onClick={() => setEditMode(true)}>
              <img src={EditFilled} alt="Rename" />
              <span>Rename Board</span>
            </DropdownMenuItem>
            <DropdownMenuItem onClick={handleCopyBoardLink}>
              <img src={InsertLinkFilled} alt="Copy Link" />
              <span style={{ minWidth: "126px" }}>Copy Board Link</span>
            </DropdownMenuItem>
            <DropdownMenuItem onClick={handleDuplicatedBoard}>
              <img src={FileCopy} alt="Duplicate" />
              <span>Duplicate Board</span>
            </DropdownMenuItem>
            <DropdownMenuItem onClick={() => setShowDeleteBoardModal(true)}>
              <img src={TrashFilledRed} alt="Delete" />
              <span className="text-danger">Delete Board</span>
            </DropdownMenuItem>
          </DropdownMenu>
        </DropdownBase>
      </div>

      <AddTeamMemberModal
        show={showAddTeamModal}
        board={board}
        handleClose={() => setShowAddTeamModal(false)}
      />

      <DeleteBoardModal
        show={showDeleteBoardModal}
        handleClose={() => setShowDeleteBoardModal(false)}
        board={board}
      />
    </div>
  );
};

export default BoardCard;
