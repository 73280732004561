import useIsPremium from "hooks/useIsPremium";
import useIsFreePlan from "hooks/useIsFreePlan";
import FreeTrialEndedModal from "components/Modals/FreeTrialEndedModal";

const RequireActiveSubscription = ({ children }) => {
  const isPremium = useIsPremium();
  const isFreePlan = useIsFreePlan();

  // if (!isPremium && !isFreePlan) {
  if (false) {
    return (
      <>
        <FreeTrialEndedModal />
        {children}
      </>
    );
  } else {
    return children;
  }
};

export default RequireActiveSubscription;
