import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import instance from "lib/axios";

export const fetchUserLibraries = createAsyncThunk(
  "libraries/fetchLibraries",
  async (payload, { rejectWithValue }) => {
    try {
      const res = await instance.get("/users/libraries");

      return res.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const fetchLibraryAds = createAsyncThunk(
  "libraries/fetchAds",
  async (payload, { rejectWithValue }) => {
    try {
      const { id } = payload;

      const res = await instance.get("/libraries/" + id + "/ads");

      return { id, data: res.data };
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const fetchLibraryByLinkId = createAsyncThunk(
  "libraries/fetchLibraryByLinkId",
  async (payload, { rejectWithValue }) => {
    try {
      const { link_id } = payload;

      const res = await instance.get("/libraries/" + link_id);

      return res.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const moveMulti = createAsyncThunk(
  "libraries/moveMulti",
  async (payload, { rejectWithValue }) => {
    const moved = [];

    try {
      const { ids, libraryId } = payload;

      for (let i = 0; i < ids.length; i++) {
        const id = ids[i];

        const res = await instance.patch("/ads/" + id, {
          library_id: parseInt(libraryId),
        });

        moved.push(res.data);
      }

      return { ads: moved, libraryId };
    } catch (e) {
      return rejectWithValue({ error: e, moved });
    }
  }
);

export const deleteMultiAd = createAsyncThunk(
  "libraries/deleteMultiAd",
  async (payload, { rejectWithValue }) => {
    try {
      const { ids } = payload;

      await instance.delete("/ads?ids=" + ids.toString());

      return { ids };
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const fetchFilteredAds = createAsyncThunk(
  "libraries/fetchFilteredAds",
  async (payload, { rejectWithValue, getState }) => {
    try {
      const filter = payload;

      const editedFilter = {};

      // Clean up filters and sort them
      Object.keys(filter)
        .sort()
        .forEach((key) => {
          const value = filter[key];

          if (
            value === null ||
            value === undefined ||
            value === "" ||
            value.length === 0
          )
            return;

          editedFilter[key] = value;
        });

      if (Object.keys(editedFilter).length <= 1) return;

      const filterQueryParams = new URLSearchParams(editedFilter).toString();

      const filterState = getState().libraries.filters;

      // If the filter was already applied, use it
      if (filterState[filterQueryParams]) {
        return {
          filter: filterQueryParams,
          ads: filterState[filterQueryParams],
        };
      }

      const res = await instance.get("/ads?" + filterQueryParams);

      return {
        filter: filterQueryParams,
        ads: res.data,
      };
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const createLibrary = createAsyncThunk(
  "libraries/createLibrary",
  async (payload, { rejectWithValue }) => {
    try {
      const { name, industry } = payload;

      const res = await instance.post("/libraries", {
        name,
        industry,
      });

      return res.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const deleteLibrary = createAsyncThunk(
  "libraries/deleteLibrary",
  async (payload, { rejectWithValue, getState }) => {
    try {
      const { id } = payload;

      const libraries = getState().libraries.libraries;

      if (libraries.length === 1) {
        toast("You can't delete your last library", {
          className: "toast-danger",
        });

        return;
      }

      await instance.delete("/libraries/" + id);

      return { id };
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

const initialState = {
  libraries: [],
  filters: {
    currentFilter: null,
    isLoading: null,
  },
};

const libraries = createSlice({
  name: "libraries",
  initialState,
  reducers: {
    setLibraries: (state, action) => {
      state.libraries = action.payload;
    },
    addFilter: (state, action) => {
      const { filter, ads } = action.payload;

      state.filters[filter] = ads;
    },
    resetCurrentFilter: (state) => {
      state.filters.currentFilter = null;
    },
  },
  extraReducers: {
    [fetchUserLibraries.fulfilled]: (state, action) => {
      state.libraries = action.payload;
    },
    [fetchUserLibraries.rejected]: (state, action) => {
      toast("Error fetching libraries", {
        className: "toast-danger",
      });
    },
    [fetchLibraryAds.fulfilled]: (state, action) => {
      const { id, data } = action.payload;

      const libraryIndex = state.libraries.findIndex((l) => l.id === id);
      const library = { ...state.libraries[libraryIndex], ads: data };

      state.libraries[libraryIndex] = library;
    },
    [fetchLibraryAds.rejected]: (state, action) => {
      toast("Error fetching ads", {
        className: "toast-danger",
      });
    },

    [moveMulti.fulfilled]: (state, action) => {
      const { ads, libraryId } = action.payload;
      const ids = ads.map((ad) => ad.id);

      state.libraries.forEach((library) => {
        if (library.ads) {
          library.ads = library.ads.filter((ad) => !ids.includes(ad.id));
        }

        if (library.id === libraryId) {
          library.ads = library.ads.concat(ads);
        }
      });

      toast("Ads moved successfully", {
        className: "toast-success",
      });
    },

    [deleteMultiAd.fulfilled]: (state, action) => {
      const { ids } = action.payload;

      state.libraries.forEach((library) => {
        if (library.ads) {
          library.ads = library.ads.filter((ad) => !ids.includes(ad.id));
        }
      });

      Object.keys(state.filters).forEach((filter) => {
        if (filter === "currentFilter" || filter === "isLoading") return;

        state.filters[filter] = state.filters[filter].filter(
          (ad) => !ids.includes(ad.id)
        );
      });

      toast("Ads deleted successfully", {
        className: "toast-success",
      });
    },
    [deleteMultiAd.rejected]: (state, action) => {
      toast("Error deleting ads", {
        className: "toast-danger",
      });
    },
    [fetchLibraryByLinkId.fulfilled]: (state, action) => {
      const data = action.payload;

      state.libraries.push(data);
    },
    [fetchLibraryByLinkId.rejected]: (state, action) => {
      toast("Error fetching library", {
        className: "toast-danger",
      });
    },
    [fetchFilteredAds.pending]: (state, action) => {
      state.filters.isLoading = true;
    },
    [fetchFilteredAds.fulfilled]: (state, action) => {
      if (!action?.payload?.filter) return;

      const { filter, ads } = action.payload;

      state.filters[filter] = ads;

      state.filters.currentFilter = filter;
      state.filters.isLoading = false;
    },
    [fetchFilteredAds.rejected]: (state, action) => {
      toast("Error Filtering ads", {
        className: "toast-danger",
      });

      state.filters.isLoading = false;
    },
    [createLibrary.fulfilled]: (state, action) => {
      const data = action.payload;

      data.ads = [];
      data.tags = [];
      data.brands = [];
      data.categories = [];

      state.libraries.push(data);

      toast("Library created successfully", {
        className: "toast-success",
      });
    },
    [createLibrary.rejected]: (state, action) => {
      toast("Error creating folder", {
        className: "toast-danger",
      });
    },
    [deleteLibrary.fulfilled]: (state, action) => {
      const { id } = action.payload;

      state.libraries = state.libraries.filter((l) => l.id !== id);
    },
    [deleteLibrary.rejected]: (state, action) => {
      toast("Error deleting folder", {
        className: "toast-danger",
      });
    },
  },
});

export const { setLibraries, addFilter, resetCurrentFilter } =
  libraries.actions;

export default libraries.reducer;
