import { useCallback } from "react";

const useAutoFocus = () => {
  const inputRef = useCallback((input: HTMLInputElement) => {
    if (input) {
      input.focus();
    }
  }, []);

  return inputRef;
};

export default useAutoFocus;
