import "./HorizontalBoardFrameCard.css";
import { useState, useRef, ChangeEvent } from "react";
import { BoardFrame, Type } from "types/DataObjects";
import {
  CloudUploading,
  DragIndicator,
  UploadButton,
  UploadIcon,
} from "assets/icons";
import { IconButton } from "components/Forms/Button";
import { Textarea } from "components/Forms/Textarea";
import { getFileType } from "utils/mics";
import { uploadFile } from "utils/requests";
import Spinner from "components/Loaders/Spinner";
import Overlay from "components/Utils/Overlay";
import { useSelector } from "react-redux";

export type HorizontalFrameCardProps = {
  frame: BoardFrame;
  isMarked: Boolean;
  handleToggleMark: (frame: BoardFrame) => void;
  handleEditFrame: (frame: BoardFrame, content: Partial<BoardFrame>) => void;
  isLastCard: Boolean;
  readOnly: Boolean;
  draggingOptions: any;
};

const HorizontalBoardFrameCard: React.FC<HorizontalFrameCardProps> = ({
  frame,
  isMarked,
  handleToggleMark,
  handleEditFrame,
  readOnly,
  draggingOptions,
}) => {
  const [isUploading, setIsUploading] = useState(false);
  const [isLoadingMedia, setIsLoadingMedia] = useState(true);

  const uploadInputRef = useRef<HTMLInputElement>(null);
  const progressBarRef = useRef() as React.MutableRefObject<HTMLDivElement>;

  const frameFields = useSelector(
    (state: any) => state.app.boardFrameFields
  ).map((f: any) => f.name);

  const frameOrder = (frame.order + 1).toLocaleString("en-US", {
    minimumIntegerDigits: 2,
    useGrouping: false,
  });

  const handleOpenFileUpload = () => {
    !readOnly && uploadInputRef.current?.click();
  };

  const handleUploadAsset: React.ChangeEventHandler<HTMLInputElement> = async (
    e
  ) => {
    const file = e.target.files?.[0];

    if (!file) return;

    const fileType = getFileType(file) as Type;

    if (isUploading || !["image", "video"].includes(fileType)) return;

    setIsUploading(true);

    try {
      let res = await uploadFile(file, {
        onUploadProgress: (progress: any) => {
          const { total, loaded } = progress;
          const totalSizeInMB = total / 1000000;
          const loadedSizeInMB = loaded / 1000000;
          const uploadPercentage = (loadedSizeInMB / totalSizeInMB) * 100;

          progressBarRef.current.style.width = `${uploadPercentage}%`;
        },
        encType: "multipart/form-data",
      });
      if (res.status === 201) {
        const result_data = await res.data;

        const updatedFrame = {
          src: result_data.src,
          type: fileType,
        };

        handleEditFrame(frame, updatedFrame);
      } else {
        setIsUploading(false);
      }
    } catch (e) {
      setIsUploading(false);
    }

    setIsUploading(false);
  };

  const handleEditFieldValue = (fieldName: string, value: string) => {
    !readOnly && handleEditFrame(frame, { [fieldName]: value });
  };

  const handleMediaLoaded = () => {
    setIsLoadingMedia(false);
  };

  const handleMediaLoadError = () => {
    setIsLoadingMedia(false);
  };

  // Dragging Handlers
  const style = { ...draggingOptions?.styles };

  // Dragging styles
  // Stop drop animation
  if (
    !draggingOptions?.isDragging &&
    !draggingOptions?.isSorting &&
    !draggingOptions?.isOver
  )
    style.transition = "none";

  // Dragged frame always on top
  if (draggingOptions?.isDragging) {
    style.zIndex = "9999";
    style.touchAction = "none";
  }

  return (
    <div
      className={`horizontal-frame-card relative w-full rounded-8 bg-white flex gap-12 p-24 border-2 ${
        isMarked ? "marked" : ""
      }`}
      style={style}
    >
      <div className="flex flex-col items-center justify-between">
        {/* Frame Order */}
        <p className="text-primary font-extrabold">{frameOrder}</p>

        {/* Dragging Handler */}
        {!readOnly && draggingOptions && (
          <IconButton className="w-24 h-24 rounded-4">
            <img
              src={DragIndicator}
              ref={draggingOptions.setNodeRef}
              {...draggingOptions.attributes}
              {...draggingOptions.listeners}
              alt="Drag Handler"
              width="16px"
              height="16px"
            />
          </IconButton>
        )}

        {/* Dummy div */}
        <div>&nbsp;</div>
      </div>

      {/* Frame Asset */}
      {frameFields.includes("Asset") && (
        <div className="asset-container relative flex-centered rounded-4 border-1 border-color-20 bg-light-pink flex-1">
          {/* Asset Upload Input */}
          <input
            name="asset"
            type="file"
            className="hidden"
            max={1}
            ref={uploadInputRef}
            onChange={handleUploadAsset}
            accept="image/*,video/*"
          />

          {/* Asset Upload Icon */}
          {!readOnly && frame.src && !isUploading && (
            <div
              className="asset-upload-icon cursor-pointer absolute opacity-0 transition-0.5 z-200"
              onClick={handleOpenFileUpload}
            >
              <img src={UploadButton} alt="Upload Icon" className="w-24 h-24" />
            </div>
          )}

          {/* Empty Asset */}
          {!isUploading && !frame.src && (
            <div
              className="empty-asset fully-centered cursor-pointer"
              onClick={handleOpenFileUpload}
            >
              <img src={UploadIcon} alt="Upload" />
            </div>
          )}

          {/* Asset Uploading */}
          {isUploading && (
            <div className="uploading-asset flex flex-col gap-8 items-center justify-center">
              <img src={CloudUploading} alt="Uploading..." />
              <p className="text-primary text-sm font-semibold">Uploading</p>
              <div className="asset-loader-progress">
                <div className="loaded" ref={progressBarRef}></div>
              </div>
            </div>
          )}

          {/* Asset Loading */}
          {frame.src && isLoadingMedia && (
            <div className="asset-loader flex-centered flex-col">
              <div className="loading-spinner">
                <Spinner className="spinner-primary spinner-light" />
              </div>
              <p className="text-primary">Loading</p>
            </div>
          )}

          {/* Asset Image */}
          {!isUploading && frame.type === "image" && (
            <div className={`image-asset ${isLoadingMedia ? "hidden" : ""}`}>
              <img
                src={frame.src}
                alt="Media"
                className="w-full h-auto"
                onLoad={handleMediaLoaded}
                onError={handleMediaLoadError}
              />
            </div>
          )}

          {/* Asset Video */}
          {!isUploading && frame.type === "video" && (
            <div className={`video-asset ${isLoadingMedia ? "hidden" : ""}`}>
              <video
                className="w-full h-auto"
                controls
                onLoad={handleMediaLoaded}
                onError={handleMediaLoadError}
                onLoadedMetadata={handleMediaLoadError}
              >
                <source src={frame.src} />
              </video>
            </div>
          )}
        </div>
      )}

      {/* Frame Label */}
      {frameFields.includes("Label") && (
        <Textarea
          classes={{
            container: "flex-1",
          }}
          placeholder="Label"
          value={frame.label}
          onChange={(e: ChangeEvent<HTMLTextAreaElement>) =>
            handleEditFieldValue("label", e.target.value)
          }
        />
      )}

      {/* Frame Sound */}
      {frameFields.includes("Sound") && (
        <Textarea
          classes={{
            container: "flex-2",
          }}
          placeholder="Sound"
          value={frame.sound}
          onChange={(e: ChangeEvent<HTMLTextAreaElement>) =>
            handleEditFieldValue("sound", e.target.value)
          }
        />
      )}

      {/* Frame Action */}
      {frameFields.includes("Action") && (
        <Textarea
          classes={{
            container: "flex-2",
          }}
          placeholder="Action"
          value={frame.action}
          onChange={(e: ChangeEvent<HTMLTextAreaElement>) =>
            handleEditFieldValue("action", e.target.value)
          }
        />
      )}

      {/* Frame Caption */}
      {frameFields.includes("Caption") && (
        <Textarea
          classes={{
            container: "flex-1",
          }}
          placeholder="Caption"
          value={frame.caption}
          onChange={(e: ChangeEvent<HTMLTextAreaElement>) =>
            handleEditFieldValue("caption", e.target.value)
          }
        />
      )}

      <Overlay
        show={true}
        className="absolute inset-0 z--1"
        onClick={() => handleToggleMark(frame)}
      />
    </div>
  );
};

export default HorizontalBoardFrameCard;
