import "./BoardCardsLoader.css";
import LoadingPlaceholder from "react-placeholder-loading";

const BoardCardsLoader = () => {
  return (
    <div className="boards-loader">
      <LoadingPlaceholder
        width="100%"
        height={40}
        shape="rect"
        colorStart="#e8e1fa"
        colorEnd="#e1d8f7"
      />
      <LoadingPlaceholder
        width="100%"
        height={72}
        shape="rect"
        colorStart="#e8e1fa"
        colorEnd="#e1d8f7"
      />
      <LoadingPlaceholder
        width="100%"
        height={72}
        shape="rect"
        colorStart="#e8e1fa"
        colorEnd="#e1d8f7"
      />
      <LoadingPlaceholder
        width="100%"
        height={72}
        shape="rect"
        colorStart="#e8e1fa"
        colorEnd="#e1d8f7"
      />
      <LoadingPlaceholder
        width="100%"
        height={72}
        shape="rect"
        colorStart="#e8e1fa"
        colorEnd="#e1d8f7"
      />
    </div>
  );
};

export default BoardCardsLoader;
