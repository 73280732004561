import Modal from "./Base/ModalBase";
import { DefaultModalHeader, ModalBody } from "./Base/Utils";
import { useEffect, useState } from "react";
import { Button } from "components/Forms/Button";
import { CheckList } from "components/Forms/CheckList";
import { LabeledCheckbox } from "components/Forms/Checkbox";
import { setBoardFrameFields } from "store/app/app";
import { allBoardFrameFields } from "store/app/const";
import { useSelector, useDispatch } from "react-redux";

const SelectBoardFrameFieldsModal = ({ show, handleClose }) => {
  const fields = useSelector((state) => state.app.boardFrameFields);
  const dispatch = useDispatch();

  const [tempFields, setTempFields] = useState(fields.map((f) => f.name));

  useEffect(() => {
    setTempFields(fields.map((f) => f.name));
  }, [show]);

  const handleToggleField = (field) => {
    if (tempFields.includes(field)) {
      setTempFields((prev) => prev.filter((f) => f !== field));
    } else {
      setTempFields((prev) => [...prev, field]);
    }
  };

  const handleApplySelection = () => {
    const filteredFields = allBoardFrameFields
      .filter((f) => tempFields.includes(f.name))
      .map((f) => f.name);

    dispatch(setBoardFrameFields(filteredFields));

    handleClose();
  };

  return (
    <Modal show={show} handleClose={handleClose}>
      <DefaultModalHeader handleClose={handleClose}>
        Show Labels
      </DefaultModalHeader>
      <ModalBody className="mt-24 pb-32">
        <CheckList
          classes={{
            container: "flex flex-col gap-8",
          }}
        >
          {allBoardFrameFields.map((field) => (
            <LabeledCheckbox
              key={field}
              classes={{
                container: "checkbox-no-border",
                label: "text-primary",
                labelActive: "font-semibold",
              }}
              checked={fields.map((f) => f.name).includes(field.name)}
              onChange={() => handleToggleField(field.name)}
            >
              {field.name}
            </LabeledCheckbox>
          ))}
        </CheckList>
        <Button className="w-full h-40 mt-24" onClick={handleApplySelection}>
          Apply
        </Button>
      </ModalBody>
    </Modal>
  );
};

export default SelectBoardFrameFieldsModal;
