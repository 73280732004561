import { auth } from "lib/firebase";
import { useState, useEffect } from "react";

const useIsPremium = () => {
  const user = auth.currentUser;
  const [isPremium, setIsPremium] = useState(true);

  useEffect(() => {
    auth.onIdTokenChanged(async (user) => {
      if (user) {
        const token = await user.getIdTokenResult();

        if (token?.claims?.stripeRole !== undefined) {
          if (token?.claims?.stripeRole === "pro") {
            setIsPremium(true);
          } else {
            setIsPremium(false);
          }
        }
      }
    });
  }, [user]);

  return isPremium;
};

export default useIsPremium;
