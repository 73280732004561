import Modal from "./Base/ModalBase";
import { DefaultModalHeader, ModalBody } from "./Base/Utils";
import { Selectbox, SelectOption } from "components/Forms/Selectbox";
import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Button } from "components/Forms/Button";
import { editAd } from "store/slices/ad/ad";

const MoveAdModal = ({ show, handleClose, ad }) => {
  const libraries = useSelector((state) => state.libraries.libraries);

  const dispatch = useDispatch();

  const filteredLibraries = libraries.filter(
    (library) => library.id !== ad.library_id
  );
  const [selectedLibrary, setSelectedLibrary] = useState(filteredLibraries[0]);

  const handleLibraryChange = (library) => {
    setSelectedLibrary(library);
  };

  const handleMoveLibrary = (e) => {
    e?.preventDefault();

    if (selectedLibrary) {
      dispatch(
        editAd({
          id: ad.id,
          data: { library_id: selectedLibrary.id },
          message: "Ad moved successfully",
        })
      ).then(() => handleClose());
    }
  };

  return (
    <Modal show={show} handleClose={handleClose}>
      <DefaultModalHeader handleClose={handleClose}>
        Move To Folder
      </DefaultModalHeader>
      <ModalBody className="mt-24 pb-32">
        <form onSubmit={handleMoveLibrary}>
          <Selectbox
            placeholder={filteredLibraries[0]?.name}
            classes={{
              container: "border-color-25",
              placeholder: "text-primary",
            }}
            onChange={handleLibraryChange}
            selected={selectedLibrary}
          >
            {filteredLibraries.map((library) => (
              <SelectOption
                key={library.id}
                value={library}
                label={library.name}
                className="text-primary"
              >
                {library.name}
              </SelectOption>
            ))}
          </Selectbox>
          <Button className="w-full h-40 mt-12" onClick={handleMoveLibrary}>
            Move Ad
          </Button>
        </form>
      </ModalBody>
    </Modal>
  );
};

export default MoveAdModal;
