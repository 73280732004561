import "./AdDetailsModal.css";
import Modal from "./Base/ModalBase";
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import JSZip from "jszip";
import instance from "lib/axios";
import * as DOMPurify from "dompurify";
import { formatDistance } from "date-fns";
import { addAdTag, removeAdTag, editAd } from "store/slices/ad/ad";
import Tag from "components/Misc/Tag";
import { Button } from "components/Forms/Button";
import Carousel from "components/Misc/Carousel";
import { SelectOption, Selectbox } from "components/Forms/Selectbox";
import Spinner from "components/Loaders/Spinner";
import {
  DownloadActive,
  PhonePCActive,
  PlusIcon,
  XIcon,
  InsertLinkFilled,
  ArrowLeft,
  CloudDone,
} from "assets/icons";
import { createFileAndDownload } from "utils/DOM";
import { IconButton } from "components/Forms/Button";
import { ModalBody, ModalFooter } from "./Base/Utils";
import { ResizeableTextarea } from "components/Forms/Textarea";
import { toast } from "react-toastify";
import { useDebounce } from "use-debounce";
import { eventEmitter } from "events/index";

const AdDetailsModal = ({
  ad,
  show,
  readOnly,
  handleClose,
  handleShowNextAd,
  handleShowPreviousAd,
}) => {
  const dispatch = useDispatch();
  const libraries = useSelector((state) => state.libraries.libraries);

  const [selectedLibrary, setSelectedLibrary] = useState(
    libraries.find((l) => l.id === ad.library_id)
  );

  const [tagInput, setTagInput] = useState("");
  const [notes, setNote] = useState(ad.notes);
  const [debouncedNotes] = useDebounce(notes, 500);
  const [showTagInput, setShowTagInput] = useState(false);
  const logoSrc =
    ad.source === "tiktok" ? "/images/tiktok.jpeg" : ad.provider_logo;

  const [isSavingNotes, setIsSavingNotes] = useState(false);
  const [isDownloadingVideo, setIsDownloadingVideo] = useState(false);
  const [isDownloadingMedia, setIsDownloadingMedia] = useState(false);

  const saveDate = formatDistance(new Date(), new Date(ad.created_at), {
    addSuffix: false,
  });

  const media = ad.ad_media;
  const sanitizedContent = DOMPurify.sanitize(ad.content);

  const handleMoveAd = () => {
    if (ad.library_id === selectedLibrary) return;

    dispatch(
      editAd({
        id: ad.id,
        data: { library_id: selectedLibrary.id },
        message: "Ad Moved successfully",
      })
    );
  };

  const implode = (arr) => arr.join(", ");

  const formatedLikes = Intl.NumberFormat("en-US", {}).format(ad.likes);

  const toggleShowTagInput = () => {
    setTagInput("");
    setShowTagInput((prev) => !prev);
  };

  const handleRemoveTag = (tag) => {
    dispatch(removeAdTag(tag));
  };

  const handleAddTag = (e) => {
    e?.preventDefault();

    if (tagInput === "") return;

    dispatch(addAdTag({ ad_id: ad.id, tag: tagInput }));

    setTagInput("");
    toggleShowTagInput();
  };

  const handleSaveNote = () => {
    dispatch(
      editAd({
        id: ad.id,
        data: { notes },
      })
    ).then(() => {
      setIsSavingNotes(false);
    });
  };

  // Watch for notes change and auto save
  useEffect(() => {
    if (notes === ad.notes) return;

    setIsSavingNotes(true);

    handleSaveNote();
  }, [debouncedNotes]);

  const handleDownloadAsPNG = async () => {
    const name = media[0].src.split("/").pop();
    const res = await instance.get(media[0].src, {
      responseType: "blob",
    });

    const url = window.URL.createObjectURL(new Blob([res.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `${name}.png`);
    document.body.appendChild(link);
    link.click();
  };

  const handleDownloadAsMP4 = async () => {
    setIsDownloadingVideo(true);

    let name = media[0].src.split("/").pop();

    // Force add .mp4 extension
    if (!name.includes(".mp4")) name = name + ".mp4";

    try {
      const res = await instance.get(media[0].src, {
        responseType: "blob",
      });

      createFileAndDownload(res.data, name);
    } catch (e) {
      setIsDownloadingVideo(false);
    }

    setIsDownloadingVideo(false);
  };

  const handleDownloadMedia = async () => {
    setIsDownloadingMedia(true);

    // grap all media and zip them
    const zip = new JSZip();

    for (let i = 0; i < media.length; i++) {
      const medium = media[i];

      try {
        const fileRes = await instance.get(medium.src, {
          responseType: "blob",
        });

        let fileName = medium.src.split("/").pop();

        // Add extensions if not present
        if (medium.type === "image" && !fileName.includes(".png"))
          fileName = fileName + ".png";
        else if (medium.type === "video" && !fileName.includes(".mp4"))
          fileName = fileName + ".mp4";

        zip.file(fileName, fileRes.data);
      } catch (e) {}
    }

    const content = await zip.generateAsync({ type: "blob" });

    createFileAndDownload(content, "media.zip");

    setIsDownloadingMedia(false);
  };

  const handleCopyAdLink = () => {
    const link = `${window.location.origin}/ad/${ad.link_id}`;

    navigator.clipboard.writeText(link);

    toast("Link copied to clipboard", {
      className: "toast-success",
    });

    eventEmitter.emit("ad:link_copied", {
      adId: ad.id,
      link,
    });
  };

  const handleGoToNextAd = () => {
    handleShowNextAd(ad);
  };

  const handleGoToPreviousAd = () => {
    handleShowPreviousAd(ad);
  };

  return (
    <Modal
      show={show}
      handleClose={handleClose}
      classes={{ modal: "relative ad-view-modal modal-lg p-0" }}
    >
      <IconButton
        className="absolute modal-close-icon w-24 h-24"
        onClick={handleClose}
      >
        <img src={XIcon} alt="Close" />
      </IconButton>
      <ModalBody className="p-24 pb-0 overflow-auto">
        <div className="body">
          <div className="left-side pb-24">
            <div className="left-side-header">
              <div className="ad-card-header">
                <div className="ad-info">
                  <div className="logo">
                    <img src={logoSrc} alt={""} />
                  </div>
                  <div className="ad-provider">
                    <h3>{ad.provider_name}</h3>
                    <p>Saved {saveDate} ago</p>
                  </div>
                </div>
                <div className="ad-menu"></div>
              </div>
            </div>
            <div className="content">
              <div className="description">
                <p dangerouslySetInnerHTML={{ __html: sanitizedContent }}></p>
              </div>
              <div className="asset">
                {ad.display_format.toLowerCase() === "image" && (
                  <div className="asset-img">
                    <img src={media[0].src} alt="" />
                  </div>
                )}

                {ad.display_format.toLowerCase() === "video" && (
                  <div className="asset-video">
                    <video className="w-full" src={media[0].src} controls />
                  </div>
                )}

                {(ad.display_format.toLowerCase() === "dco" ||
                  ad.display_format.toLowerCase() === "dpa") && (
                  <>
                    {media[0].type.toLowerCase() === "image" && (
                      <div className="asset-img">
                        <img src={media[0].src} alt="" />
                      </div>
                    )}

                    {media[0].type.toLowerCase() === "video" && (
                      <div className="asset-video">
                        <video className="w-full" src={media[0].src} controls />
                      </div>
                    )}
                  </>
                )}

                {(ad.display_format.toLowerCase() === "carousel" ||
                  ad.display_format.toLowerCase() === "multi_images") && (
                  <div className="asset-carousel">
                    <Carousel media={media} />
                  </div>
                )}
              </div>
              {ad.link && (
                <a
                  href={ad.link}
                  className="provider-og"
                  target="_blank"
                  rel="noreferrer"
                >
                  <div className="og-content">
                    <p className="caption">{ad.link_caption}</p>
                    <p className="title">{ad.link_title}</p>
                    <p className="subtitle">{ad.link_subtitle}</p>
                  </div>
                  <div className="og-action">
                    <button>{ad.link_text}</button>
                  </div>
                </a>
              )}
            </div>
          </div>
          <div className="separator"></div>
          <div className="right-side pb-24">
            <div>
              {!readOnly && (
                <>
                  <p className="label">FOLDER</p>
                  <div className="library-select">
                    <Selectbox
                      placeholder={selectedLibrary?.name}
                      selectedValue={selectedLibrary}
                      onChange={(l) => setSelectedLibrary(l)}
                      classes={{
                        baseContainer: "w-full",
                        label: "text-primary",
                        placeholder: "text-primary",
                        options: "text-primary",
                      }}
                    >
                      {libraries.map((l) => (
                        <SelectOption key={l.id} value={l}>
                          {l.name}
                        </SelectOption>
                      ))}
                    </Selectbox>
                    <Button
                      className="h-40 font-semibold rounded-6"
                      style={{
                        width: "100px",
                      }}
                      onClick={handleMoveAd}
                    >
                      Update
                    </Button>
                  </div>
                </>
              )}
              <div
                className="info"
                style={{ marginTop: !readOnly ? "27px" : "0" }}
              >
                <div className="info-row">
                  {ad.cta_type && (
                    <div className="info-piece">
                      <p className="label">CTA TYPE</p>
                      <p className="value">{ad.cta_type}</p>
                    </div>
                  )}
                  <div className="info-piece">
                    <p className="label">DISPLAY TYPE</p>
                    <p className="value">{ad.display_format.toUpperCase()}</p>
                  </div>
                  <div className="info-piece">
                    <p className="label">LIKES</p>
                    <p className="value">{formatedLikes}</p>
                  </div>
                </div>
                <div className="info-row">
                  <div className="info-piece">
                    <p className="label">PLATFORMS</p>
                    <p className="value">{implode(JSON.parse(ad.platforms))}</p>
                  </div>
                </div>
                <div className="info-row">
                  <div className="info-piece">
                    <p className="label">CATEGORIES</p>
                    <p className="value">
                      {implode(JSON.parse(ad.categories))}
                    </p>
                  </div>
                </div>
              </div>
              <div className="tags-container">
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <p className="label">TAGS</p>
                  <div
                    className="flex"
                    style={{
                      justifyContent: "space-between",
                      alignItems: "flex-start",
                      marginTop: "12px",
                    }}
                  >
                    <div className="tags">
                      {ad.ad_tags.map((tag) => (
                        <Tag
                          key={tag.id}
                          handleRemove={() => handleRemoveTag(tag)}
                          removeable={!readOnly}
                        >
                          {tag.name}
                        </Tag>
                      ))}
                    </div>
                    {!readOnly && (
                      <div
                        style={{
                          width: `${ad.ad_tags.length !== 0 ? "auto" : "100%"}`,
                        }}
                      >
                        <Button
                          className="text-base font-semibold rounded-6"
                          style={{
                            height: "30px",
                            width: "95px",
                          }}
                          onClick={toggleShowTagInput}
                        >
                          <img src={PlusIcon} alt="Add" />
                          Add Tag
                        </Button>
                      </div>
                    )}
                  </div>
                  <div className={`tag-input ${showTagInput ? "" : "hidden"}`}>
                    <form onSubmit={handleAddTag}>
                      <input
                        type="text"
                        className="input-default text-primary"
                        placeholder="+ Type Tag & Enter"
                        value={tagInput}
                        onChange={(e) => setTagInput(e.target.value)}
                      />
                    </form>
                  </div>
                </div>
              </div>
              <div className="note-container mt-16">
                <div className="flex justify-between items-center text-primary-70 font-semibold">
                  <p className="label">NOTES</p>
                  <div className="h-24 flex items-center gap-8">
                    {isSavingNotes && "Saving Notes..."}

                    {!isSavingNotes && (
                      <>
                        <img src={CloudDone} alt="Saved" />
                        Notes Saved
                      </>
                    )}
                  </div>
                </div>
                <ResizeableTextarea
                  classes={{ container: "w-full mt-10" }}
                  placeholder="Add Notes"
                  defaultHeight="121px"
                  value={notes || ""}
                  onChange={(e) => setNote(e.target.value)}
                />
              </div>
            </div>
          </div>
        </div>
      </ModalBody>
      <ModalFooter
        classes={{
          container:
            "flex items-start justify-between flex-wrap-reverse gap-16 ",
        }}
      >
        <div className="footer-actions flex flex-wrap items-center gap-16">
          {media[0].type === "video" && media.length === 1 && (
            <Button
              className="btn-white font-semibold h-40 gap-10"
              style={{ paddingRight: "16px", minWidth: "174px" }}
              onClick={handleDownloadAsMP4}
            >
              {isDownloadingVideo ? (
                <Spinner className="spinner-primary" />
              ) : (
                <>
                  <img src={DownloadActive} alt="Download" />
                  Download MP4
                </>
              )}
            </Button>
          )}
          {media[0].type === "image" && media.length === 1 && (
            <Button
              className="btn-white font-semibold h-40 gap-10"
              style={{ paddingRight: "16px", minWidth: "174px" }}
              onClick={handleDownloadAsPNG}
            >
              <img src={DownloadActive} alt="Download" />
              Download PNG
            </Button>
          )}
          {media.length > 1 && (
            <Button
              className="btn-white font-semibold h-40 gap-10"
              style={{ paddingRight: "16px", minWidth: "174px" }}
              onClick={handleDownloadMedia}
            >
              <img src={DownloadActive} alt="Download" />
              {isDownloadingMedia ? <Spinner /> : "Download Media"}
            </Button>
          )}
          {ad.link && (
            <a
              href={ad.link}
              style={{ textDecoration: "none", minWidth: "174px" }}
              target={"_blank"}
              rel={"noopener noreferrer"}
            >
              <Button
                className="btn-white font-semibold rounded-6 h-40 gap-10 w-full"
                style={{ paddingRight: "16px" }}
              >
                <img src={PhonePCActive} alt="Landing" />
                Landing Page
              </Button>
            </a>
          )}
          <Button
            className="btn-white font-semibold h-40 gap-10"
            style={{ paddingRight: "16px", minWidth: "160px" }}
            onClick={handleCopyAdLink}
          >
            <img src={InsertLinkFilled} alt="Download" />
            Copy ad link
          </Button>
        </div>
        <div className="flex items-center justify-center gap-12 navigation-arrows">
          <Button
            className="btn-white font-semibold h-40"
            onClick={handleGoToPreviousAd}
          >
            <img src={ArrowLeft} alt="Previous Ad" />
          </Button>
          <Button
            className="btn-white font-semibold h-40"
            onClick={handleGoToNextAd}
          >
            <img src={ArrowLeft} className="rotate-180" alt="Next Ad" />
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  );
};

export default AdDetailsModal;
