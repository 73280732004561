import Modal from "./Base/ModalBase";
import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { updateProject } from "store/slices/project";
import { DefaultModalHeader, ModalBody } from "./Base/Utils";
import { Input } from "components/Forms/Input";
import { Button } from "components/Forms/Button";
import useAutoFocus from "hooks/useAutoFocus";

const RenameProjectModal = ({ show, handleClose, project }) => {
  const [name, setName] = useState(project.name);
  const dispatch = useDispatch();

  const inputRef = useAutoFocus();

  const handleRenameProject = (e) => {
    e.preventDefault();

    dispatch(updateProject({ id: project.id, data: { name } }));

    handleClose();
  };

  // Reset project name when modal is closed
  useEffect(() => {
    setName(project.name);
  }, [show, project]);

  return (
    <Modal show={show} handleClose={handleClose}>
      <DefaultModalHeader handleClose={handleClose}>
        Rename Project
      </DefaultModalHeader>
      <ModalBody className="mt-24 pb-32">
        <form onSubmit={handleRenameProject}>
          <Input
            type="text"
            placeholder="Project Name"
            classes={{
              container: "h-40",
            }}
            value={name}
            onChange={(e) => setName(e.target.value)}
            inputRef={inputRef}
          />
        </form>
        <Button className="mt-12 w-full h-40" onClick={handleRenameProject}>
          Rename Project
        </Button>
      </ModalBody>
    </Modal>
  );
};

export default RenameProjectModal;
