import "styles/form.css";
import "./styles.css";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Input } from "components/Forms/Input";
import { Button } from "components/Forms/Button";
import { ContinueWithGoogleButton } from "components/Forms/Button";
import { RemoveRedEye } from "assets/icons";
import { useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import { clearvalidationErrors, register } from "store/slices/user";
import useAutoFocus from "hooks/useAutoFocus";

function Register() {
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [password, setPassword] = useState("");

  const emailInputRef = useAutoFocus();

  const dispatch = useDispatch();
  const validationErrors = useSelector((state) => state.user.validationErrors);

  const passwordInputRef = useRef();

  const handleToggleShowPassword = () => {
    const currentType = passwordInputRef.current.type;

    if (currentType === "text") passwordInputRef.current.type = "password";
    else if (currentType === "password") passwordInputRef.current.type = "text";
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();

    dispatch(
      register({
        email,
        firstName,
        lastName,
        password,
      })
    );
  };

  useEffect(() => {
    dispatch(clearvalidationErrors);
  }, [email, firstName, lastName, password, dispatch]);

  return (
    <div className="register-page">
      <div className="logo-container">
        <Link to="/">
          <img
            src="/images/MagicBriefLogo.png"
            alt="Magic Brief"
            width="159px"
          />
        </Link>
      </div>
      <form className="form-container" onSubmit={handleFormSubmit}>
        <div className="form-body">
          <h1 className="form-title text-primary text-lg">Sign Up</h1>
          <div className="input-group">
            <div className="input-container">
              <Input
                type="email"
                placeholder="Your Email"
                inputRef={emailInputRef}
                autoComplete="true"
                onChange={(e) => setEmail(e.target.value)}
              />
              <span className="validation-error">
                {validationErrors.register?.email?.[0]}
              </span>
            </div>
            <div className="input-row">
              <div className="input-container">
                <Input
                  placeholder="First name"
                  autoComplete="true"
                  onChange={(e) => setFirstName(e.target.value)}
                />
                <span className="validation-error">
                  {validationErrors.register?.firstName?.[0]}
                </span>
              </div>
              <div className="input-container">
                <Input
                  placeholder="Last name"
                  autoComplete="true"
                  onChange={(e) => setLastName(e.target.value)}
                />
                <span className="validation-error">
                  {validationErrors.register?.lastName?.[0]}
                </span>
              </div>
            </div>
            <div className="input-container">
              <Input
                type="password"
                placeholder="Create Password"
                inputRef={passwordInputRef}
                autoComplete="true"
                onChange={(e) => setPassword(e.target.value)}
                icon={
                  <img
                    src={RemoveRedEye}
                    alt="Show Password"
                    onClick={handleToggleShowPassword}
                    className="cursor-pointer"
                  />
                }
              />
              <span className="validation-error">
                {validationErrors.register?.password?.[0]}
              </span>
            </div>
          </div>
          <div className="confirmation-text">
            <p>By signing up, you confirm that you’ve read</p>
            <p>
              and accepted our{" "}
              <a
                href="https://magicbrief.com/terms"
                target="_blank"
                rel="noreferrer"
              >
                User Notice
              </a>{" "}
              and{" "}
              <a
                href="https://magicbrief.com/privacy"
                target="_blank"
                rel="noreferrer"
              >
                Privacy Policy.
              </a>
            </p>
          </div>
          <div className="action-buttons">
            <Button className="w-full h-40" type="submit">
              Register
            </Button>
            <p className="or-text">OR</p>
            <ContinueWithGoogleButton />
          </div>
        </div>
        <div className="form-footer">
          <p className="text-primary">
            Already have an MagicBrief account? <Link to="/login">Log in</Link>
          </p>
        </div>
      </form>
    </div>
  );
}

export default Register;
