import Dropable from "./Dropable";
import DropdownBase from "./DropdownBase";
import DropdownMenu from "./DropdownMenu";
import DropdownMenuItem from "./DropdownMenuItem";
import Avatar from "components/Misc/Avatar";
import { logout } from "store/slices/user";
import { CC, Logout, Support } from "assets/icons";
import { useDispatch, useSelector } from "react-redux";
import {
  functions,
  createUserInFirestore,
  triggerCreateStripeCustomer,
} from "lib/firebase";
import { httpsCallable } from "firebase/functions";
import useUniversalLoader from "hooks/useUniversalLoader";

const UserMenuDropdown = () => {
  const dispatch = useDispatch();
  const universalLoader = useUniversalLoader();

  const user = useSelector((state) => state.user.localUser);

  const handleLogout = () => dispatch(logout());

  const navigateToStripePortal = async () => {
    universalLoader.startLoading();

    try {
      // Get portal link
      const functionRef = httpsCallable(
        functions,
        "ext-firestore-stripe-payments-createPortalLink"
      );

      const { data } = await functionRef({
        returnUrl: window.location.origin,
      });

      window.location.assign(data.url);
    } catch (e) {
      if (e.message === "Cannot read property 'stripeId' of undefined") {
        // Create user in firestore
        await createUserInFirestore();

        // Create stripe customer
        await triggerCreateStripeCustomer();

        // Retry
        await navigateToStripePortal();
      }
    }
  };

  return (
    <DropdownBase>
      <Dropable>
        <Avatar src={user.avatar} />
      </Dropable>
      <DropdownMenu>
        <DropdownMenuItem className="gap-12" onClick={navigateToStripePortal}>
          <img src={CC} alt="Billing" />
          <span>Billing</span>
        </DropdownMenuItem>

        <DropdownMenuItem>
          <a
            className="flex items-center gap-12"
            href="https://magicbrief.com/support"
            target="_blank"
            rel="noreferrer"
          >
            <img src={Support} alt="Support" />
            <p>Support</p>
          </a>
        </DropdownMenuItem>

        <DropdownMenuItem className="gap-12" onClick={handleLogout}>
          <img src={Logout} alt="Logout" />
          <span className="text-danger">Logout</span>
        </DropdownMenuItem>
      </DropdownMenu>
    </DropdownBase>
  );
};

export default UserMenuDropdown;
