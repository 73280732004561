import Modal from "./Base/ModalBase";
import { DefaultModalHeader, ModalBody } from "./Base/Utils";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CheckList } from "components/Forms/CheckList";
import { LabeledCheckbox } from "components/Forms/Checkbox";
import Spinner from "components/Loaders/Spinner";
import { Button } from "components/Forms/Button";
import { exportBoard } from "store/slices/board";

const ExportBoardModal = ({ show, handleClose, board }) => {
  const [toExport, setToExport] = useState([]);
  const isExporting = useSelector((state) => state.board.isExporting);
  const dispatch = useDispatch();

  const handleToggleExportOption = (option) => {
    if (toExport.includes(option)) {
      setToExport(toExport.filter((item) => item !== option));
    } else {
      setToExport([...toExport, option]);
    }
  };

  const handleExport = () => {
    if (toExport.length > 0) {
      dispatch(
        exportBoard({
          board,
          options: toExport,
        })
      );
    }
  };

  const exportOptions = [
    {
      id: "images",
      name: "Export Images",
    },
    {
      id: "videos",
      name: "Export Videos",
    },
    {
      id: "docx",
      name: "Export Script (DOCX)",
    },
    // {
    //   id: "captions",
    //   name: "Export Captions (SRT)",
    // },
    // {
    //   id: "timeline",
    //   name: "Export Timeline (Premiere Pro)",
    // },
  ];

  return (
    <Modal show={show} handleClose={handleClose}>
      <DefaultModalHeader handleClose={handleClose}>
        Export Options
      </DefaultModalHeader>
      <ModalBody className="mt-24 pb-32">
        <CheckList
          classes={{ container: "flex flex-col gap-8", item: "text-primary" }}
        >
          {exportOptions.map((o) => (
            <LabeledCheckbox onChange={() => handleToggleExportOption(o.id)}>
              {o.name}
            </LabeledCheckbox>
          ))}
        </CheckList>
        <Button className="w-full h-40 mt-24" onClick={handleExport}>
          {isExporting ? <Spinner /> : "Export Board"}
        </Button>
      </ModalBody>
    </Modal>
  );
};

export default ExportBoardModal;
