import "./Checkbox.css";
import { useState, useEffect } from "react";

const LabledCheckbox = ({ children, checked, onChange, classes }) => {
  const [isChecked, setIsChecked] = useState(checked || false);

  const toggleCheck = () => {
    setIsChecked((prev) => !prev);
  };

  useEffect(() => {
    onChange && onChange(isChecked);
  }, [isChecked]);

  return (
    <div
      className={`checkbox-container checkbox-bordered w-full 
      ${classes?.container}
       ${isChecked ? " checked" : ""}
      `}
      onClick={toggleCheck}
    >
      {/* Label */}
      {children && (
        <p
          className={
            classes?.label + " " + (isChecked ? classes?.labelActive : "")
          }
        >
          {children}
        </p>
      )}

      {/* Checkbox Input */}
      <div className={`checkbox ${classes?.checkbox}`}>
        <input
          id="checkbox"
          type="checkbox"
          className={classes?.input}
          checked={isChecked}
          onClick={(e) => e.stopPropagation()}
          onChange={() => {}}
        />
        <label htmlFor="checkbox"></label>
      </div>
    </div>
  );
};

export default LabledCheckbox;
