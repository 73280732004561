import "./AdInspirationModal.css";
import ModalBase from "./Base/ModalBase";
import { DefaultModalHeader, ModalBody } from "./Base/Utils";
import RectangleExternalLink from "components/Utils/RectangleExternalLink";
import { FacebookActive, TiktokActive } from "assets/icons";
import inspirationLinks from "static/inspiration_links.json";

const adsLibraries = [
  {
    name: "Facebook Ads Library",
    icon: FacebookActive,
    href: "https://www.facebook.com/ads/library/",
  },
  {
    name: "TikTok Creative Centre",
    icon: TiktokActive,
    href: "https://ads.tiktok.com/business/creativecenter/inspiration/topads",
  },
];

const AdInspirationModal = ({ show, handleClose }) => {
  return (
    <ModalBase show={show} handleClose={handleClose}>
      <DefaultModalHeader handleClose={handleClose}>
        Ad Inspiration
      </DefaultModalHeader>

      <ModalBody className="pb-0">
        <div className="flex flex-col gap-8 mt-24">
          {adsLibraries.map((library) => (
            <RectangleExternalLink
              key={library.name}
              href={library.href}
              icon={library.icon}
            >
              <span className="font-semibold">{library.name}</span>
            </RectangleExternalLink>
          ))}
        </div>
      </ModalBody>

      <ModalBody className="p-0">
        <div className="ads-inspiration-list flex flex-col gap-8">
          {inspirationLinks.map((item, i) => (
            <RectangleExternalLink key={i} href={item.link}>
              {item.name}
            </RectangleExternalLink>
          ))}
        </div>
      </ModalBody>
    </ModalBase>
  );
};

export default AdInspirationModal;
