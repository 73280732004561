const appConfigs = {
  env: process.env.REACT_APP_ENV || "production",
  host: process.env.REACT_APP_API_HOST,
  video_host: process.env.REACT_APP_VIDEO_API_HOST,
  short_url: process.env.REACT_APP_SHORT_URL || "app.magicbrief.com",
  max_frames_per_board: process.env.REACT_APP_MAX_FRAMES_PER_PAGE || 200,
  defaultAdsLimitFetchLimit: window.innerWidth > 1200 ? 12 : 8,
};

export default appConfigs;
