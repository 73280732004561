import "./CheckList.css";

export type CheckListProps = {
  children: React.ReactNode[];
  classes?: Helpers.ComponentClasses<"container" | "item">;
};

const CheckList: React.FC<CheckListProps> = ({
  children,
  classes,
}: CheckListProps) => {
  const defaultClasses = {
    container: classes?.container || "checklist",
    item: classes?.item || "checklist-item checklist-item w-full",
  };

  return (
    <div className={defaultClasses.container}>
      {children?.map((child: any, i: number) => (
        <div key={i} className={defaultClasses.item}>
          {child}
        </div>
      ))}
    </div>
  );
};

export default CheckList;
