import { Button } from "components/Forms/Button";
import { FolderWithPlusInside } from "assets/icons";
import { UserMenuDropdown } from "components/Dropdown";
import {
  NavbarContainer,
  NavbarDefaultLeft,
  NavigationLinks,
} from "components/Navbar";

const Navbar = ({ handleShowCreateProjectModal }) => {
  return (
    <NavbarContainer classes={{ navbarWrapper: "nav-fixed" }}>
      <NavbarDefaultLeft />
      <div className="right-side">
        <Button
          className="btn-white min-w-40 p-12 md-p-0"
          onClick={handleShowCreateProjectModal}
        >
          <img src={FolderWithPlusInside} alt="New Project" />
          <span className="hide-sm pr-2">New Project</span>
        </Button>
        <NavigationLinks active={"/"} />
        <UserMenuDropdown />
      </div>
    </NavbarContainer>
  );
};

export default Navbar;
