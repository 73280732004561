import "./SquareButton.css";

const SquareButton = ({ children, classes, ...options }) => {
  return (
    <button
      className={`square-btn fully-centered h-40 w-40 rounded-8 ${classes}`}
      {...options}
    >
      {children}
    </button>
  );
};

export default SquareButton;
