import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
  sendPasswordResetEmail,
  updateProfile,
  sendEmailVerification,
} from 'firebase/auth';
import { auth } from '../../lib/firebase';
import Validator from 'validatorjs';
import { clearState } from './project';
import { eventEmitter } from 'events/index';
import { updateUserLocalData } from 'utils/user';
import { LocalUser } from 'types/DataObjects';
import { AxiosError } from 'axios';
import { FirebaseError } from 'firebase/app';

type State = {
  localUser: LocalUser | null;
  validationErrors: {
    login?: {
      email: string[];
      password: string[];
    };
    register?: {
      email: string[];
      password: string[];
      firstName: string[];
      lastName: string[];
    };
  };
};

export const register = createAsyncThunk<
  void,
  { email: string; password: string; firstName: string; lastName: string },
  { rejectValue: FirebaseError | AxiosError | Error }
>(
  'user/register',
  async (
    { email, password, firstName, lastName },
    { dispatch, rejectWithValue }
  ) => {
    const valid = new Validator(
      { email, firstName, lastName, password },
      {
        email: 'required|email',
        firstName: 'required|string|min:2|max:15|alpha',
        lastName: 'required|string|min:2|max:15|alpha',
        password: 'required|min:8|max:32',
      }
    );

    if (valid.errorCount > 0) {
      dispatch(setRegisterValidationErrors(valid.errors.errors));
      return;
    }

    try {
      await createUserWithEmailAndPassword(auth, email, password).then(
        async (user) => {
          await updateProfile(user.user, {
            displayName: firstName.trim() + ' ' + lastName.trim(),
          }).then(async (user) => {
            // Force refresh token to update user claims
            await auth.currentUser?.getIdToken(true);
          });

          eventEmitter.emit('user:created', user);

          await sendEmailVerification(user.user);

          dispatch(setToLocalUser(user));
        }
      );
    } catch (e) {
      return rejectWithValue(e as any);
    }
  }
);

export const login = createAsyncThunk<
  void,
  { email: string; password: string }
>('user/login', async ({ email, password }, { dispatch }) => {
  // Client side validation
  const valid = new Validator(
    { email, password },
    {
      email: 'required|email',
      password: 'required|min:8|max:32',
    }
  );

  if (!valid.check()) {
    dispatch(setLoginValidationErrors(valid.errors.errors));
    return;
  }

  const user = await signInWithEmailAndPassword(auth, email, password);

  dispatch(setToLocalUser(user));
});

export const logout = createAsyncThunk(
  'user/logout',
  async (_, { dispatch }) => {
    // If user have magicbrief extension installed
    // Send request to extension to logout
    const extensionId = localStorage.getItem('extensionId');

    if (extensionId) {
      /* global chrome */
      try {
        chrome.runtime.sendMessage(extensionId, { type: 'logout' });
      } catch (e) {
        // Do nothing
        console.error(e);
      }
    }

    updateUserLocalData({}, false);

    dispatch(clearState());
    dispatch(unSetUser());

    await signOut(auth);
  }
);

export const resetPassword = createAsyncThunk<void, { email: string }>(
  'user/logout',
  async ({ email }) => {
    await sendPasswordResetEmail(auth, email);
  }
);

const initialUser = localStorage.getItem('user');

const initialState: State = {
  localUser: initialUser ? JSON.parse(initialUser) : null,
  validationErrors: {
    login: {
      email: [],
      password: [],
    },
    register: {
      email: [],
      firstName: [],
      lastName: [],
      password: [],
    },
  },
};

const user = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setToLocalUser: (state, { payload }) => {
      if (!payload.user) {
        state.localUser = null;
        return;
      }

      const localUser = {
        uid: payload.user.uid,
        name: payload.user.displayName,
        email: payload.user.email,
        emailVerified: payload.user.emailVerified,
        avatar: payload.user.photoURL,
        // accessToken: payload.user.accessToken,
      };

      localStorage.setItem('user', JSON.stringify(localUser));

      // instance.defaults.headers.common.authorization = `Bearer ${localUser.accessToken}`;

      state.localUser = localUser;
    },
    unSetUser: (state) => {
      state.localUser = null;
      localStorage.removeItem('user');
    },
    setLoginValidationErrors: (state, { payload }) => {
      state.validationErrors.login = payload;
    },
    setRegisterValidationErrors: (state, { payload }) => {
      state.validationErrors.register = payload;
    },
    clearvalidationErrors: (state) => {
      state.validationErrors = {};
    },
  },
  extraReducers: (builder) => {
    builder.addCase(login.rejected, (state, action) => {
      const errCode = action.error.code;

      const errors: State['validationErrors']['login'] = {
        email: [],
        password: [],
      };

      switch (errCode) {
        case 'auth/user-not-found':
          errors.email.push("User doesn't exists");
          break;
        case 'auth/wrong-password':
          errors.password.push('Wrong password');
          break;
        case 'auth/too-many-requests':
          errors.email.push(
            'Too many attempts, the account is disabled temporarily'
          );
          break;
        case 'auth/user-disabled':
          errors.email.push('Your account is disabled');
          break;
        default:
          console.debug(errCode);
          errors.email.push('Unknown error occured');
          break;
      }

      state.validationErrors.login = errors;
    });
    builder.addCase(register.rejected, (state, action) => {
      const err = action.payload;
      const errCode = err instanceof FirebaseError ? err.code : null;

      const errors: State['validationErrors']['register'] = {
        email: [],
        firstName: [],
        lastName: [],
        password: [],
      };

      switch (errCode) {
        case 'auth/email-already-in-use':
          errors.email.push('User already exists');
          break;
        default:
          errors.email.push('Unknown error occured');
          break;
      }

      state.validationErrors.register = errors;
    });
  },
});

export const {
  setToLocalUser,
  unSetUser,
  setLoginValidationErrors,
  setRegisterValidationErrors,
  clearvalidationErrors,
} = user.actions;

export default user.reducer;
