import "./Input.css";

export type InputProps = {
  icon?: React.ReactNode;
  classes?: Helpers.ComponentClasses<"container" | "input">;
  inputRef?:
    | React.MutableRefObject<HTMLInputElement>
    | ((input: HTMLInputElement) => void);
} & React.HTMLProps<HTMLInputElement>;

const Input: React.FC<InputProps> = ({
  icon,
  classes,
  inputRef,
  ...props
}: InputProps) => {
  return (
    <div className={`input-default ${classes?.container}`}>
      <input type="text" className={classes?.input} ref={inputRef} {...props} />
      {icon && icon}
    </div>
  );
};

export default Input;
