import Button from "./Button";
import { GoogleLogo } from "assets/icons";
import { auth } from "lib/firebase";
import {
  signInWithPopup,
  getAdditionalUserInfo,
  GoogleAuthProvider,
} from "firebase/auth";
import { useDispatch } from "react-redux";
import { setToLocalUser } from "store/slices/user";
import { eventEmitter } from "events/index";

const ContinueWithGoogleButton = () => {
  const dispatch = useDispatch();

  const handleContinueWithGoogle = async (e) => {
    e.preventDefault();

    const provider = new GoogleAuthProvider();

    const user = await signInWithPopup(auth, provider);

    const { isNewUser } = getAdditionalUserInfo(user);

    if (isNewUser) {
      eventEmitter.emit("user:created", user);
    }

    dispatch(setToLocalUser(user));
  };

  return (
    <Button
      style={{
        background: "#fff",
        color: "#696974",
        border: "1px solid #E3E3E7",
        boxShadow: "none",
      }}
      onClick={handleContinueWithGoogle}
    >
      <img src={GoogleLogo} alt="Google logo" />
      Continue with Google
    </Button>
  );
};

export default ContinueWithGoogleButton;
