import "./styles.css";
import { useState } from "react";
import { UserMenuDropdown } from "components/Dropdown";
import { ArrowDown, UploadIcon } from "assets/icons";
import instance from "lib/axios";
import { toast } from "react-toastify";
import NavbarContainer from "components/Navbar/NavbarContainer";
import NavigationLinks from "components/Navbar/NavigationLinks";
import NavbarDefaultLeft from "components/Navbar/NavbarDefaultLeft";
import { eventEmitter } from "events/index";
import { appConfigs } from "configs";

const VideoUploadForm = ({ handleVideoProcessed, defaultFile }) => {
  const [framesRate, setFramesRate] = useState(5);
  const [file, setFile] = useState(defaultFile || null);
  const [uploading, setUploading] = useState(null);

  const handleFramesRateChange = (e) => {
    const value = e.target.value;

    if (!value) {
      setFramesRate(1);
      return;
    }

    const asInt = parseInt(value);

    if (asInt) {
      setFramesRate(asInt);
    }
  };

  const handleIncreaseFramesRate = () => {
    setFramesRate(framesRate + 1);
  };

  const handleDecreaseFramesRate = () => {
    if (framesRate === 1) return;

    setFramesRate(framesRate - 1);
  };

  const handleVideoSelect = (e) => {
    const files = e.target.files || e.dataTransfer.files;

    if (!files.length) return;

    const file = files[0];

    if (file.size > 50000000) {
      toast("Please select a file less than 50 MB", {
        className: "toast-danger",
      });

      return;
    }

    setFile(file);
  };

  const handleSubmitVideo = async () => {
    if (!file) return;

    setUploading(1);

    const formData = new FormData();
    formData.append("file", file);
    formData.append("rate", framesRate);

    try {
      const res = await instance.post("/process", formData, {
        baseURL: appConfigs.video_host,
        onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );

          if (percentCompleted < 90) {
            setUploading((prev) => {
              const current = percentCompleted - 5;

              if (current > prev) {
                return current;
              } else {
                return prev;
              }
            });
          } else {
            setUploading(90);
          }
        },
      });

      setUploading(null);

      // Fire event only if the video wasn't provided by default
      if (!defaultFile) {
        eventEmitter.emit("splice:video_spliced", {
          source: "video_page",
          name: file.name,
          size: file.size,
          type: file.type,
          frames_rate: framesRate,
        });
      }

      handleVideoProcessed(res.data);
    } catch (e) {
      const data = e.response.data;

      setUploading(null);

      toast(data.message, {
        className: "toast-danger",
      });
    }
  };

  return (
    <div>
      <NavbarContainer>
        <NavbarDefaultLeft />
        <div className="right-side">
          <NavigationLinks />
          <UserMenuDropdown />
        </div>
      </NavbarContainer>

      <div className="dropzone-container">
        <div className="video-dropzone">
          <input
            className="video-input"
            type="file"
            accept="video/mp4,video/avi,video/mov,video/webm"
            onChange={handleVideoSelect}
            onDrop={handleVideoSelect}
          />
          {file ? (
            <div className="upload-thumb">
              <p>{file.name}</p>
            </div>
          ) : (
            <div className="upload-placeholder">
              <img src={UploadIcon} alt="upload" />
              <p className="text-primary font-extrabold">Upload Video</p>
            </div>
          )}
        </div>
        <div className="frame-caputre-area">
          <p
            className="text-primary font-extrabold text-lg"
            style={{ paddingRight: "4px" }}
          >
            Detail
          </p>
          <div className="frames-caputre-number">
            <div className="button-up-down">
              <div className="up-down-text">
                <input
                  className="text-primary font-extrabold text-lg"
                  type="text"
                  value={framesRate}
                  onChange={handleFramesRateChange}
                />
              </div>
              <span className="up-down">
                <img
                  className="img-flip"
                  src={ArrowDown}
                  alt="increase"
                  onClick={handleIncreaseFramesRate}
                />
                <img
                  src={ArrowDown}
                  alt="decrease"
                  onClick={handleDecreaseFramesRate}
                />
              </span>
            </div>
          </div>
          <span className="text-primary caputre-tip">
            <p>The level of change needed to trigger frame capture.</p>
            <p>The higher the number the more change needed.</p>
          </span>
        </div>
        <div style={{ position: "relative", maxWidth: "100%" }}>
          <div
            className="video-progress"
            style={{ width: `${uploading}%` }}
          ></div>
          <button
            onClick={handleSubmitVideo}
            className={`font-extrabold text-md process-video-btn ${
              uploading ? "video-uploading" : ""
            }`}
          >
            <span style={{ zIndex: "5", position: "relative" }}>
              {uploading ? "Processing Video" : "Process Video"}
            </span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default VideoUploadForm;
