import "./styles.css";
import { useState } from "react";
import { Button } from "components/Forms/Button";
import {
  DropdownBase,
  Dropable,
  DropdownMenu,
  DropdownMenuItem,
} from "components/Dropdown";
import { IconButton } from "components/Forms/Button";
import Spinner from "components/Loaders/Spinner";
import {
  MoreFilled,
  VideoLibrary,
  FolderPlain,
  ImagesSlices,
  CreateBoard,
} from "assets/icons";
import Zip from "jszip";
import instance from "lib/axios";
import { toast } from "react-toastify";
import ExportFramesModal from "components/Modals/ExportFramesModal";
import NavbarContainer from "components/Navbar/NavbarContainer";
import NavigationLinks from "components/Navbar/NavigationLinks";
import NavbarDefaultLeft from "components/Navbar/NavbarDefaultLeft";
import Overlay from "components/Utils/Overlay";
import { appConfigs } from "configs";

const ProcessedVideoResults = ({ results, handleClearResults }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [marked, setMarked] = useState([]);
  const [showCreateBoardModal, setShowCreateBoardModal] = useState(null);
  const markedCount = marked.length;

  const providedFrames = results.map((result) => result.id).toLocaleString();
  const selectedFrames = results
    .filter((result, i) => marked.includes(i))
    .map((result) => result.id);

  const toggleFrameMark = (index) => {
    if (marked.includes(index)) {
      setMarked(marked.filter((i) => i !== index));
    } else {
      setMarked([...marked, index]);
    }
  };

  const exportAsImages = () => {
    const zip = new Zip();

    const b64toBlob = (b64Data, contentType = "", sliceSize = 512) => {
      const byteCharacters = atob(b64Data);
      const byteArrays = [];

      for (
        let offset = 0;
        offset < byteCharacters.length;
        offset += sliceSize
      ) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);

        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
      }

      const blob = new Blob(byteArrays, { type: contentType });

      return blob;
    };

    let images = results.map((i) => i.image);

    if (marked.length !== 0) {
      images = results.filter((result, index) => marked.includes(index));
      images = images.map((i) => i.image);
    }

    images.forEach((img, i) => {
      const asBlob = b64toBlob(img, "image/jpg");

      zip.file(`image-${i + 1}.jpg`, asBlob);
    });

    zip.generateAsync({ type: "blob" }).then(function (content) {
      const url = URL.createObjectURL(content);
      const link = document.createElement("a");
      link.href = url;
      link.download = "images.zip";
      link.click();
    });
  };

  const exportAsVideo = async () => {
    setIsLoading(true);

    let frames = "";

    if (markedCount !== 0) {
      frames = results.filter((result, index) => marked.includes(index));
      frames = frames.map((i) => i.id).join(",");
    }

    try {
      const res = await instance.get(
        `/export_video?ids=${frames}&provided=${providedFrames}`,
        {
          baseURL: appConfigs.video_host,
          responseType: "blob",
        }
      );

      const url = URL.createObjectURL(res.data);
      const link = document.createElement("a");
      link.href = url;
      link.download = "video";
      link.click();

      setIsLoading(false);
    } catch (e) {
      toast("Error while exporting your videos.", {
        className: "toast-danger",
      });

      setIsLoading(false);
    }
  };

  const exportAsGif = async () => {
    setIsLoading(true);

    let frames = "";

    if (markedCount !== 0) {
      frames = results.filter((result, index) => marked.includes(index));
      frames = frames.map((i) => i.id).join(",");
    }

    try {
      const res = await instance.get(
        `/export_gif?ids=${frames}&provided=${providedFrames}`,
        {
          baseURL: appConfigs.video_host,
          responseType: "blob",
        }
      );

      const url = URL.createObjectURL(res.data);
      const link = document.createElement("a");
      link.href = url;
      link.download = "gif";
      link.click();

      setIsLoading(false);
    } catch (e) {
      toast("Error while exporting your Gifs.", {
        className: "toast-danger",
      });

      setIsLoading(false);
    }
  };

  return (
    <div className="video-page">
      <NavbarContainer classes={{ navbarWrapper: "nav-fixed" }}>
        <NavbarDefaultLeft />
        <div className="right-side">
          <div className={`spinner-container ${isLoading ? "" : "hide"}`}>
            <Spinner className={"spinner-primary spinner-light"} />
          </div>
          {markedCount > 0 && (
            <div className="marked-container">
              <p className="marked-counter text-md text-primary font-extrabold">
                {markedCount}
              </p>
            </div>
          )}
          <DropdownBase>
            <Dropable>
              <Button className="btn-white min-w-40 h-40 p-12 md-p-0">
                <img
                  src={FolderPlain}
                  alt="View module"
                  style={{ paddingLeft: "2px" }}
                />
                <span className="hide-sm pr-2">Export Frames</span>
              </Button>
            </Dropable>
            <DropdownMenu>
              <DropdownMenuItem onClick={exportAsImages}>
                <img src={ImagesSlices} alt="Export as images" />
                <span>Images</span>
              </DropdownMenuItem>
              <DropdownMenuItem onClick={exportAsVideo}>
                <img src={VideoLibrary} alt="Export as video" />
                <span>Videos</span>
              </DropdownMenuItem>
              <DropdownMenuItem onClick={exportAsGif}>
                <img src={ImagesSlices} alt="Export as gifs" />
                <span>Gifs</span>
              </DropdownMenuItem>
            </DropdownMenu>
          </DropdownBase>
          <Button
            className="btn-white min-w-40 p-12 md-p-0"
            onClick={() => setShowCreateBoardModal(true)}
          >
            <img src={CreateBoard} alt="Create board" />
            <span className="hide-sm pr-2">Create Board</span>
          </Button>
          <NavigationLinks />
          <DropdownBase>
            <Dropable>
              <IconButton className="border-1 border-color-20">
                <img src={MoreFilled} alt="More" />
              </IconButton>
            </Dropable>
            <DropdownMenu>
              <DropdownMenuItem onClick={handleClearResults}>
                <img src={VideoLibrary} alt="New video" />
                <span>New Video</span>
              </DropdownMenuItem>
            </DropdownMenu>
          </DropdownBase>
        </div>
      </NavbarContainer>

      <div className="video-page-result-content" onClick={() => setMarked([])}>
        <div className="container">
          <div className="cards frames-grid-view z-100">
            {results.map((result, i) => (
              <div key={i} id={result.id}>
                <div
                  className="card-container"
                  onClick={(e) => {
                    e.stopPropagation();
                    toggleFrameMark(i);
                  }}
                >
                  <div
                    className={`card ${
                      marked.includes(i) ? "card-marked" : ""
                    }`}
                  >
                    <div className="header">
                      <span className="title text-sm font-bold text-primary">
                        Frame
                      </span>
                      <span className="order text-sm font-extrabold text-primary">
                        {(i + 1).toLocaleString("en-us", {
                          minimumIntegerDigits: 2,
                          useGrouping: false,
                        })}
                      </span>
                    </div>
                    <div className="asset">
                      <div className="asset-img">
                        <img
                          src={"data:image/jpg;base64," + result.image}
                          alt="frame"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        <Overlay show={true} className="z-0" onClick={() => setMarked([])} />
      </div>

      <ExportFramesModal
        show={showCreateBoardModal}
        handleClose={() => setShowCreateBoardModal(false)}
        selectedFrames={selectedFrames}
        providedFrames={providedFrames}
      />
    </div>
  );
};

export default ProcessedVideoResults;
