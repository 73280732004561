import { useEffect, useState } from "react";
import Input from "./Input";
import { IconButton } from "components/Forms/Button";
import { TrueMark, CloseIconRounded } from "assets/icons";
import useAutoFocus from "hooks/useAutoFocus";

const EditableText = ({
  currentValue,
  placeholder,
  isEditing: defaultIsEditing = false,
  onSubmit,
  onChange,
  classes,
}) => {
  const [isEditing, setIsEditing] = useState(defaultIsEditing);
  const [tempValue, setTempValue] = useState(currentValue);

  const inputRef = useAutoFocus();

  const handleStartEdit = () => {
    setIsEditing(true);
  };

  const handleCancelEdit = () => {
    setIsEditing(false);

    setTempValue(currentValue);
  };

  const handleChangeValue = (e) => {
    setTempValue(e.target.value);
  };

  const handleSubmit = () => {
    onSubmit && onSubmit(tempValue);

    // Stop editing
    setIsEditing(false);
  };

  useEffect(() => {
    setIsEditing(defaultIsEditing);
  }, [defaultIsEditing]);

  useEffect(() => {
    onChange && onChange(tempValue, isEditing);
  }, [tempValue, isEditing]);

  if (isEditing) {
    return (
      <form
        className={`flex gap-10 ${classes?.container}`}
        onSubmit={handleSubmit}
      >
        <Input
          classes={{
            container: `bg-white rounded-4 flex-1 ${classes?.inputContainer}`,
            input: classes?.input,
          }}
          inputRef={inputRef}
          placeholder={placeholder}
          value={tempValue}
          onChange={handleChangeValue}
          icon={
            <img
              src={CloseIconRounded}
              alt="Close"
              onClick={handleCancelEdit}
            />
          }
        />
        <IconButton className="rounded-4 btn-green" onClick={handleSubmit}>
          <img src={TrueMark} alt="save" />
        </IconButton>
      </form>
    );
  } else {
    return (
      <p className={`text-primary ${classes?.text}`} onClick={handleStartEdit}>
        {currentValue}
      </p>
    );
  }
};

export default EditableText;
