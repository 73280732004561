import "./Textarea.css";

type Props = {
  classes?: Helpers.ComponentClasses<"container" | "textarea">;
  textareaRef?: React.MutableRefObject<HTMLTextAreaElement>;
} & React.HTMLProps<HTMLTextAreaElement>;

const Textarea: React.FC<Props> = ({
  classes,
  textareaRef,
  ...props
}: Props) => {
  return (
    <div className={`textarea-container ${classes?.container}`}>
      <textarea
        className={`text-area-default ${classes?.textarea}`}
        ref={textareaRef}
        {...props}
      ></textarea>
    </div>
  );
};

export default Textarea;
