import "./ImageCard.css";

export type ImageCardProps = {
  image: React.ReactNode;
  children: React.ReactNode;
  onClick?: () => void;
};

const ImageCard: React.FC<ImageCardProps> = ({
  image,
  children,
  onClick,
}: ImageCardProps) => {
  return (
    <div
      className="image-card flex flex-col items-center bg-white rounded-14 cursor-pointer"
      onClick={onClick}
    >
      {image}
      <p className="text-primary text-center text-15 font-semibold line-height-20 mt-30">
        {children}
      </p>
    </div>
  );
};

export default ImageCard;
