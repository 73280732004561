import { auth } from "lib/firebase";
import { useState, useEffect } from "react";

const useIsFreePlan = () => {
  const user = auth.currentUser;
  const [isFreePlan, setIsFreePlan] = useState(true);

  useEffect(() => {
    auth.onIdTokenChanged(async (user) => {
      if (user) {
        const token = await user.getIdTokenResult();

        if (token?.claims?.stripeRole === "free") {
          setIsFreePlan(true);
        } else {
          setIsFreePlan(false);
        }
      }
    });
  }, [user]);

  return isFreePlan;
};

export default useIsFreePlan;
