import "./CollaborationControlMenu.tsx.css";
import { Input } from "components/Forms/Input";
import { Button } from "components/Forms/Button";
import { AddPersonOutlined, Globe, InsertLink } from "assets/icons";
import React, { useState, ChangeEvent } from "react";
import Avatar from "components/Misc/Avatar";
import Spinner from "components/Loaders/Spinner";
import { useQueryClient, useMutation, useQuery } from "react-query";
import { Collaboration } from "interfaces/Collaboration";
import { toast } from "react-toastify";

// The type of the item that will be shared
export type item = {
  id: number;
  title: string;
};

export type CollaborationControlMenuType<C> = {
  item: item;
  readOnly: boolean;
  showShareToPublic?: boolean;
  handleCopyLink?: (item: item) => void;
  addQuery: (item: item, email: string) => Promise<C>;
  fetchQuery: (item: item) => Promise<C[]>;
  removeQuery: (item: item, collaboration: C) => Promise<void>;
  classes?: Helpers.ComponentClasses<"container" | "inviteSection">;
  events?: {
    onCollaborationAdded?: (collaboration: C) => void;
    onCollaborationDeleted?: (collaboration: C) => void;
  };
};

const CollaborationControlMenu = <C extends Collaboration>({
  item,
  readOnly = false,
  showShareToPublic = true,
  handleCopyLink,
  addQuery,
  fetchQuery,
  removeQuery,
  classes,
  events,
}: CollaborationControlMenuType<C>) => {
  const [email, setEmail] = useState("");

  const queryClient = useQueryClient();

  const { isFetching: isLoadingCollaborators, data: collaborators } = useQuery<
    C[]
  >(["collaborators", item.title, item.id], () => fetchQuery(item), {
    enabled: !!item.id,
  });

  const createCollaborationMutation = useMutation(() => addQuery(item, email), {
    onSuccess: (data: C) => {
      const previousData: C[] =
        queryClient.getQueryData<C[]>(["collaborators", item.title, item.id]) ||
        [];

      queryClient.setQueryData<C[]>(
        ["collaborators", item.title, item.id],
        [...previousData, data]
      );

      setEmail("");

      events?.onCollaborationAdded && events.onCollaborationAdded(data);

      toast(`${data.name || data.email} invited to "${item.title}"`, {
        className: "toast-success",
      });
    },

    onError(error: any) {
      const message = error.response?.data?.message;
      const errorCode = error.response?.data?.errors?.code;

      let toastClass = "toast-danger";

      if (errorCode === "INVITATION_SENT") toastClass = "toast-success";

      toast(message, { className: toastClass });
    },
  });

  const removeCollborationMutation = useMutation(
    (collaboration: C) => removeQuery(item, collaboration),
    {
      onSuccess: (_, variables) => {
        const previousData: C[] =
          queryClient.getQueryData<C[]>([
            "collaborators",
            item.title,
            item.id,
          ]) || [];

        queryClient.setQueryData<C[]>(
          ["collaborators", item.title, item.id],
          previousData.filter((c) => c.id !== variables.id)
        );

        events?.onCollaborationDeleted &&
          events.onCollaborationDeleted(variables);

        toast(`Collaborator "${variables.email}" removed successfully`, {
          className: "toast-success",
        });
      },
      onError(error: any) {
        const message = error.response?.data?.message;

        toast(message, { className: "toast-danger" });
      },
    }
  );

  const handleAddCollaboratorClicked = async (
    e?: React.FormEvent<HTMLFormElement>
  ) => {
    e?.preventDefault();

    createCollaborationMutation.mutate();
  };

  const handleRemoveCollaboratorClicked = async (collaboration: C) => {
    removeCollborationMutation.mutate(collaboration);
  };

  return (
    <div
      className={`collaboration-control-menu rounded-8 ${classes?.container}`}
    >
      {/* Sharing To Public */}
      {showShareToPublic && (
        <div className="rounded-8 flex items-center justify-between p-16 copy-link-section">
          <div className="flex items-center gap-18">
            <img src={Globe} alt="Globe" style={{ marginLeft: "3px" }} />
            <div className="flex flex-col">
              <p className="text-primary font-extrabold text-md line-height-18">
                Share to web
              </p>
              {item.title && (
                <p className="text-primary-70 mt-2">{item.title}</p>
              )}
            </div>
          </div>
          {handleCopyLink && (
            <Button
              className=""
              onClick={handleCopyLink}
              style={{ paddingRight: "18px" }}
            >
              <img src={InsertLink} alt="Copy Link" />
              Copy Link
            </Button>
          )}
        </div>
      )}

      {/* Invitation */}
      <div
        className={`collaborators-section flex flex-col gap-12 ${classes?.inviteSection}`}
      >
        {!readOnly && (
          <>
            <div className="flex gap-16 items-center text-md">
              <img src={AddPersonOutlined} alt="Add Collaborator" />
              <p className="text-primary font-extrabold">Add Collaborators</p>
            </div>

            <form
              className="flex items-center justify-between gap-10"
              onSubmit={handleAddCollaboratorClicked}
            >
              <Input
                placeholder="Enter Email"
                classes={{ container: "h-40 w-full" }}
                value={email}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  setEmail(e.target.value)
                }
              />
              <Button
                onClick={handleAddCollaboratorClicked}
                className="h-40"
                style={{ width: "120px" }}
              >
                {createCollaborationMutation.isLoading && <Spinner />}
                {!createCollaborationMutation.isLoading && "Invite"}
              </Button>
            </form>
          </>
        )}

        {/* Collaborators Loader */}
        {isLoadingCollaborators && (
          <div className="fully-centered p-12">
            <Spinner className="spinner-primary" />
          </div>
        )}

        {/* Collaborators */}
        {!isLoadingCollaborators && (
          <div className="flex flex-col mt-4 gap-8">
            {collaborators?.map((c: C) => (
              <div
                className="collaborator flex items-center justify-between"
                key={c.id}
              >
                <div className="flex items-center gap-16">
                  {/* User Avatar */}
                  <Avatar src={c.photoURL} />

                  {/* User Info */}
                  <div className="flex flex-col">
                    <p className="collaborator-name text-primary font-extrabold line-height-18">
                      {c.name}
                    </p>
                    <p className="collaborator-email text-primary-70">
                      {c.email}
                    </p>
                  </div>
                </div>

                {/* Remove Collaborator Button */}
                {!readOnly && (
                  <Button
                    onClick={() => handleRemoveCollaboratorClicked(c)}
                    className="btn-clear btn-clear-hovered h-32 rounded-8 text-base text-danger"
                  >
                    {removeCollborationMutation.isLoading && (
                      <Spinner className="spinner-danger" />
                    )}
                    {!removeCollborationMutation.isLoading && "Remove"}
                  </Button>
                )}
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default CollaborationControlMenu;
