import "./NewFrameCard.css";
import { AddBox } from "assets/icons";

const NewFrameCard = ({ handleAddFrame }) => {
  return (
    <div
      className="new-frame-card w-full fully-centered flex-col relative rounded-8 z-100 cursor-pointer"
      onClick={handleAddFrame}
    >
      <img className="img-sm" src={AddBox} alt="Upload" />
      <p className="text-primary text-sm font-semibold mt-12">New Frame</p>
    </div>
  );
};

export default NewFrameCard;
