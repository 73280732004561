import "./NewBoardCard.css";
import { AddBox } from "assets/icons";

const NewBoardCard = ({ handleClick }) => {
  return (
    <div
      className="new-board-card flex items-center h-48 rounded-8 cursor-pointer gap-16 p-24"
      onClick={handleClick}
    >
      <img src={AddBox} alt="Add board" width="20px" height="20px" />
      <p className="text-md font-extrabold">Create New Board</p>
    </div>
  );
};

export default NewBoardCard;
