import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { allBoardFrameFields } from "./const";

type initialStateType = {
  boardFrameFields: { name: string }[];
};

if (localStorage.getItem("boardFrameFields") === null) {
  localStorage.setItem("boardFrameFields", JSON.stringify(allBoardFrameFields));
}

const initialState: initialStateType = {
  boardFrameFields:
    JSON.parse(localStorage.getItem("boardFrameFields") || "[]") ||
    allBoardFrameFields,
};

const app = createSlice({
  name: "app",
  initialState,
  reducers: {
    setBoardFrameFields(state, action: PayloadAction<string[]>) {
      let fields: initialStateType["boardFrameFields"] = [];

      if (action.payload.length === 0) fields = allBoardFrameFields;
      else
        fields = allBoardFrameFields.filter((field) =>
          action.payload.includes(field.name)
        );

      state.boardFrameFields = fields;
      localStorage.setItem("boardFrameFields", JSON.stringify(fields));
    },
  },
});

export const { setBoardFrameFields } = app.actions;

export default app.reducer;
