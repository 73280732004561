import "./Checkbox.css";

const Checkbox = ({ checked, onChange, classes }) => {
  const randomId = Math.random().toString(36).substring(7);

  return (
    <>
      {/* Checkbox Input */}
      <div className={`checkbox ${classes?.checkbox}`}>
        <input
          id={`checkbox-${randomId}`}
          type="checkbox"
          className={`${classes?.input}`}
          checked={checked}
          onClick={(e) => e.stopPropagation()}
          onChange={() => {}}
        />
        <label
          className={`${classes?.label}`}
          htmlFor={`checkbox-${randomId}`}
        ></label>
      </div>
    </>
  );
};

export default Checkbox;
