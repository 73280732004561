import "./ChromeExtensionBanner.css";
import { SmoothArrowLeft } from "assets/icons";
import { useEffect, useState } from "react";
import { auth, checkIfUserInstalledChromeExtensionBefore } from "lib/firebase";

const ChromeExtensionBanner = (props) => {
  const [isExtensionInstalled, setIsExtensionInstalled] = useState(true);

  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      if (user) {
        checkIfUserInstalledChromeExtensionBefore().then((res) => {
          setIsExtensionInstalled(res);
        });
      }
    });
  }, []);

  return (
    !isExtensionInstalled && (
      <div className="extension-banner" {...props}>
        <div className="left-side">
          <h1 className="title text-primary">
            Download the Chrome <br /> extension to start saving ads
          </h1>
          <div className="flex items-center gap-20">
            <a
              href="https://chrome.google.com/webstore/detail/magicbrief-save-facebook/nmgliaofodcipdklfpkokjpaeaagjmjk"
              className="extension-link-btn text-primary flex items-center justify-center gap-14"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src="/images/chrome.png"
                alt="Chrome"
                className="w-24 h-24 chrome-icon"
              />
              Download Extension
            </a>
            <img className="arrow-left" src={SmoothArrowLeft} alt="Download" />
          </div>
        </div>
        <div className="right-side">
          <img
            className="facebook-library-img"
            src="/images/GhostedFacebookLibrary.png"
            alt="Facebook ads library"
          />
        </div>
      </div>
    )
  );
};

export default ChromeExtensionBanner;
