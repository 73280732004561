import {
  NavbarContainer,
  NavbarDefaultLeft,
  NavigationLinks,
} from "components/Navbar";
import { UserMenuDropdown } from "components/Dropdown";
import { useSelector } from "react-redux";
import { Button } from "components/Forms/Button";
import { Link } from "react-router-dom";

const LoggedInContent = () => (
  <>
    <NavigationLinks />
    <UserMenuDropdown />
  </>
);

const GuestContent = () => (
  <div className="flex items-center gap-16">
    <p className="font-semibold text-primary hide-sm">
      Save ad references in seconds!
    </p>
    <Link className="decoration-none" to="/register">
      <Button className="h-40 btn-gradient" style={{ width: "137px" }}>
        Try MagicBrief
      </Button>
    </Link>
  </div>
);

const Navbar = () => {
  const user = useSelector((state) => state.user.localUser);

  return (
    <NavbarContainer classes={{ navbarWrapper: "nav-fixed" }}>
      <NavbarDefaultLeft />
      <div className="right-side">
        {user && <LoggedInContent />}
        {!user && <GuestContent />}
      </div>
    </NavbarContainer>
  );
};

export default Navbar;
