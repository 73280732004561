import "./styles.css";
import "styles/form.css";
import { Input } from "components/Forms/Input";
import { Button } from "components/Forms/Button";
import { Oval } from "assets/icons";
import { useState } from "react";
import { Link } from "react-router-dom";
import { auth } from "lib/firebase";
import { sendPasswordResetEmail } from "firebase/auth";
import { toast } from "react-toastify";

function ResetPassword() {
  const [email, setEmail] = useState("");

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    if (!email) return;

    try {
      await sendPasswordResetEmail(auth, email);

      toast(`Password reset instructions sent to "${email}"`, {
        className: "toast-success",
      });

      setEmail("");
    } catch (error) {
      if (error.code === "auth/user-not-found") {
        toast("User not found", {
          className: "toast-danger",
        });
      } else {
        toast("Error while sending reset password instructions", {
          className: "toast-danger",
        });
      }
    }
  };

  return (
    <div className="reset-password-page">
      <div className="logo-container">
        <Link to="/">
          <img
            src="/images/MagicBriefLogo.png"
            alt="Magic Brief"
            width="159px"
          />
        </Link>
      </div>
      <form className="form-container" onSubmit={handleFormSubmit}>
        <div className="form-body">
          <h1 className="form-title text-primary text-lg">Reset Password</h1>
          <div className="input-group">
            <div className="input-container">
              <Input
                placeholder="Email"
                onChange={(e) => setEmail(e.target.value)}
                autoComplete="true"
                value={email}
              />
            </div>
          </div>
          <div className="action-buttons" style={{ marginTop: "-10px" }}>
            <Button className="w-full h-40" type="submit">
              Send reset instructions
            </Button>
          </div>
        </div>
        <div className="form-footer">
          <Link to="/register">Login</Link>
          <img src={Oval} alt="oval" />
          <Link to="/register">Sign up for an account?</Link>
        </div>
      </form>
      <div className="privacy-terms">
        <a
          href="https://magicbrief.com/privacy"
          target="_blank"
          rel="noreferrer"
        >
          Privacy policy
        </a>
        <img src={Oval} alt="oval" />
        <a href="https://magicbrief.com/terms" target="_blank" rel="noreferrer">
          Terms of use
        </a>
      </div>
    </div>
  );
}

export default ResetPassword;
