import { useEffect, useRef } from "react";
import Spinner from "components/Loaders/Spinner";

export const DefaultLoadingContent = ({ children }) => {
  return (
    <div className="not-updated">
      <Spinner className="spinner-primary spinner-light" />
      <p className="font-semibold">{children}</p>
    </div>
  );
};

export const DefaultSuccessContent = ({ children, image }) => {
  return (
    <div className="updated">
      {image && image}
      <p className="font-semibold">{children}</p>
    </div>
  );
};

export const UpdatingIndicator = ({
  isLoading,
  loadingComponent,
  successComponent,
  persistTime = 3000,
}) => {
  const ref = useRef(null);
  const lastTimer = useRef(null);

  useEffect(() => {
    if (ref.current && isLoading) {
      ref.current.style.opacity = "1";

      if (lastTimer.current) clearTimeout(lastTimer.current);
    } else {
      lastTimer.current = setTimeout(() => {
        ref.current.style.opacity = "0";
      }, persistTime);
    }
  }, [isLoading]);

  return (
    <div className="content-updating-indicator" ref={ref}>
      {isLoading ? loadingComponent : successComponent}
    </div>
  );
};

export default UpdatingIndicator;
