import "./DropdownMenuItem.css";

export type DropdownMenuItemProps = {
  children: React.ReactNode;
  className?: string;
} & React.HTMLAttributes<HTMLDivElement>;

const DropdownMenuItem: React.FC<DropdownMenuItemProps> = ({
  children,
  className,
  ...props
}: DropdownMenuItemProps) => {
  return (
    <div className={`dropdown-menu-item ${className}`} {...props}>
      {children}
    </div>
  );
};

export default DropdownMenuItem;
