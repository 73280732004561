export type SelectboxDefaultOptionProps = {
  value: any;
  children: React.ReactNode;
  className?: string;
};

const SelectboxDefaultOption: React.FC<SelectboxDefaultOptionProps> = ({
  children,
  value,
  className,
  ...props
}: SelectboxDefaultOptionProps) => {
  return (
    <div className={`selectbox-option option-default ${className}`} {...props}>
      {children}
    </div>
  );
};

export default SelectboxDefaultOption;
