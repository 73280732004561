import "./IconButton.css";

type Props = {
  children: JSX.Element | JSX.Element[];
  className?: string;
} & React.HTMLAttributes<HTMLDivElement>;

const IconButton: React.FC<Props> = ({
  children,
  className,
  ...props
}: Props) => {
  return (
    <div className={`icon-btn cursor-pointer ${className}`} {...props}>
      {children}
    </div>
  );
};

export default IconButton;
