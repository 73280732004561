import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { auth } from "../../lib/firebase";
import instance from "../../lib/axios";

export const fetchUserProjects = createAsyncThunk(
  "projects/fetchUserProjects",
  async () => {
    const projects = await instance.get(
      "/users/" + auth.currentUser.uid + "/projects"
    );

    return projects;
  }
);

export const createProject = createAsyncThunk(
  "projects/createProject",
  async (project, { rejectWithValue }) => {
    try {
      const newProject = await instance.post("/projects", project);

      return newProject;
    } catch (e) {
      return rejectWithValue(e.response.data.message);
    }
  }
);

export const updateProject = createAsyncThunk(
  "projects/updateProject",
  async (payload, { rejectWithValue }) => {
    try {
      const project = await instance.patch(
        "/projects/" + payload.id,
        payload.data
      );

      return project;
    } catch (e) {
      return rejectWithValue(e.response.data.message);
    }
  }
);

export const deleteProjectOnly = createAsyncThunk(
  "projects/deleteProject",
  async (payload, { rejectWithValue }) => {
    try {
      await instance.delete("/projects/" + payload.id);

      return payload;
    } catch (e) {
      return rejectWithValue(e.response.data.message);
    }
  }
);

export const deleteProjectWithBoards = createAsyncThunk(
  "projects/deleteProjectWithBoards",
  async (payload, { rejectWithValue }) => {
    try {
      await instance.delete("/projects/" + payload.id + "?withBoards=true");

      return payload;
    } catch (e) {
      return rejectWithValue(e.response.data.message);
    }
  }
);

export const createBoard = createAsyncThunk(
  "board/createBoard",
  async (payload, { rejectWithValue }) => {
    try {
      const res = await instance.post("/boards", payload);

      return res.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const editBoard = createAsyncThunk(
  "project/editBoard",
  async (payload, { rejectWithValue }) => {
    try {
      const { id, name, isPublic, status, message } = payload;

      const board = await instance.patch("/boards/" + id, {
        name: name?.trim(),
        public: isPublic,
        status,
      });

      return { board, message };
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const deleteBoard = createAsyncThunk(
  "project/deleteBoard",
  async (payload) => {
    await instance.delete("/boards/" + payload.id);

    return payload;
  }
);

export const addBoardCollaborator = createAsyncThunk(
  "project/addBoardCollaborator",
  async (payload, { rejectWithValue }) => {
    try {
      const { board, email } = payload;

      const collaborator = await instance.post(
        "/boards/" + board.id + "/collaborators",
        {
          email: email,
        }
      );

      return { board, collaborator: collaborator.data };
    } catch (e) {
      return rejectWithValue(e.response.data);
    }
  }
);

export const removeCollaborator = createAsyncThunk(
  "project/removeCollaborator",
  async ({ collaborator }, { rejectWithValue }) => {
    try {
      await instance.delete("/boards/collaborations/" + collaborator.id);

      return { collaborator };
    } catch (e) {
      return rejectWithValue(e.response.data);
    }
  }
);

export const duplicatedBoard = createAsyncThunk(
  "project/duplicatedBoard",
  async ({ id }, { rejectWithValue }) => {
    try {
      const board = await instance.post("/boards/" + id + "/duplicate");

      return board;
    } catch (e) {
      return rejectWithValue(e.response.data.message);
    }
  }
);

const initialState = {
  projects: [],
  isLoading: {
    projects: true,
  },
};

const project = createSlice({
  initialState,
  name: "project",
  reducers: {
    clearState(state) {
      state.projects = [];
    },
    changeProjectCollaboratorsCount(state, action) {
      const { projectId, count } = action.payload;

      const projectIndex = state.projects.findIndex(
        (project) => project.id === projectId
      );

      state.projects[projectIndex]._count.project_collaboration = count;
    },
  },
  extraReducers: {
    [fetchUserProjects.fulfilled]: (state, action) => {
      state.projects = action.payload.data;
      state.isLoading.projects = false;
    },
    [createProject.fulfilled]: (state, action) => {
      const project = action.payload.data;

      state.projects.push(project);

      toast(`Project "${project.name}" created successfully`, {
        className: "toast-success",
      });
    },
    [updateProject.fulfilled]: (state, action) => {
      const projectDate = action.payload.data;

      const updatedProjectIndex = state.projects.findIndex(
        (project) => project.id === projectDate.id
      );

      state.projects[updatedProjectIndex] = {
        ...state.projects[updatedProjectIndex],
        ...projectDate,
      };

      toast(`Project updated successfully`, {
        className: "toast-success",
      });
    },
    [deleteProjectOnly.fulfilled]: (state, action) => {
      const projectId = action.payload.id;

      const primaryProjectIndex = state.projects.findIndex(
        (project) => project.primary
      );
      const primaryProject = state.projects[primaryProjectIndex];
      const deletedProjectIndex = state.projects.findIndex(
        (project) => project.id === projectId
      );
      let deletedProjectBoardsCopy = [
        ...state.projects[deletedProjectIndex].boards,
      ];

      // Move all boards to primary project
      deletedProjectBoardsCopy = deletedProjectBoardsCopy.map((board) => {
        board.project_id = primaryProject.id;

        return board;
      });

      // Remove deleted project
      state.projects.splice(deletedProjectIndex, 1);

      // Push all boards of deleted project to primary project
      state.projects[primaryProjectIndex].boards = [
        ...state.projects[primaryProjectIndex].boards,
        ...deletedProjectBoardsCopy,
      ];

      toast(`Project deleted successfully`, {
        className: "toast-success",
      });
    },
    [deleteProjectWithBoards.fulfilled]: (state, action) => {
      const projectId = action.payload.id;

      const deletedProjectIndex = state.projects.findIndex(
        (project) => project.id === projectId
      );

      state.projects.splice(deletedProjectIndex, 1);

      toast(`Project deleted successfully`, {
        className: "toast-success",
      });
    },
    [createBoard.fulfilled]: (state, action) => {
      const board = action.payload;

      const projectIndex = state.projects.findIndex(
        (project) => project.id === board.project_id
      );

      state.projects[projectIndex].boards.push(board);
      state.createdBoard = board;

      toast(`Board "${board.name}" created successfully`, {
        className: "toast-success",
      });
    },
    [editBoard.fulfilled]: (state, action) => {
      const { board: updatedBoard, message } = action.payload;

      if (state.projects.length !== 0) {
        // If projects are loaded change in local state
        const boardProjectIndex = state.projects.findIndex(
          (p) => p.id === updatedBoard.project_id
        );

        if (boardProjectIndex !== -1) {
          const boardIndex = state.projects[boardProjectIndex].boards.findIndex(
            (b) => b.id === updatedBoard.id
          );

          state.projects[boardProjectIndex].boards[boardIndex] = updatedBoard;
        }
      }

      if (message) {
        toast(message || "Board updated successfully", {
          className: "toast-success",
        });
      }
    },
    [deleteBoard.fulfilled]: (state, action) => {
      const deletedBoard = action.payload;

      const boardProjectIndex = state.projects.findIndex((p) =>
        p.boards.some((b) => b.id === deletedBoard.id)
      );

      const boardIndex = state.projects[boardProjectIndex].boards.findIndex(
        (b) => b.id === deletedBoard.id
      );

      state.projects[boardProjectIndex].boards.splice(boardIndex, 1);
    },
    [addBoardCollaborator.fulfilled]: (state, action) => {
      const { board, collaborator } = action.payload;

      let allBoards = state.projects.map((p) => p.boards);
      allBoards = allBoards.flat();

      const updatedBoard = allBoards.find(
        (b) => b.id === collaborator.board_id
      );
      updatedBoard.collaborators.push(collaborator);

      toast(
        `${collaborator.name || collaborator.email} invited to "${board.name}"`,
        {
          className: "toast-success",
        }
      );
    },
    [addBoardCollaborator.rejected]: (state, action) => {
      const errorCode = action.payload.errors?.code;

      let toastClass = "toast-danger";

      if (errorCode === "INVITATION_SENT") toastClass = "toast-success";

      toast(action.payload.message, {
        className: toastClass,
      });
    },
    [removeCollaborator.fulfilled]: (state, action) => {
      const { collaborator } = action.payload;

      let allBoards = state.projects.map((p) => p.boards);
      allBoards = allBoards.flat();

      const updatedBoard = allBoards.find(
        (b) => b.id === collaborator.board_id
      );

      const collaboratorIndex = updatedBoard.collaborators.findIndex(
        (c) => c.user_id === collaborator.user_id
      );

      updatedBoard.collaborators.splice(collaboratorIndex, 1);

      toast(`${collaborator.email} removed from board`, {
        className: "toast-success",
      });
    },
    [duplicatedBoard.fulfilled]: (state, action) => {
      const board = action.payload.data;

      const projectIndex = state.projects.findIndex(
        (project) => project.id === board.project_id
      );

      state.projects[projectIndex].boards.push(board);

      toast(`Board duplicated successfully`, {
        className: "toast-success",
      });
    },
  },
});

export const { clearState, changeProjectCollaboratorsCount } = project.actions;

export default project.reducer;
