import "./BoardFrameCard.css";
import { useState, useRef } from "react";
import {
  DragHandle,
  UploadIcon,
  UploadButton,
  CloudUploading,
  AddBox,
  InsertLink,
} from "assets/icons";
import Spinner from "components/Loaders/Spinner";
import { Input } from "components/Forms/Input";
import { ResizeableTextarea } from "components/Forms/Textarea";
import { getFileType } from "utils/mics";
import { uploadFile } from "utils/requests";
import { useSelector } from "react-redux";

export const BoardFrameCard = ({
  frame,
  isMarked,
  handleToggleMark,
  handleEditFrame,
  handleAddAfter,
  isLastCard,
  showFullContent,
  readOnly,
  draggingOptions,
}) => {
  const [isUploading, setIsUploading] = useState(false);
  const [isLoadingAsset, setIsLoadingAsset] = useState(true);

  const uploadInput = useRef();
  const progressBarRef = useRef();
  const frameFields = useSelector((state) => state.app.boardFrameFields).map(
    (f) => f.name
  );

  const handleFieldChange = (fieldName, value) => {
    const editedFrameData = { ...frame, [fieldName]: value };

    handleEditFrame(frame, editedFrameData);
  };

  const handleFileUpload = async (file) => {
    if (isUploading) return;

    setIsUploading(true);

    if (
      file &&
      (getFileType(file) === "image" || getFileType(file) === "video")
    ) {
      const fileType = getFileType(file);

      try {
        let res = await uploadFile(file, {
          onUploadProgress: (progress) => {
            const { total, loaded } = progress;
            const totalSizeInMB = total / 1000000;
            const loadedSizeInMB = loaded / 1000000;
            const uploadPercentage = (loadedSizeInMB / totalSizeInMB) * 100;

            progressBarRef.current.style.width = `${uploadPercentage}%`;
          },
          encType: "multipart/form-data",
        });

        if (res.status === 201) {
          res = await res.data;

          const updatedFrame = {
            src: res.src,
            type: fileType,
          };

          console.debug("Asset uploaded", res);

          await handleEditFrame(frame, updatedFrame);
        } else {
          setIsUploading(false);
        }
      } catch (e) {
        setIsUploading(false);
      }
    }

    setIsUploading(false);
  };

  const handleOpenFrameLink = () => window.open(frame.link, "_blank");

  const handleMediaLoaded = () => {
    setIsLoadingAsset(false);
  };

  const handleMediaLoadError = () => {
    setIsLoadingAsset(false);
  };

  const style = { ...draggingOptions?.styles };

  // Dragging styles
  // Stop drop animation
  if (
    !draggingOptions?.isDragging &&
    !draggingOptions?.isSorting &&
    !draggingOptions?.isOver
  )
    style.transition = "none";

  // Dragged frame always on top
  if (draggingOptions?.isDragging) {
    style.zIndex = "999";
    style.touchAction = "none";
  }

  return (
    <div className="card-container" style={style}>
      <div className={`card ${isMarked || isUploading ? "card-marked" : ""}`}>
        <div
          className="clickable-overlay"
          onClick={!isUploading ? () => handleToggleMark(frame) : null}
        ></div>
        <div className="header">
          <span className="title text-sm font-bold text-primary">Frame</span>

          {/* Transparent Clickable Area For Card Marking */}
          <div
            className="header-clickable-area"
            onClick={!isUploading ? () => handleToggleMark(frame) : null}
          >
            &nbsp;
          </div>

          {/* Dragging Handler */}
          {!readOnly && draggingOptions && (
            <img
              src={DragHandle}
              ref={draggingOptions.setNodeRef}
              {...draggingOptions.attributes}
              {...draggingOptions.listeners}
              alt="Drag Handler"
            />
          )}

          {/* Transparent Clickable Area For Card Marking */}
          <div
            className="header-clickable-area"
            onClick={!isUploading ? () => handleToggleMark(frame) : null}
          >
            &nbsp;
          </div>

          {/* Card Order */}
          <span className="order text-sm font-extrabold text-primary">
            {(frame.order + 1).toLocaleString("en-us", {
              minimumIntegerDigits: 2,
              useGrouping: false,
            })}
          </span>
        </div>

        {/* Card Assets */}
        <div className="asset">
          {frame.type && !isUploading && !readOnly && (
            <div
              className="upload-icon"
              onClick={() => uploadInput.current.click()}
            >
              <img src={UploadButton} alt="Upload" />
            </div>
          )}

          {!readOnly && (
            <input
              className="file-input"
              max={1}
              type="file"
              name="asset"
              ref={uploadInput}
              onChange={(e) => handleFileUpload(e.target.files[0])}
              style={frame.type ? { display: "none" } : {}}
              accept="image/*,video/*"
            />
          )}

          {/* Uploading Placeholder */}
          {isUploading && (
            <>
              <div className="asset-empty">
                <img
                  src={CloudUploading}
                  alt="Uploading"
                  width="24px"
                  height="24px"
                  style={{ marginRight: "8px" }}
                />
                <p className="text-primary text-sm font-semibold">
                  Uploading...
                </p>
              </div>

              {/* Upload Progress bar */}
              <div className="asset-loader-progress">
                <div className="loaded" ref={progressBarRef}></div>
              </div>
            </>
          )}

          {/* Frame Media Asset */}
          {!isUploading && (
            <>
              {/* If the asset is image */}
              {frame.type === "image" && (
                <div className="asset-img">
                  <div
                    className={`asset-loader ${!isLoadingAsset ? "hide" : ""}`}
                  >
                    <div className="loading-spinner">
                      <Spinner className={"spinner-primary spinner-light"} />
                    </div>
                    <p className="text-primary">Loading</p>
                  </div>

                  <img
                    src={frame.src}
                    alt="Asset"
                    onLoad={handleMediaLoaded}
                    onError={handleMediaLoadError}
                    className={isLoadingAsset ? "hide" : ""}
                  />
                </div>
              )}

              {/* If the asset is video */}
              {frame?.type === "video" && (
                <div className="asset-video">
                  <div
                    className={`asset-loader ${!isLoadingAsset ? "hide" : ""}`}
                  >
                    <div className="loading-spinner">
                      <Spinner className={"spinner-primary spinner-light"} />
                    </div>
                    <p className="text-primary">Loading</p>
                  </div>

                  <video
                    className={isLoadingAsset ? "hide" : ""}
                    src={frame.src}
                    controls
                    onLoad={handleMediaLoaded}
                    onError={handleMediaLoadError}
                    onLoadedMetadata={handleMediaLoadError}
                  ></video>
                </div>
              )}

              {/* If no assets */}
              {!frame.type && (
                <div className="asset-empty">
                  <>
                    <img
                      src={UploadIcon}
                      alt="Upload Media"
                      width="24px"
                      height="24px"
                    />
                    <p className="text-primary text-sm">Upload Asset</p>
                  </>
                </div>
              )}
            </>
          )}
        </div>

        {/* Card Text Content */}
        <div className="note-text">
          <ResizeableTextarea
            placeholder="Label"
            value={frame.label}
            onChange={(e) => handleFieldChange("label", e.target.value)}
            style={{ marginTop: "4px" }}
          />
        </div>
        <div className="note-text">
          <ResizeableTextarea
            placeholder="Sound"
            value={frame.sound}
            onChange={(e) => handleFieldChange("sound", e.target.value)}
            defaultHeight={"60px"}
          />
        </div>
        <div className="note-text">
          <ResizeableTextarea
            placeholder="Action"
            value={frame.action}
            onChange={(e) => handleFieldChange("action", e.target.value)}
            defaultHeight={"60px"}
          />
        </div>
        {showFullContent && (
          <>
            <div className="note-text">
              <ResizeableTextarea
                placeholder="Caption"
                value={frame.caption}
                onChange={(e) => handleFieldChange("caption", e.target.value)}
                defaultHeight={"60px"}
              />
            </div>
            <div className="note-text">
              <Input
                classes={{
                  container: "h-32 rounded-4 pr-4",
                  input: "font-semibold text-sm",
                }}
                placeholder="Link"
                value={frame.link}
                onChange={(e) => handleFieldChange("link", e.target.value)}
                icon={
                  <img
                    className="img-filled"
                    src={InsertLink}
                    alt="Go to"
                    onClick={handleOpenFrameLink}
                  />
                }
              />
            </div>
          </>
        )}
      </div>

      {/* Add Card Button */}
      <div
        className="add-between-card"
        style={{ visibility: isLastCard ? "hidden" : "" }}
      >
        <div className="bar"></div>
        <img
          src={AddBox}
          alt="Add frame"
          onClick={() => handleAddAfter(frame)}
        />
        <div className="bar"></div>
      </div>
    </div>
  );
};

export default BoardFrameCard;
