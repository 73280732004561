import { CloseIconRounded } from "assets/icons";

export type DefaultModalHeaderProps = {
  children: React.ReactNode;
  handleClose?: () => void;
};

export type DefaultModalBodyProps = {
  children: React.ReactNode;
  className?: string;
};

export type DefaultModalFooterProps = {
  children: React.ReactNode;
  classes?: Helpers.ComponentClasses<"container">;
};

export const DefaultModalHeader: React.FC<DefaultModalHeaderProps> = ({
  children,
  handleClose,
}: DefaultModalHeaderProps) => {
  return (
    <div className="modal-header">
      <p className="modal-title text-primary font-extrabold">{children}</p>
      {handleClose && (
        <img
          className="close-icon"
          src={CloseIconRounded}
          alt="close"
          onClick={handleClose}
        />
      )}
    </div>
  );
};

export const ModalBody: React.FC<DefaultModalBodyProps> = ({
  children,
  className,
  ...props
}: DefaultModalBodyProps) => {
  return (
    <div className={`modal-body ${className}`} {...props}>
      {children}
    </div>
  );
};

export const ModalFooter: React.FC<DefaultModalFooterProps> = ({
  children,
  classes,
}: DefaultModalFooterProps) => {
  return <div className={`modal-footer ${classes?.container}`}>{children}</div>;
};
