import { ToastContainer, cssTransition } from "react-toastify";
import "animate.css/animate.min.css";
import "react-toastify/dist/ReactToastify.css";
import "./ToastWrapper.css";

const ToastWrapper = () => {
  const toastAnimation = cssTransition({
    enter: "animate__animated animate__fadeInDown animate__faster",
    exit: "animate__animated animate__fadeOutUp",
  });

  return (
    <ToastContainer
      transition={toastAnimation}
      autoClose={1900}
      bodyClassName="toast-body"
      position="top-center"
      closeButton={false}
      hideProgressBar={true}
    />
  );
};

export default ToastWrapper;
