import { eventEmitter } from "./index";

export default function registerBoardEvents() {
  eventEmitter.on("board:link_copied", (data) => {
    window.analytics.track("board_link_copied", data);
  });

  eventEmitter.on("board:frame_added", (data) => {
    window.analytics.track("board_frame_added", data);
  });
}
