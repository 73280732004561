import { createPortal } from "react-dom";

export type OverlayProps = {
  show: boolean;
  portalTarget?: string;
  className?: string;
} & React.HTMLProps<HTMLDivElement>;

const Overlay: any = ({
  show,
  portalTarget,
  className,
  ...props
}: OverlayProps) => {
  const overlay = <div className={`overlay ${className}`} {...props}></div>;
  const portalTargetElement = document.querySelector(
    portalTarget || "#overlay-root"
  );

  if (show)
    return portalTarget
      ? portalTargetElement
        ? createPortal(overlay, portalTargetElement)
        : overlay
      : overlay;
};

export default Overlay;
