import instance from "lib/axios";
import { IAdsFetchOptions } from "./types";
import { stringifyAdFetchOptions } from "./utils";

export async function fetchUserAdsRequest(
  payload: IAdsFetchOptions | string,
  cursor?: number
): Promise<{
  ads: any[];
  cursor: number;
  hasMore: boolean;
  stringifiedOptions: string;
}> {
  try {
    let stringifiedOptions = "";

    if (typeof payload === "object")
      stringifiedOptions = stringifyAdFetchOptions(payload || {});

    if (typeof payload === "string") stringifiedOptions = payload;

    let url = `/users/ads?${stringifiedOptions}`;

    if (cursor) url += `&cursor=${cursor}`;

    const res = await instance.get(url);

    return {
      ads: res.data.ads,
      cursor: res.data.cursor,
      hasMore: res.data.hasMore,
      stringifiedOptions,
    };
  } catch (e) {
    throw e;
  }
}

export async function fetchLibraryAds(
  payload: IAdsFetchOptions | string,
  link_id: string,
  cursor?: number
): Promise<{
  ads: any[];
  cursor: number;
  hasMore: boolean;
  stringifiedOptions: string;
}> {
  try {
    let stringifiedOptions = "";

    if (typeof payload === "object")
      stringifiedOptions = stringifyAdFetchOptions(payload || {});

    if (typeof payload === "string") stringifiedOptions = payload;

    let url = `/libraries/${link_id}/ads?${stringifiedOptions}`;

    if (cursor) url += `&cursor=${cursor}`;

    const res = await instance.get(url);

    return {
      ads: res.data.ads,
      cursor: res.data.cursor,
      hasMore: res.data.hasMore,
      stringifiedOptions,
    };
  } catch (e) {
    throw e;
  }
}
