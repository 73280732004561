import "./GetStartedModal.css";
import { useEffect, useState } from "react";
import ModalBase from "./Base/ModalBase";
import { ModalBody } from "./Base/Utils";
import { Button } from "components/Forms/Button";
import {
  ScissorsWithDots,
  OutlinedCreateBoard,
  OutlinedFolderWithPlus,
} from "assets/icons";
import ImageCard from "components/Cards/ImageCard";
import { useNavigate } from "react-router-dom";
import { updateUserLocalData } from "utils/user";
import useIsNewUser from "hooks/useIsNewUser";
import { getUserLocalData } from "utils/user";
import { useSelector } from "react-redux";
import CreateBoardModal from "./CreateBoardModal";

export const GetStartedModal: React.FC<any> = ({}) => {
  const navigate = useNavigate();
  const isNewUser = useIsNewUser();

  const user = useSelector((state: any) => state.user.localUser);

  const [show, setShow] = useState(false);
  const [showCreateBoardModal, setShowCreateBoardModal] = useState(false);

  useEffect(() => {
    const didGetStarted = getUserLocalData().setupGetStarted || false;

    // Show get started modal if user is new and verified it's email
    if (isNewUser && user.emailVerified && !didGetStarted) {
      setShow(true);
    }
  }, [isNewUser]);

  const setGetStartedSetupAsDone = () =>
    updateUserLocalData({ setupGetStarted: true });

  const handleNavigateToLibraryPage = () => {
    setGetStartedSetupAsDone();

    navigate({ pathname: "library" });
  };

  const handleClose = () => {
    setShow(false);
  };

  const handleSelectCreateBoard = () => {
    setGetStartedSetupAsDone();

    handleShowCreateBoardModal();

    handleClose();
  };

  const handleNavigateToVideoPage = () => {
    setGetStartedSetupAsDone();

    navigate({ pathname: "/video" });
  };

  const handleSkip = () => {
    setGetStartedSetupAsDone();

    handleClose();
  };

  const handleShowCreateBoardModal = () => {
    setShowCreateBoardModal(true);
  };

  return (
    <ModalBase
      show={show}
      classes={{
        modal: "get-started-modal",
      }}
      handleClose={handleClose}
    >
      <ModalBody>
        {/* Modal Header */}
        <div className="w-full flex flex-col gap-12 text-center text-primary">
          <h1 className="text-2xl font-extrabold line-height-23">
            How would you like to get started?
          </h1>
        </div>

        {/* Modal Content */}
        <div className="flex justify-center flex-wrap gap-12 mt-48">
          <ImageCard
            image={
              <img
                className="w-48 h-48"
                src={OutlinedFolderWithPlus}
                alt="Save Ads"
              />
            }
            onClick={handleNavigateToLibraryPage}
          >
            Start saving ad references
          </ImageCard>
          <ImageCard
            image={<img src={OutlinedCreateBoard} alt="Create Board" />}
            onClick={handleSelectCreateBoard}
          >
            Create a storyboard
          </ImageCard>
          <ImageCard
            image={<img src={ScissorsWithDots} alt="Splice Videos" />}
            onClick={handleNavigateToVideoPage}
          >
            Automatically <br /> cut up a video
          </ImageCard>
        </div>

        {/* Modal Footer */}
        <div className="flex flex-col items-center gap-12 mt-42">
          <Button
            className="btn-clear btn-clear-hovered btn-grayed-hover text-primary font-semibold"
            style={{ maxWidth: "206px" }}
            onClick={handleSkip}
          >
            I’ll get started on my own
          </Button>
        </div>
      </ModalBody>

      <CreateBoardModal
        show={showCreateBoardModal}
        defaultSelectedProject={null}
        handleClose={() => setShowCreateBoardModal(false)}
      />
    </ModalBase>
  );
};

export default GetStartedModal;
