import "./DropdownMenu.css";

export type DropdownMenuProps = {
  children: React.ReactNode;
  className?: string;
} & React.HTMLAttributes<HTMLDivElement>;

const DropdownMenu: React.FC<DropdownMenuProps> = ({
  children,
  className,
  ...props
}: DropdownMenuProps) => {
  return (
    <div className={`dropdown-menu ${className}`} {...props}>
      {children}
    </div>
  );
};

export default DropdownMenu;
