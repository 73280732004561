import { ProjectCollaboration } from "interfaces/Collaboration";
import instance from "lib/axios";

export async function fetchProjectCollaborations(
  id: number
): Promise<ProjectCollaboration[]> {
  const { data } = await instance.get(`/projects/${id}/collaborations`);

  return data;
}

export async function createProjectCollaboration(
  id: number,
  email: string
): Promise<ProjectCollaboration> {
  const { data } = await instance.post(`/projects/${id}/collaborations`, {
    email,
  });

  return data;
}

export async function deleteProjectCollaboration(
  collaboration: ProjectCollaboration
): Promise<void> {
  await instance.delete(`/projects/collaborations/${collaboration.id}`);
}
