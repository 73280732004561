import { useState } from "react";
import { IconButton } from "components/Forms/Button";
import {
  DropdownBase,
  Dropable,
  DropdownMenu,
  DropdownMenuItem,
} from "components/Dropdown";
import {
  MoreFilled,
  TrashFilledRed,
  Splice,
  InfoOutlined,
  SwapHoriz,
  InsertLinkFilled,
} from "assets/icons";
import DeleteAdModal from "components/Modals/DeleteAdModal";
import { formatDistance } from "date-fns";
import MoveAdModal from "components/Modals/MoveAdModal";
import Spinner from "components/Loaders/Spinner";
import Carousel from "components/Misc/Carousel";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { eventEmitter } from "events/index";
import useUniversalLoader from "hooks/useUniversalLoader";
import { useNavigate } from "react-router-dom";

const AdCard = ({ ad, showAdDetailsModal, readOnly }) => {
  const unviversalLoader = useUniversalLoader();
  const navigate = useNavigate();
  const libraries = useSelector((state) => state.libraries.libraries);

  const [isLoadingAsset, setIsLoadingAsset] = useState(true);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showMoveAdModal, setShowMoveAdModal] = useState(false);

  const media = ad?.ad_media;

  const logoSrc =
    ad.source === "tiktok" ? "/images/tiktok.jpeg" : ad.provider_logo;

  const saveDate = formatDistance(new Date(), new Date(ad.created_at), {
    addSuffix: false,
  });

  const handleShowMoveAdModal = () => {
    if (libraries.length === 1) {
      toast("Please create another folder first", {
        className: "toast-danger",
      });
      return;
    }

    setShowMoveAdModal(true);
  };

  const handleCopyAdLink = () => {
    const link = `${window.location.origin}/ad/${ad.link_id}`;
    navigator.clipboard.writeText(link);

    toast("Link copied to clipboard", { className: "toast-success" });

    eventEmitter.emit("ad:link_copied", {
      adId: ad.id,
      link,
    });
  };

  const handleCloseDeleteModal = () => {
    setShowDeleteModal(false);
  };

  const handleCloseMoveAdModal = () => {
    setShowMoveAdModal(false);
  };

  const handleVideoSplice = async () => {
    if (media[0].type !== "video") return;

    unviversalLoader.startLoading();

    try {
      eventEmitter.emit("splice:video_spliced", {
        source: "ad_library",
        ad_id: ad.id,
      });

      const video = await fetch(media[0].src).then((res) => res.blob());
      const videoAsFile = new File([video], "video.mp4");

      navigate("/video", { state: { file: videoAsFile } });
    } catch (e) {
      console.log(e);
    }

    unviversalLoader.stopLoading();
  };

  return (
    <>
      <div
        className="card-container z-200 cursor-pointer z-0"
        onClick={() => showAdDetailsModal(ad)}
      >
        <div className="card ad-container">
          <div className="ad-card-header">
            <div className="ad-info">
              <div className="logo">
                <img src={logoSrc} alt={ad.name} />
              </div>
              <div className="ad-provider">
                <h3>{ad.provider_name}</h3>
                <p>Saved {saveDate} ago</p>
              </div>
            </div>
            <div className="ad-menu" onClick={(e) => e.stopPropagation()}>
              <DropdownBase
                overlayProps={{
                  className: "z-100",
                }}
              >
                <Dropable>
                  <IconButton>
                    <img src={MoreFilled} alt="More" />
                  </IconButton>
                </Dropable>
                {readOnly && (
                  <DropdownMenu>
                    <DropdownMenuItem onClick={() => showAdDetailsModal(ad)}>
                      <img src={InfoOutlined} alt="Add editor" />
                      <span>Ad Details</span>
                    </DropdownMenuItem>
                  </DropdownMenu>
                )}
                {!readOnly && (
                  <DropdownMenu>
                    <DropdownMenuItem onClick={() => showAdDetailsModal(ad)}>
                      <img src={InfoOutlined} alt="Add editor" />
                      <span>Ad Details</span>
                    </DropdownMenuItem>
                    <DropdownMenuItem
                      onClick={handleCopyAdLink}
                      style={{ width: "192px" }}
                    >
                      <img src={InsertLinkFilled} alt="Add editor" />
                      <span>Copy Ad Link</span>
                    </DropdownMenuItem>
                    {/* {media[0].type === "video" && (
                      <DropdownMenuItem
                        onClick={handleVideoSplice}
                        style={{ width: "192px" }}
                      >
                        <img src={Splice} alt="Splice Ad" />
                        <span>Splice</span>
                      </DropdownMenuItem>
                    )} */}
                    <DropdownMenuItem
                      onClick={() => handleShowMoveAdModal(true)}
                    >
                      <img src={SwapHoriz} alt="Add editor" />
                      <span>Move Ad</span>
                    </DropdownMenuItem>
                    <DropdownMenuItem onClick={() => setShowDeleteModal(true)}>
                      <img src={TrashFilledRed} alt="Add editor" />
                      <span className="text-danger">Delete Ad</span>
                    </DropdownMenuItem>
                  </DropdownMenu>
                )}
              </DropdownBase>
            </div>
          </div>
          <div className="asset">
            {media[0].type === "image" && media.length === 1 && (
              <div className="asset-img">
                <div
                  className={`asset-loader ${!isLoadingAsset ? "hide" : ""}`}
                >
                  <div className="loading-spinner">
                    <Spinner className={"spinner-primary spinner-light"} />
                  </div>
                  <p className="text-primary">Loading</p>
                </div>
                <img
                  src={media[0].src}
                  alt={ad.ad_provider}
                  onLoad={() => setIsLoadingAsset(false)}
                  onError={() => setIsLoadingAsset(false)}
                  className={isLoadingAsset ? "hide" : ""}
                />
              </div>
            )}
            {media[0].type === "video" && media.length === 1 && (
              <div className="asset-video">
                <div
                  className={`asset-loader ${!isLoadingAsset ? "hide" : ""}`}
                >
                  <div className="loading-spinner">
                    <Spinner className={"spinner-primary spinner-light"} />
                  </div>
                  <p className="text-primary">Loading</p>
                </div>
                <video
                  src={media[0].src}
                  controls
                  onClick={(e) => e.stopPropagation()}
                  onLoad={() => setIsLoadingAsset(false)}
                  onError={() => setIsLoadingAsset(false)}
                  onLoadedMetadata={() => setIsLoadingAsset(false)}
                  className={isLoadingAsset ? "hide" : ""}
                />
              </div>
            )}
            {media.length > 1 && (
              <div className="asset-img">
                <div
                  className={`asset-loader ${!isLoadingAsset ? "hide" : ""}`}
                >
                  <div className="loading-spinner">
                    <Spinner className={"spinner-primary spinner-light"} />
                  </div>
                  <p className="text-primary">Loading</p>
                </div>
                <Carousel
                  media={media}
                  onLoadingChange={(isLoading) => setIsLoadingAsset(isLoading)}
                />
              </div>
            )}
          </div>
        </div>
      </div>

      <DeleteAdModal
        show={showDeleteModal}
        handleClose={handleCloseDeleteModal}
        ad={ad}
      />

      <MoveAdModal
        show={showMoveAdModal}
        handleClose={handleCloseMoveAdModal}
        ad={ad}
      />
    </>
  );
};

export default AdCard;
