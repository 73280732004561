import "./Switch.css";
import { useState, cloneElement } from "react";
import SwitchItem from "./SwitchItem";

type Props = {
  children: JSX.Element | JSX.Element[];
  defaultActive?: any;
  onChange?: (value: any) => void;
  classes?: Helpers.ComponentClasses<"container">;
};

const Switch: React.FC<Props> = ({
  children,
  defaultActive,
  onChange,
  classes,
}: Props) => {
  const [active, setActive] = useState(defaultActive);

  const asArray = Array.isArray(children) ? children : [children];
  const switchItems = asArray.filter((child) => child.type === SwitchItem);

  const handleSwitchChange = (value: any) => {
    setActive(value);

    onChange && onChange(value);
  };

  return (
    <ul
      className={`switch-container relative flex-centered rounded-8 ${classes?.container}`}
    >
      <span className="switch-border-prefix"></span>
      {switchItems.map((switchItem, index) => {
        return (
          <li
            key={index}
            className="switch-item-container h-full flex-centered z-10"
            onClick={() => handleSwitchChange(switchItem.props.value)}
          >
            {cloneElement(switchItem, {
              active: switchItem.props.value === active,
              ...switchItem.props,
            })}
          </li>
        );
      })}
    </ul>
  );
};

export default Switch;
