import ModalBase from "./Base/ModalBase";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { DefaultModalHeader, ModalBody } from "./Base/Utils";
import {
  Selectbox,
  SelectOption,
  SelectboxAction,
} from "components/Forms/Selectbox";
import { Input } from "components/Forms/Input";
import { Button } from "components/Forms/Button";
import { createBoard } from "../../store/slices/project";
import useAutoFocus from "hooks/useAutoFocus";
import { PlusIconActive } from "assets/icons";
import CreateProjectModal from "./CreateProjectModal";

const CreateBoardModal = ({ show, handleClose, defaultSelectedProject }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const inputRef = useAutoFocus();
  const projects = useSelector((state) => state.project.projects);

  const [boardName, setBoardName] = useState("");
  const [selectedProject, setSelectedProject] = useState(
    defaultSelectedProject
  );

  const [showCreateProjectModal, setShowCreateProjectModal] = useState(false);

  useEffect(() => {
    setSelectedProject(defaultSelectedProject);
  }, [defaultSelectedProject]);

  const handleProjectOptionChange = (project) => {
    setSelectedProject(project || defaultSelectedProject);
  };

  const handleCreateProjectClicked = () => {
    // setShowProjectsSelectbox(false);
    setShowCreateProjectModal(true);
  };

  const handleAddBoard = (e) => {
    e?.preventDefault();

    if (boardName.length === "") return;

    dispatch(
      createBoard({ name: boardName, project_id: selectedProject.id })
    ).then((res) => {
      if (res.type === "board/createBoard/fulfilled") {
        setBoardName("");

        const board = res.payload;

        return navigate("/" + board.sharable_link_id);
      }
    });
  };

  return (
    <ModalBase show={show} handleClose={handleClose}>
      <DefaultModalHeader handleClose={handleClose}>
        New Board
      </DefaultModalHeader>

      <ModalBody className="mt-24 pb-32">
        <form onSubmit={handleAddBoard}>
          <Input
            placeholder="Board Name"
            classes={{
              container: "h-40",
            }}
            value={boardName}
            onChange={(e) => setBoardName(e.target.value)}
            inputRef={inputRef}
          />

          <div style={{ marginTop: "12px" }}>
            <Selectbox
              placeholder="Select Project"
              classes={{
                placeholder: "text-primary",
              }}
              selected={selectedProject}
              onChange={handleProjectOptionChange}
            >
              {projects.map((project) => (
                <SelectOption
                  key={project.id}
                  value={project}
                  className="text-primary"
                >
                  {project.name}
                </SelectOption>
              ))}

              <SelectboxAction>
                <div
                  className="new-library-option d-flex items-center w-full"
                  style={{ borderBottom: "1px solid #3d1caf4d" }}
                  onClick={handleCreateProjectClicked}
                >
                  <img
                    src={PlusIconActive}
                    alt="New Library"
                    className="bg-secondary rounded-4 p-5"
                  />
                  <p className="text-primary font-extrabold">New Project</p>
                </div>
              </SelectboxAction>
            </Selectbox>
          </div>

          <Button className="w-full h-40 mt-12" onClick={handleAddBoard}>
            Create Board
          </Button>
        </form>
      </ModalBody>

      <CreateProjectModal
        show={showCreateProjectModal}
        handleClose={() => setShowCreateProjectModal(false)}
      />
    </ModalBase>
  );
};

export default CreateBoardModal;
