import { NavbarContainer } from "components/Navbar";
import { useState, useEffect } from "react";
import {
  Dropable,
  DropdownBase,
  DropdownMenu,
  DropdownMenuItem,
} from "components/Dropdown";
import { Button, IconButton } from "components/Forms/Button";
import { EditableText } from "components/Forms/Input";
import {
  InsertLink,
  Trash,
  CloudDone,
  MoreFilled,
  ArrowBack,
  TrashFilledRed,
  AddPerson,
  Sheet,
  InsertLinkFilled,
  FolderPlain,
  BurgerIcon,
  ViewModule,
  LabelActive,
} from "assets/icons";
import { useDispatch } from "react-redux";
import { editBoard } from "store/slices/project";
import { auth } from "lib/firebase";
import { Link, useNavigate } from "react-router-dom";
import TitleLoader from "components/Loaders/TitleLoader";
import {
  UpdatingIndicator,
  DefaultLoadingContent,
  DefaultSuccessContent,
} from "components/Utils/UpdateIndicator";
import AddBoardCollaboratorModal from "components/Modals/AddBoardCollaboratorModal";
import ExportBoardModal from "components/Modals/ExportBoardModal";
import DeleteBoardModal from "components/Modals/DeleteBoardModal";
import { Switch, SwitchItem } from "components/Switch";
import SelectBoardFrameFieldsModal from "components/Modals/SelectBoardFrameFieldsModal";
import FrameFieldsBar from "components/Bars/FrameFieldsBar";

const Navbar = ({
  board,
  isLoading,
  handleCopyPageLinkToClipboard,
  markedCount,
  handleDeleteMarkedFrames,
  isUpdatingContent,
  showExtras,
  handleToggleShowExtras,
  boardView,
  handleChangeBoardView,
}) => {
  const isOwner = auth.currentUser && auth.currentUser?.uid === board?.user_id;

  const [boardName, setBoardName] = useState(null);
  const [isPageCopied, setIsPageCopied] = useState(false);

  const [showDeleteBoardModal, setShowDeleteBoardModal] = useState(false);
  const [showAddCollaboratorModal, setShowAddCollaboratorModal] =
    useState(false);
  const [showExportBoardModal, setShowExportBoardModal] = useState(false);
  const [showFrameFieldsSelectorModal, setShowFrameFildsSelectorModal] =
    useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Update board name after board load
  useEffect(() => {
    board && setBoardName(board.name);
  }, [board]);

  const handleCopyToClippord = () => {
    handleCopyPageLinkToClipboard();
    setIsPageCopied(true);
  };

  const handleRenameBoard = (name) => {
    if (!name) return;

    setBoardName(name);

    dispatch(
      editBoard({ id: board.id, name, message: "Board name has been updated" })
    );
  };

  return (
    <>
      <NavbarContainer
        classes={{
          navbarWrapper: `nav-fixed ${
            boardView === "list" ? "border-0 shadow-0" : ""
          }`,
        }}
      >
        <div className="left-side">
          <div className="board-title">
            {/* Back Button */}
            <Link to={"/"}>
              <IconButton className="icon-btn icon-btn-filled">
                <img src={ArrowBack} alt="back" />
              </IconButton>
            </Link>

            {/* Title Loader */}
            {isLoading && <TitleLoader />}

            {/* Board title */}
            {!isLoading && boardName && (
              <EditableText
                currentValue={boardName}
                placeholder="Board Name"
                onSubmit={handleRenameBoard}
                classes={{
                  container: "w-500",
                  text: "text-xl text-primary font-extrabold",
                }}
              />
            )}
          </div>
        </div>
        <div className="right-side">
          <ul className="link-generator">
            {/* Board Content Saving Indicator */}
            <UpdatingIndicator
              isLoading={isUpdatingContent}
              loadingComponent={
                <DefaultLoadingContent>Saving...</DefaultLoadingContent>
              }
              successComponent={
                <DefaultSuccessContent
                  image={<img src={CloudDone} alt="Updated" />}
                >
                  Board Changes Saved
                </DefaultSuccessContent>
              }
            />

            {/* Marked Frames Counter */}
            {markedCount > 0 && (
              <div className="marked-container">
                <p className="marked-counter text-md text-primary font-extrabold">
                  {markedCount}
                </p>
                <Button
                  className="btn-delete"
                  onClick={handleDeleteMarkedFrames}
                >
                  <img src={Trash} alt="Delete" />
                  <span style={{ marginRight: "12px" }} className="link-word">
                    Delete
                  </span>
                </Button>
              </div>
            )}

            {/* Grid/List Board View Switch */}
            <Switch
              defaultActive={boardView}
              onChange={(view) => handleChangeBoardView(view)}
            >
              <SwitchItem className="w-40" value="grid">
                <img src={ViewModule} alt="Grid" />
              </SwitchItem>
              <SwitchItem className="w-40" value="list">
                <img src={BurgerIcon} alt="List" />
              </SwitchItem>
            </Switch>

            {/* Share Board Link Button */}
            <Button
              onClick={handleCopyToClippord}
              className="btn-default copy-link-button"
            >
              <img src={InsertLink} alt="Copy" />
              <span style={{ marginRight: "12px" }}>
                {isPageCopied ? "Copied!" : "Share Link"}
              </span>
            </Button>

            {/* Board Options */}
            <DropdownBase>
              <Dropable>
                <IconButton className="icon-btn icon-btn icon-btn-bordered">
                  <img src={MoreFilled} alt="More" />
                </IconButton>
              </Dropable>

              {/* Owner Options */}
              {isOwner && (
                <DropdownMenu>
                  <DropdownMenuItem
                    onClick={() => setShowAddCollaboratorModal(true)}
                  >
                    <img src={AddPerson} alt="Add team member" />
                    Add Editor
                  </DropdownMenuItem>
                  <DropdownMenuItem onClick={handleCopyToClippord}>
                    <img src={InsertLinkFilled} alt="Copy board link" />
                    Share Link
                  </DropdownMenuItem>
                  <DropdownMenuItem
                    onClick={() => handleToggleShowExtras(true)}
                  >
                    <img src={Sheet} alt="Show extra inputs" />
                    {showExtras ? "Hide" : "Show"} Extras
                  </DropdownMenuItem>
                  <DropdownMenuItem
                    onClick={() => setShowFrameFildsSelectorModal(true)}
                  >
                    <img src={LabelActive} alt="Show Lables" />
                    Show Labels
                  </DropdownMenuItem>
                  {/* <DropdownMenuItem
                    onClick={() => setShowExportBoardModal(true)}
                  >
                    <img src={FolderPlain} alt="Export Board" />
                    Export Board
                  </DropdownMenuItem> */}
                  <DropdownMenuItem
                    onClick={() => setShowDeleteBoardModal(true)}
                    style={{ paddingRight: "30px" }}
                  >
                    <img src={TrashFilledRed} alt="Delete" />
                    <span className="text-danger">Delete Board</span>
                  </DropdownMenuItem>
                </DropdownMenu>
              )}

              {/* Guest Options */}
              {!isOwner && (
                <DropdownMenu>
                  <DropdownMenuItem onClick={handleCopyToClippord}>
                    <img src={InsertLinkFilled} alt="Copy board link" />
                    Share Link
                  </DropdownMenuItem>
                  <DropdownMenuItem
                    onClick={() => handleToggleShowExtras(true)}
                  >
                    <img src={Sheet} alt="Show extra inputs" />
                    {showExtras ? "Hide" : "Show"} Extras
                  </DropdownMenuItem>
                </DropdownMenu>
              )}
            </DropdownBase>
          </ul>
        </div>
      </NavbarContainer>

      {/* Fields Bar */}
      {boardView === "list" && <FrameFieldsBar />}

      {/* Modals */}
      <AddBoardCollaboratorModal
        show={showAddCollaboratorModal}
        handleClose={() => setShowAddCollaboratorModal(false)}
        board={board}
      />
      <DeleteBoardModal
        show={showDeleteBoardModal}
        handleClose={() => setShowDeleteBoardModal(false)}
        handleDeleted={() => navigate("/")}
        board={board}
      />
      <ExportBoardModal
        show={showExportBoardModal}
        handleClose={() => setShowExportBoardModal(false)}
        board={board}
      />
      <SelectBoardFrameFieldsModal
        show={showFrameFieldsSelectorModal}
        handleClose={() => setShowFrameFildsSelectorModal(false)}
      />
    </>
  );
};

export default Navbar;
