import { useState, useEffect } from "react";
import SelectboxBase from "./SelectboxBase";
import CheckboxOption from "./Options/CheckboxOptions";
import {
  SelectboxHead,
  SelectboxAction,
  ResetableSelectboxBody,
} from "./Utils";
import { Input } from "components/Forms/Input";

export const MultiSelectbox = ({
  children,
  show: defaultShow,
  placeholder,
  searchOptions,
  classes,
  resetable = false,
  handleReset,
}) => {
  const [show, setShow] = useState(defaultShow);
  const [searchKey, setSearchKey] = useState("");

  const asArray = Array.isArray(children) ? children.flat() : [children].flat();
  const heads = asArray.filter((child) => child?.type === SelectboxHead);
  const defaultOptions = asArray.filter(
    (child) => child?.type === CheckboxOption
  );
  const actions = asArray.filter((child) => child?.type === SelectboxAction);

  const [options, setOptions] = useState(defaultOptions);

  const handleToggle = () => {
    setShow((prev) => !prev);
  };

  const handleSearchKeyChange = (e) => {
    setSearchKey(e.target.value);
  };

  // Watch for search key change
  // And filter options
  useEffect(() => {
    if (searchKey === "") {
      setOptions(defaultOptions);

      return;
    }

    const filteredOptions = defaultOptions.filter((option) => {
      return searchOptions.filter(searchKey, option.props.value);
    });

    setOptions(filteredOptions);
  }, [searchKey, searchOptions]);

  // Force render when options change
  useEffect(() => {
    setOptions((prev) => prev);
  }, [children]);

  useEffect(() => {
    setShow(defaultShow);
  }, [defaultShow]);

  const resetableBody = (
    <ResetableSelectboxBody
      label={placeholder}
      classes={classes}
      handleToggle={handleToggle}
      isResetable={resetable}
      handleReset={handleReset}
    />
  );

  return (
    <div className="selectbox-default">
      <SelectboxBase
        show={show}
        placeholder={placeholder}
        classes={classes}
        handleToggle={handleToggle}
        selectboxBody={resetable ? resetableBody : undefined}
      >
        {/* Options Filter */}
        {searchOptions && (
          <div className="selectbox-search">
            <Input
              type="text"
              placeholder={searchOptions.placeholder || "Search"}
              onChange={handleSearchKeyChange}
            />
          </div>
        )}

        {/* Options Head */}
        {heads.length > 0 && (
          <div className={`selectbox-options-head ${classes?.heads}`}>
            {heads}
          </div>
        )}

        {/* Options List */}
        {options.length > 0 && (
          <div className={`selectbox-options-container ${classes?.options}`}>
            {options.map((option, index) => (
              <div className="option-container" key={index}>
                {option}
              </div>
            ))}
          </div>
        )}

        {/* Options Actions */}
        {actions.length > 0 && (
          <div className={`selectbox-options-actions ${classes?.actions}`}>
            {actions}
          </div>
        )}
      </SelectboxBase>
    </div>
  );
};

export default MultiSelectbox;
