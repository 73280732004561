import "./selectbox.css";
import { useRef } from "react";
import Overlay from "components/Utils/Overlay";
import { DefaultSelectboxBody } from "./Utils";

export type SelectboxBaseProps = {
  show: boolean;
  placeholder?: string;
  isPlaceholder: boolean;
  classes?: Helpers.ComponentClasses<"container" | "content" | "placeholder">;
  children: React.ReactNode;
  handleToggle: () => void;
  selectboxBody?: React.ReactNode;
};

export const SelectboxBase: React.FC<SelectboxBaseProps> = ({
  children,
  show,
  placeholder,
  isPlaceholder,
  handleToggle,
  classes,
  selectboxBody,
}: SelectboxBaseProps) => {
  const optionsRef = useRef<HTMLDivElement>(null);

  // Move options dropdown to right if it is out of screen
  if (optionsRef.current) {
    const screenWidth = window.innerWidth;

    const optionsRect = optionsRef.current.getBoundingClientRect();
    const optionsWidth = optionsRect.width;
    const optionsLeft = optionsRect.left;

    if (optionsLeft + optionsWidth > screenWidth) {
      optionsRef.current.style.left = `auto`;
      optionsRef.current.style.right = `0px`;
    }
  }

  const handleToggleClicked = () => {
    // Close all other selectboxes first
    const overlays = document.querySelectorAll(".selectbox-overlay");

    overlays.forEach((overlay: any) => overlay.click());

    handleToggle && handleToggle();

    if (show) handleToggle();
  };

  const defaultBody = (
    <DefaultSelectboxBody
      label={placeholder}
      isPlaceholder={isPlaceholder}
      classes={classes}
      handleToggle={handleToggleClicked}
    />
  );

  return (
    <div className={`selectbox-wrapper ${classes?.container}`}>
      {/* Body */}
      {selectboxBody || defaultBody}

      {/* Options Dropdown */}
      <div
        className={`selectbox-content ${show ? "d-block" : ""} ${
          classes?.content
        }`}
      >
        {/* Options List */}
        <div className="selectbox-options z-200" ref={optionsRef}>
          {children}
        </div>
      </div>

      {/* Overlay */}
      <Overlay
        show={show}
        portalTarget="#overlay-root"
        className="selectbox-overlay z-200"
        onClick={handleToggle}
      />
    </div>
  );
};

export default SelectboxBase;
