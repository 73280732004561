import Modal from "./Base/ModalBase";
import { DefaultModalHeader, ModalBody } from "./Base/Utils";
import { useEffect } from "react";
import { auth } from "lib/firebase";
import { sendEmailVerification } from "firebase/auth";
import { useSelector, useDispatch } from "react-redux";
import { setToLocalUser } from "store/slices/user";
import { Link } from "react-router-dom";
import { Button } from "components/Forms/Button";

const VerifyEmailModal = () => {
  const user = useSelector((state) => state.user.localUser);
  const dispatch = useDispatch();

  const handleResendEmail = async () => {
    if (user.emailVerified) {
      dispatch(setToLocalUser(auth.currentUser));
      return;
    }

    await sendEmailVerification(auth.currentUser);
  };

  useEffect(() => {
    if (auth.currentUser) {
      sendEmailVerification(auth.currentUser);
    }
  }, [auth.currentUser]);

  return (
    <Modal show={true}>
      <DefaultModalHeader>Check your inbox</DefaultModalHeader>
      <ModalBody className="mt-16 pb-32" style={{ lineHeight: "17px" }}>
        <p className="text-primary">
          We’ve sent a you a confirmation email to complete the signup process.
        </p>
        <br />
        <span className="text-primary font-light">
          Please check your spam folder incase you can't find it in your normal
          inbox or <Link to={"/"}>contact support.</Link>
        </span>
        <Button
          className="w-full h-40"
          onClick={handleResendEmail}
          style={{ marginTop: "25px" }}
        >
          Resend Email
        </Button>
      </ModalBody>
    </Modal>
  );
};

export default VerifyEmailModal;
