/**
 * Inject script to <head> with content of script
 *
 * @param content script content
 * @param async load async
 */
export function injectScriptWithContent(content: string, async = true) {
  const script = document.createElement("script");
  script.innerHTML = content;
  script.async = async;
  document.head.appendChild(script);
}

/**
 * Create a file from blob and download it
 */
export function createFileAndDownload(blobObject: Blob, fileName: string) {
  const fileURL = URL.createObjectURL(blobObject);
  const link = document.createElement("a");

  link.href = fileURL;
  link.setAttribute("download", fileName);

  document.body.appendChild(link);

  link.click();
}
