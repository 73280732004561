import { User } from "firebase/auth";
import { Project } from "interfaces/Project";

class ProjectService {
  static checkIfProjectOwner(project: Project, user: User) {
    return project.user_id === user.uid;
  }

  static checkIfProjectCollaborator(project: Project, user: User) {
    return project?.project_collaboration?.some((c) => c.email === user.email);
  }
}

export default ProjectService;
