import "./Dropable.css";

const Dropable = ({ children, ...props }) => {
  return (
    <div className="dropable" {...props}>
      {children}
    </div>
  );
};

export default Dropable;
