import "./ResizeableTextarea.css";
import Textarea from "./Textarea";
import { useEffect, useRef } from "react";

export type ResizeableTextareaProps = {
  defaultHeight?: string;
  classes?: Helpers.ComponentClasses<"container" | "textarea">;
} & React.HTMLProps<HTMLTextAreaElement>;

const ResizeableTextArea: React.FC<ResizeableTextareaProps> = ({
  classes,
  defaultHeight = "1px",
  ...props
}: ResizeableTextareaProps) => {
  const textareaRef = useRef() as React.MutableRefObject<HTMLTextAreaElement>;

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = defaultHeight;

      textareaRef.current.style.height =
        textareaRef.current.scrollHeight + "px";
    }
  });

  return (
    <Textarea
      classes={
        {
          ...classes,
          textarea: "resizeable-textarea",
        } as ResizeableTextareaProps["classes"]
      }
      textareaRef={textareaRef}
      {...props}
    ></Textarea>
  );
};

export default ResizeableTextArea;
