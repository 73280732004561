import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import instance from "lib/axios";
import Spinner from "components/Loaders/Spinner";

function EmailVerification() {
  const location = useLocation();

  const [verificationStatus, setVerificationStatus] = useState("pending");

  // Verify email usign oobCode
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);

    const oobCode = queryParams.get("oobCode");
    const apiKey = queryParams.get("apiKey");

    const url = `https://identitytoolkit.googleapis.com/v1/accounts:update?key=${apiKey}`;
    const data = { oobCode };
    const configs = { headers: { authorization: "" } };

    instance
      .post(url, data, configs)
      .then(() => {
        setVerificationStatus("success");
      })
      .catch((e) => {
        setVerificationStatus("error");
      });
  }, []);

  useEffect(() => {
    if (verificationStatus === "success") {
      window.location.href = "/login";
    }
  }, [verificationStatus]);

  return (
    <div className="email-verification-page w-full h-full text-primary font-extrabold">
      <div className="fully-centered gap-8">
        {/* Verification Pending */}
        {verificationStatus === "pending" && (
          <>
            <Spinner className="spinner-primary" />
            <p>Verifing Your Email</p>
          </>
        )}

        {/* Verification Succeeded */}
        {verificationStatus === "success" && <p>Email Verified Successfully</p>}

        {/* Verification Error */}
        {verificationStatus === "error" && (
          <p className="text-danger">Error While Verifying Your Email 😢 <br/> If you use Gmail or GSuite, try logging in to MagicBrief using GSuite SSO, rather than email and password</p>
        )}
      </div>
    </div>
  );
}

export default EmailVerification;
