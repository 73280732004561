import { useState, useEffect } from "react";
import SelectboxBase from "./SelectboxBase";
import DefaultOption from "./Options/DefaultOption";
import { SelectboxHead, SelectboxAction } from "./Utils";

export type SelectboxProps = {
  show?: boolean;
  placeholder?: string;
  selected?: any;
  children: React.ReactNode;
  classes?: Helpers.ComponentClasses<
    | "baseContainer"
    | "heads"
    | "options"
    | "actions"
    | "container"
    | "content"
    | "placeholder"
    | "label"
  >;
  onChange?: (value: string) => void;
};

export const Selectbox: React.FC<SelectboxProps> = ({
  children,
  show: defaultShow,
  selected: defaultSelected,
  onChange,
  placeholder: defautlPlaceholder,
  classes,
}: SelectboxProps) => {
  const [show, setShow] = useState(defaultShow || false);
  const [placeholder, setPlaceholder] = useState(defautlPlaceholder);

  const asArray = Array.isArray(children) ? children.flat() : [children].flat();
  const heads = asArray.filter((child) => child?.type === SelectboxHead);
  const options = asArray.filter((child) => child?.type === DefaultOption);
  const actions = asArray.filter((child) => child?.type === SelectboxAction);

  const handleToggle = () => {
    setShow((prev) => !prev);
  };

  const handleOptionClicked = (value: any) => {
    handleChanged(value);

    handleToggle();
  };

  /**
   * Decide the new selectbox placeholder based on the selected value
   * Gives the priority to selected option label if exists
   * then the selected option value if it's a string
   * otherwise the default selectbox placeholder
   *
   * @returns The new placeholder
   */
  const decidePlaceholder = (selectedOption: any) => {
    let placeholder = defautlPlaceholder;

    if (selectedOption?.props?.label) placeholder = selectedOption.props.label;

    if (
      placeholder === defautlPlaceholder &&
      typeof selectedOption?.props?.children === "string"
    ) {
      placeholder = selectedOption.props.children;
    }

    return placeholder;
  };

  const handleChanged = (value: any) => {
    const selectedOption = options.find(
      (option) => option.props.value === value
    );

    const newPlaceholder = decidePlaceholder(selectedOption);

    setPlaceholder(newPlaceholder);

    onChange && onChange(value);
  };

  // Set default selected option placeholder
  useEffect(() => {
    const selectedOption = options.find(
      (option) => option.props.value === defaultSelected
    );

    const placeholder = decidePlaceholder(selectedOption);

    setPlaceholder(placeholder);
  }, [defaultSelected]);

  return (
    <div className={`selectbox-default ${classes?.baseContainer}`}>
      <SelectboxBase
        placeholder={placeholder}
        isPlaceholder={placeholder === defautlPlaceholder}
        show={show}
        classes={classes}
        handleToggle={handleToggle}
      >
        {/* Options Head */}
        {heads.length > 0 && (
          <div className={`selectbox-options-head ${classes?.heads}`}>
            {heads}
          </div>
        )}

        {/* Options List */}
        {options.length > 0 && (
          <div className={`selectbox-options-container ${classes?.options}`}>
            {options.map((option, index) => (
              <div
                className="option-container"
                key={index}
                onClick={() => handleOptionClicked(option.props.value)}
              >
                {option}
              </div>
            ))}
          </div>
        )}

        {/* Options Actions */}
        {actions.length > 0 && (
          <div className={`selectbox-options-actions ${classes?.actions}`}>
            {actions}
          </div>
        )}
      </SelectboxBase>
    </div>
  );
};

export default Selectbox;
