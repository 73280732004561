import "./FrameFieldsBar.css";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";

const FrameFieldsBar: React.FC = () => {
  const fields = useSelector((state: any) => state.app.boardFrameFields);
  const [widths, setWidths] = useState<number[]>([]);
  const filteredFields = fields.filter((f: any) => f.name !== "Link");

  useEffect(() => {
    const adjustFieldSize = () => {
      const horizontalCards = document.querySelectorAll(
        ".horizontal-frame-card"
      );
      const firstCard = horizontalCards[0];

      if (firstCard) {
        const widths: number[] = [];

        let fieldsTextboxes = Array.from(firstCard.children);
        fieldsTextboxes
          .slice(1, fieldsTextboxes.length - 2)
          .forEach((field) => {
            widths.push(field.clientWidth + 12);
          });

        setWidths(widths);
      }
    };

    const searchInterval = setInterval(() => {
      const horizontalCards = document.querySelectorAll(
        ".horizontal-frame-card"
      );
      const firstCard = horizontalCards[0];

      if (firstCard) {
        clearInterval(searchInterval);
        adjustFieldSize();
      }
    });

    adjustFieldSize();

    window.onresize = () => {
      adjustFieldSize();
    };
  }, [fields]);

  return (
    <div className="frame-fields-bar fixed z-200 h-56 w-full">
      <div className="container flex items-center h-full p-8">
        {/* Frame Number */}
        <p className="text-primary text-md font-extrabold flex justify-center flex-1 card-number-field">
          #
        </p>
        {filteredFields.map((field: any, index: number) => (
          <p
            className="text-primary text-md font-extrabold flex-1"
            key={field.name}
            style={{
              width: widths[index],
              minWidth: widths[index],
              maxWidth: widths[index],
            }}
          >
            {field.name}
          </p>
        ))}
      </div>
    </div>
  );
};

export default FrameFieldsBar;
