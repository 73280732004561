import "./AdsInspirationSetupModal.css";
import { useState, ChangeEvent, useEffect } from "react";
import ModalBase from "./Base/ModalBase";
import { ModalBody } from "./Base/Utils";
import { Input } from "components/Forms/Input";
import { Button } from "components/Forms/Button";
import { Selectbox, SelectOption } from "components/Forms/Selectbox";
import Spinner from "components/Loaders/Spinner";
import { useDispatch, useSelector } from "react-redux";
import { sourceInspirationAds } from "store/slices/ad/ad";
import { getUserLocalData, updateUserLocalData } from "utils/user";
import useIsNewUser from "hooks/useIsNewUser";

export const industries = [
  "App / Software",
  "Beauty / Personal Care",
  "Clothing / Apparel",
  "Consumer Goods",
  "Education",
  "Electronics / Tech",
  "Financial",
  "Fitness",
  "Food & Beverages",
  "Healthcare",
  "Kids",
  "Travel",
];

export const AdInspirationSetupModal: React.FC = () => {
  const [showSourceAds, setShowSourceAds] = useState(false);

  const user = useSelector((state: any) => state.user.localUser);
  const isNewUser = useIsNewUser();

  const [company, setCompany] = useState("");
  const [industry, setIndustry] = useState<string>("");
  const [link, setLink] = useState("");

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const dispatch = useDispatch<any>();

  const setSourceInspirationAdsAsDone = () =>
    updateUserLocalData({ didSourceInspirationAds: true });

  const handleSourceAds = async () => {
    if (isLoading || !industry) return;

    setIsLoading(true);

    dispatch(sourceInspirationAds({ company, industry, link }))
      .unwrap()
      .then(() => {
        setIsLoading(false);

        setSourceInspirationAdsAsDone();

        window.location.reload();
      })
      .catch((e: any) => {
        setIsLoading(false);
      });
  };

  const handleSkip = () => {
    setSourceInspirationAdsAsDone();

    setShowSourceAds(false);
  };

  useEffect(() => {
    const didSourceAdsInspiration =
      getUserLocalData().didSourceInspirationAds || false;

    // Show source inspiration modal if user has not done so
    if (isNewUser && user.emailVerified && !didSourceAdsInspiration) {
      setShowSourceAds(true);
    }
  }, [isNewUser]);

  return (
    <ModalBase
      classes={{
        modal: "ads-inspiration-setup-modal",
      }}
      show={showSourceAds}
      handleClose={handleSkip}
    >
      <ModalBody>
        {/* Modal Header */}
        <div className="w-full flex flex-col gap-12 text-center text-primary">
          <h1 className="text-2xl font-extrabold line-height-23">
            Let’s get you set up with some ads!
          </h1>
          <p className="text-15 font-bold line-height-23">
            Fill in the details below and we’ll automatically populate your{" "}
            <br />
            Magicbrief library with some high-performance ads.
          </p>
        </div>

        {/* Modal Form */}
        <div className="flex flex-col gap-8 mt-24 form">
          <Input
            placeholder="Company Name"
            classes={{ container: "h-40 bg-white" }}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              setCompany(e.target.value)
            }
          />

          <Selectbox
            placeholder="Industry"
            selected={null}
            classes={{ container: "bg-white", label: "text-primary" }}
            onChange={(value: string) => setIndustry(value)}
          >
            {industries.map((i: string) => (
              <SelectOption key={i} value={i} className="text-primary">
                {i}
              </SelectOption>
            ))}
          </Selectbox>

          <Input
            placeholder="Website link (optional)"
            classes={{ container: "h-40 bg-white" }}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              setLink(e.target.value)
            }
          />
        </div>

        {/* Modal Footer */}
        <div className="flex flex-col items-center gap-12 mt-12 footer">
          <Button className="h-40 w-full" onClick={handleSourceAds}>
            {isLoading ? <Spinner /> : "Source ad inspiration"}
          </Button>
          <Button
            className="btn-clear btn-clear-hovered btn-grayed-hover text-primary font-semibold w-full"
            style={{ maxWidth: "206px" }}
            onClick={handleSkip}
          >
            Skip this step
          </Button>
        </div>
      </ModalBody>
    </ModalBase>
  );
};

export default AdInspirationSetupModal;
