import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import VerifyEmailModal from 'components/Modals/VerifyEmailModal';
import { PropsWithChildren } from 'react';
import { RootState } from 'store/store';
import { LocalUser } from 'types/DataObjects';

const RequireAuth: React.FunctionComponent<PropsWithChildren> = ({
  children,
}) => {
  const user = useSelector<RootState, LocalUser | null>(
    (state) => state.user.localUser
  );

  if (!user) {
    return <Navigate to={'/register'} replace={true} />;
  } else if (!user.emailVerified) {
    return (
      <>
        <VerifyEmailModal />
        {children}
      </>
    );
  } else {
    return <>{children}</>;
  }
};

export default RequireAuth;
